import Axios from 'axios'
import { headers } from '../utilities'
import config from '../config'
import * as _ from "lodash";

// Must have own file.
export const GET_CONTAINER_BREAKDOWN = 'GET_CONTAINER_BREAKDOWN'
export const GET_CONTAINER_BREAKDOWN_FULFILLED = 'GET_CONTAINER_BREAKDOWN_FULFILLED'
export const GET_CONTAINER_BREAKDOWN_REJECTED = 'GET_CONTAINER_BREAKDOWN_REJECTED'


export const GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER = 'GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER'
export const GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_FULFILLED = 'GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_FULFILLED'
export const GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_REJECTED = 'GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_REJECTED'



export const fetchContainerSummaryBreakdown = (data) => {
  return dispatch => {
    dispatch({
      type: GET_CONTAINER_BREAKDOWN,
      payload: {}
    })
    return Axios.get(`${config.REACT_APP_API_BASE_URL}/container-summary-breakdown`, { headers: headers() })
      .then(function (response) {
        const groupBysoNumber = groupBy("sales_document"); // sales_document is equivalent to 'so number'
        const mappedGroudBySoNumber = _.map(
          groupBysoNumber(response.data),
          (mappedSoNumber) => mappedSoNumber
        );
        const newMappedData = []
        mappedGroudBySoNumber.map((mappedData) => {
          newMappedData.push({
            created_at: mappedData[0]['so_created_on'],
            so_number: mappedData[0]['sales_document'],
            billing_creation_date: mappedData[0]['billing_creation_dat'],
            po_number: mappedData[0]['po_number'],
            customer_po_number: mappedData[0]['customer_po_number'],
            status: _getMappedStatus(mappedData),
            actual_stocks: _getMappedActualStocks(mappedData),
            amount: _getMappedAmount(mappedData),
            // amount: 0,
            cbm: mappedData.reduce((a, c) => a + parseFloat((c['product_variant'] && c['product_variant']['cbm']) * getActualStocks(c.status, c)), 0), // from URC
            gross_weight: mappedData.reduce((a, c) => a + parseFloat((c['product_variant'] && c['product_variant']['gross_weight'] || 0)), 0),
            // gross_weight: 0,
            products: _getMappedProducts(mappedData),
            ordered_quantities: _getMappedOrderedQuantities(mappedData),
            invoiced_quantities: _getMappedInvoicedQuantities(mappedData),
            statusBased_quantities: _getMappedQuantityStatusBased(mappedData)
          })
        })
        dispatch({
          type: GET_CONTAINER_BREAKDOWN_FULFILLED,
          payload: newMappedData
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: GET_CONTAINER_BREAKDOWN_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}

const _getMappedActualStocks = (arrayData) => {
  const actualStocksArray = []
  arrayData.map((data) => actualStocksArray.push({ actualStocks: getActualStocks(data.status, data) }))
  return actualStocksArray.reduce((a, c) => a + parseFloat(c['actualStocks']), 0)
}

const _getMappedAmount = (arrayData) => {
  const amountArray = []
  arrayData.map((data) => amountArray.push({ amount: (data.product_variant && data.product_variant.pricelist ? data.product_variant.pricelist[0]['selling_price'] : 0) * getActualStocks(data.status, data) }))
  return amountArray.reduce((a, c) => a + parseFloat(c['amount']), 0)
}

const _getMappedProducts = (arrayData) => {
  const productsArray = []
  arrayData.map((data) => {
    productsArray.push(data.product_variant)
  })
  return productsArray
}

const _getMappedQuantityStatusBased = (arrayData) => {
  const quantitiesBasedOnStatusArray = []
  arrayData.map((data) => {
    quantitiesBasedOnStatusArray.push(getActualStocks(data.status, data))
  })
  return quantitiesBasedOnStatusArray
}


const _getMappedOrderedQuantities = (arrayData) => {
  const orderedQuantitiesArray = []
  arrayData.map((data) => {
    orderedQuantitiesArray.push(data.order_qty)
  })
  return orderedQuantitiesArray
}

const _getMappedInvoicedQuantities = (arrayData) => {
  const invoicedQuantitiesArray = []
  arrayData.map((data) => {
    invoicedQuantitiesArray.push(data.billed_qty)
  })
  return invoicedQuantitiesArray
}

export const fetchContainerBasedOnSoNumber = (data) => {
  return dispatch => {
    dispatch({
      type: GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER,
      payload: {}
    })
    return Axios.get(`${config.REACT_APP_API_BASE_URL}/container-summary-breakdown?so_number=${data}`, { headers: headers() })
      .then(function (response) {
        const newArray = []
        response.data.map((dataToStatus) => {
          newArray.push({
            ...dataToStatus,
            status: getStatusLabel(dataToStatus.status)
          })
        })
        dispatch({
          type: GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_FULFILLED,
          payload: newArray
        })
        return { error: false }
      })
      .catch(function (error) {
        dispatch({
          type: GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_REJECTED,
          payload: error
        })
        return { error: true }
      })
  }
}

const _getMappedStatus = (arrayData) => {
  const newStatus = []
  arrayData.map((data) => newStatus.push({ status: data.status }))
  const returnStatus = newStatus.every((data) => data.status === 'invoiced')
  let strStatus = ''
  if (returnStatus) strStatus = 'invoiced'
  else strStatus = 'in process'
  return strStatus
}

// Global Resusable functions

const groupBy = (key) => (array) => {
  const test = [];
  return array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
};

export const getStatus = data => {
  data = data || "";
  let status;
  if (data["billing_document"]) status = "invoiced";
  else if (data["delivery"]) status = "forLoading";
  else if (data["sales_document"]) status = "produced";
  else if (!data["sales_document"]) status = "forProduction";
  else status = "";
  return status;
};

export const getStatusLabel = status => {
  let returnStr = ''
  if (status === 'invoiced') returnStr = 'Invoiced'
  else if (status === 'ready_to_load') returnStr = 'For Loading'
  else if (status === 'produced') returnStr = 'Produced'
  else if (status === 'forProduction') returnStr = 'For Production'
  return returnStr
};

export const getActualStocks = (status, arrayData) => {
  let actualStocks;
  if (status === "produced") {
    actualStocks = arrayData["order_qty"]; // sap
  } else if (status === "ready_to_load") {
    actualStocks = arrayData["delivered_qty"]; // sap
  } else if (status === "invoiced") {
    actualStocks = arrayData["billed_qty"]; // sap
  }
  return actualStocks;
};

const getQtyBasedOnStatus = (status, arrayData) => {
  let returnQty;
  if (status === "produced") {
    returnQty = arrayData["order_qty"]; // sap
  } else if (status === "forLoading") {
    returnQty = arrayData["delivered_qty"]; // sap
  } else if (status === "invoiced") {
    returnQty = arrayData["billed_qty"]; // sap
  }
  return returnQty;
};
