import * as _ from 'lodash';
import moment from 'moment';
import {
	GET_CONTAINER_BREAKDOWN,
	GET_CONTAINER_BREAKDOWN_FULFILLED,
	GET_CONTAINER_BREAKDOWN_REJECTED,
	GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER,
	GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_FULFILLED,
	GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_REJECTED
} from '../actions';

export default (state = {
	containerBreakdownLoading: false,
	containerBreakdownSuccess: false,
	containerBreakdownSoNumberSuccess: false,
	containerBreakdownSoNumberLoading: false,
	containerBreakdownData: [],
	containerBreakdownSoNumberData: [],
	error: null
}, action) => {
	switch (action.type) {
		case GET_CONTAINER_BREAKDOWN:
			return { ...state, containerBreakdownLoading: true, containerBreakdownSuccess: false, error: null }
		case GET_CONTAINER_BREAKDOWN_FULFILLED:
			action.payload.map((data, i) => {
				data.created_at = moment(data.created_at).format("MM/DD/YYYY")
				data.created_at_whole = moment(data.created_at).format("MMMM DD YYYY")
			})
			return {
				...state, containerBreakdownLoading: false, containerBreakdownSuccess: true, error: null,
				containerBreakdownData: action.payload
			};
		case GET_CONTAINER_BREAKDOWN_REJECTED:
			return { ...state, containerBreakdownLoading: false, containerBreakdownSuccess: false, error: action.payload, data: null };
		case GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER:
			return { ...state, containerBreakdownSoNumberLoading: true, containerBreakdownSoNumberSuccess: false, error: null }
		case GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_FULFILLED:
			action.payload.map((data, i) => {
				data.created_at = moment(data.created_at).format("MM/DD/YYYY")
			})
			return {
				...state, containerBreakdownSoNumberLoading: false, containerBreakdownSoNumberSuccess: true, error: null,
				containerBreakdownSoNumberData: action.payload
			};
		case GET_CONTAINER_BREAKDOWN_BASED_SO_NUMBER_REJECTED:
			return { ...state, containerBreakdownSoNumberLoading: false, containerBreakdownSoNumberSuccess: false, error: action.payload, data: null };

		default:
			return state;
	}
}
