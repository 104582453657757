
// Must have own file.
export const BEVERAGE_FILTER = 'BEVERAGE_FILTER'
export const REMOVE_BEVERAGE_FILTER = 'REMOVE_BEVERAGE_FILTER'

export const redirectFilter = (data) => {
  return dispatch => {
    dispatch({
      type: BEVERAGE_FILTER,
      payload: { data }
    })
  }
}

export const removeRedirectFilter = () => {
  return dispatch => {
    dispatch({
      type: REMOVE_BEVERAGE_FILTER
    })
  }
}
