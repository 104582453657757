import { FETCH_RECENT_DRAFTS, FETCH_RECENT_DRAFTS_FULFILLED, FETCH_RECENT_DRAFTS_REJECTED, FETCH_DELIVERY_DETAILS_DRAFTS, FETCH_DELIVERY_DETAILS_DRAFTS_FULFILLED, FETCH_DELIVERY_DETAILS_DRAFTS_REJECTED } from '../actions'

export default (state = {
  data: null,
  deliveryDetailsDraftData: [],
  fetching: false,
  fetched: false,
  error: null
}, action) => {
  switch (action.type) {
    case FETCH_RECENT_DRAFTS:
      return { ...state, fetching: true, fetched: false, error: null }
    case FETCH_RECENT_DRAFTS_FULFILLED:
      return { ...state, fetching: false, fetched: true, error: null, data: action.payload }
    case FETCH_RECENT_DRAFTS_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload, data: null }
    case FETCH_DELIVERY_DETAILS_DRAFTS:
      return { ...state }
    case FETCH_DELIVERY_DETAILS_DRAFTS_FULFILLED:
      return { ...state, deliveryDetailsDraftData: action.payload }
    case FETCH_DELIVERY_DETAILS_DRAFTS_REJECTED:
      return { ...state }

    default:
      return state
  }
}
