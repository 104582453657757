import axios from 'axios'

import config from '../config'
import { headers } from '../utilities'

export const POST_RECENT_DRAFTS = 'POST_RECENT_DRAFTS'
export const POST_RECENT_DRAFTS_FULFILLED = 'POST_RECENT_DRAFTS_FULFILLED'
export const POST_RECENT_DRAFTS_REJECTED = 'POST_RECENT_DRAFTS_REJECTED'

export const FETCH_RECENT_DRAFTS = 'FETCH_RECENT_DRAFTS'
export const FETCH_RECENT_DRAFTS_FULFILLED = 'FETCH_RECENT_DRAFTS_FULFILLED'
export const FETCH_RECENT_DRAFTS_REJECTED = 'FETCH_RECENT_DRAFTS_REJECTED'

export const POST_CANCEL_DRAFTS = 'POST_CANCEL_DRAFTS'
export const POST_CANCEL_DRAFTS_FULFILLED = 'POST_CANCEL_DRAFTS_FULFILLED'
export const POST_CANCEL_DRAFTS_REJECTED = 'POST_CANCEL_DRAFTS_REJECTED'

export const FETCH_DELIVERY_DETAILS_DRAFTS = 'FETCH_DELIVERY_DETAILS_DRAFTS'
export const FETCH_DELIVERY_DETAILS_DRAFTS_FULFILLED = 'FETCH_DELIVERY_DETAILS_DRAFTS_FULFILLED'
export const FETCH_DELIVERY_DETAILS_DRAFTS_REJECTED = 'FETCH_DELIVERY_DETAILS_DRAFTS_REJECTED'

export const saveAsDraft = (data, draftId) => dispatch => {
  draftId = (draftId !== null) ? `/${draftId}` : ''
  const draftData = {
    ...data,
    distributor_draft_id: draftId
  }
  dispatch({
    type: POST_RECENT_DRAFTS,
    payload: {}
  })
  return axios.post(`${config.REACT_APP_API_BASE_URL}/draft${draftId}`, draftData, { headers: headers() })
    .then(response => {
      dispatch({
        type: POST_RECENT_DRAFTS_FULFILLED,
        payload: response.data
      })
      return { error: false }
    })
    .catch(err => {
      dispatch({
        type: POST_RECENT_DRAFTS_REJECTED,
        payload: err
      })
      return { error: true }
    })
}


export const fetchDrafts = () => dispatch => {
  dispatch({
    type: FETCH_RECENT_DRAFTS,
    payload: {}
  })
  return axios.get(`${config.REACT_APP_API_BASE_URL}/drafts`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_RECENT_DRAFTS_FULFILLED,
        payload: response.data
      })
    })
    .catch(err => {
      dispatch({
        type: FETCH_RECENT_DRAFTS_REJECTED,
        payload: err
      })
    })
}

export const cancelDraft = (id) => dispatch => {
  const data = {
    status: 0
  }
  dispatch({
    type: POST_CANCEL_DRAFTS,
    payload: {}
  })
  return axios.post(`${config.REACT_APP_API_BASE_URL}/draft/${id}`, data, { headers: headers() })
    .then(response => {
      dispatch({
        type: POST_CANCEL_DRAFTS_FULFILLED,
        payload: response.data
      })
      dispatch(fetchDrafts())
      return { error: false }
    })
    .catch(err => {
      dispatch({
        type: POST_CANCEL_DRAFTS_REJECTED,
        payload: err
      })
      return { error: true }
    })
}

export const fetchDraftById = (id) => dispatch => {
  dispatch({
    type: FETCH_DELIVERY_DETAILS_DRAFTS,
    payload: {}
  })
  return axios.get(`${config.REACT_APP_API_BASE_URL}/delivery-details/${id}`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_DELIVERY_DETAILS_DRAFTS_FULFILLED,
        payload: response.data
      })
    })
    .catch(err => {
      dispatch({
        type: FETCH_DELIVERY_DETAILS_DRAFTS_REJECTED,
        payload: err
      })
    })
}
