import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import * as _ from 'lodash'
import { Layout, Modal } from 'antd'
import { useMediaQuery } from 'react-responsive';

import './App.scss'
import 'antd/dist/antd.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Header from './shared/Header'
import Footer from './shared/Footer'

import NotFound from './NotFound'
import Home from './Home'
import About from './About'
import ProductCatalog from './Products'
// import News from './News'
import Contact from './Contact'
import Order from './Order'
import RecentOrders from './RecentOrders'
import OrderStatus from './OrderStatus'
import Drafts from './Drafts'
import FAQ from './FAQ'
import UserProfile from './UserProfile'
import ForgotPassword from './ForgotPassword'
import ConfirmPassword from './ConfirmPassword'
import PurchaseOrder from "./PurchaseOrder";
import ResetPassword from './ResetPassword'
import OrderSummary from "./OrderSummary";
import ContainerSummary from './ContainerSummary/index'
import SoList from './ContainerSummary/soList'
import {
  clearLocalStorage,
  isLocalStorageEmpty,
} from "../utilities";


moment.updateLocale(moment.locale(), { invalidDate: 'N/A' })

const genericErrors = {
  401: {
    title: 'Resource not Found',
    message: 'Unable to load resource'
  },
  500: {
    title: 'Internal Server Error',
    message: 'The server was unable to complete your request.'
  },
  502: {
    title: 'Bad Gateway',
    message: 'The server was unable to complete your request.'
  },
  503: {
    title: 'Service Unavailable',
    message: 'The server was unable to complete your request.'
  }
}

const globalLinks = [{
  route: 'www2.urc.com.ph/',
  label: 'URC Philippines'
}, {
  route: 'www.urcthailand.com',
  label: 'URC Thailand'
}, {
  route: 'urc.com.vn/en',
  label: 'URC Vietnam'
}, {
  route: 'urc.com.my',
  label: 'URC Malaysia'
}]

const paths = [{
  default: true,
  exact: true,
  slug: 'home',
  route: '/',
  label: 'Home',
  placements: ['Header', 'Footer'],
  authRequired: false,
  menu: 'left',
  component: Home
},
{
  default: false,
  exact: false,
  slug: 'about-us',
  route: '/about-us',
  label: 'About Us',
  placements: ['Header', 'Footer'],
  authRequired: false,
  menu: 'left',
  component: About
},
{
  default: false,
  exact: false,
  slug: 'products',
  route: '/products',
  label: 'Our Products',
  placements: ['Header', 'Footer'],
  authRequired: false,
  menu: 'left',
  component: ProductCatalog
},
{
  default: false,
  exact: false,
  slug: 'order',
  route: '/order',
  label: 'Order Now',
  placements: ['Header'],
  authRequired: true,
  menu: 'left',
  component: Order
},
{
  default: false,
  exact: false,
  slug: 'order-history',
  route: '/order-history',
  label: 'Order History',
  placements: [],
  authRequired: false,
  menu: 'left',
  component: RecentOrders
},
{
  default: false,
  exact: false,
  slug: 'order-status',
  route: '/order-status',
  label: 'Order Status',
  placements: [],
  authRequired: true,
  menu: 'left',
  component: OrderStatus
},
{
  default: false,
  exact: true,
  slug: "summary-dashboard",
  route: "/summary-dashboard",
  label: "Order Summary",
  placements: ['Header'],
  authRequired: true,
  menu: "left",
  component: OrderSummary
},
{
  default: false,
  exact: true,
  slug: "container-dashboard",
  route: "/container-dashboard",
  label: "Container Summary",
  placements: ['Header'],
  authRequired: true,
  menu: "right",
  component: ContainerSummary
},
{
  default: false,
  exact: true,
  slug: "so-list",
  route: "/so-list",
  label: "So List",
  placements: [],
  authRequired: true,
  menu: "left",
  component: SoList
},
{
  default: false,
  exact: false,
  slug: 'drafts',
  route: '/drafts',
  label: 'Drafts',
  placements: [],
  authRequired: false,
  menu: 'left',
  component: Drafts
},
{
  default: false,
  exact: false,
  slug: 'contact-us',
  route: '/contact-us',
  label: 'Contact Us',
  placements: ['Header', 'Footer'],
  authRequired: false,
  menu: 'left',
  component: Contact
},
{
  default: false,
  exact: false,
  slug: 'faq',
  route: '/faq',
  label: 'FAQs',
  placements: ['Footer'],
  authRequired: false,
  menu: 'left',
  component: FAQ
},
{
  default: false,
  exact: false,
  slug: 'forgot-password',
  route: '/forgot-password',
  label: 'Forgot Password',
  placements: [],
  authRequired: false,
  menu: 'left',
  component: ForgotPassword
},
{
  default: false,
  exact: false,
  slug: 'user-profile',
  route: '/user-profile',
  label: 'User Profile',
  placements: ['Header'],
  authRequired: true,
  menu: 'right',
  component: UserProfile
},
{
  default: false,
  exact: false,
  slug: 'confirm-password',
  route: '/confirm-password',
  label: 'Confirm Password',
  placements: [],
  authRequired: true,
  menu: 'right',
  component: ConfirmPassword
},
{
  default: false,
  exact: false,
  slug: "purchase-status",
  route: "/purchase-status",
  label: "Purchase Order",
  placements: ["Header"],
  authRequired: true,
  menu: "right",
  component: PurchaseOrder
},
{
  default: false,
  exact: false,
  slug: "password/reset",
  route: "/password/reset/:token/:email",
  label: "Reset Password",
  placements: [],
  authRequired: true,
  menu: "right",
  component: ResetPassword
}
]

const toastXHRError = code => {
  const error = genericErrors[code]
  if (error) {
    console.error(error.title, error.message)
  }
}

const setCurrentRoute = pathname => dispatch => {
  const payload = _.find(paths, ['route', pathname]);
  dispatch({ type: 'ROUTE_CHANGE', payload });
}

const MainLayout = props => {
  const isMobile = useMediaQuery({ maxWidth: 1224 });
  return <Layout {...props} className={`${props.className} ${isMobile ? 'mobile' : ''}`}>{props.children}</Layout>
}

class App extends Component {
  initialState = {
    loginStatus: false,
    isSessionExpiredModalShow: false
  }

  constructor(props) {
    super(props)
    axios.interceptors.response.use(response => response, (error) => {
      switch (error.response.status) {
        case 401: this.logout(); break
        case 404: toastXHRError(404); break
        case 500: toastXHRError(500); break
        case 502: toastXHRError(502); break
        case 503: toastXHRError(503); break
        default: break
      }
      return Promise.reject(error)
    })

    axios.defaults.transformResponse = axios.defaults.transformResponse.concat(
      // (response) => func(response)
    )

    this.state = this.initialState;
  }

  logout() {
    const self = this
    if (this.state.loginStatus && !this.state.isSessionExpiredModalShow && isLocalStorageEmpty()) {
      this.setState({ isSessionExpiredModalShow: true })
      Modal.info({
        title: 'Your session expired',
        content: (
          <div>
            <p>You will be redirected in home page.</p>
          </div>
        ),
        onOk() {
          clearLocalStorage();
          self.props.history.push("/");
          window.location.reload()
          // self.handleLoginStatus(false)
        },
      });
    }
    this.forceUpdate()

  }

  componentDidMount() {
    this.props.setCurrentRoute(this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    if (pathname !== prevProps.location.pathname) {
      this.props.setCurrentRoute(pathname);
    }
  }

  handleLoginStatus = status => {
    this.setState({ ...this.state, loginStatus: status });
  }

  render() {
    const { loginStatus } = this.state;
    const { route } = this.props;

    const currentSlug = route.current ? route.current.slug === 'order' ? route.current.slug : '' : '';

    return (
      <MainLayout className={`main-layout ${route.current ? route.current.slug : ''}`} >
        <Header {...this.props} paths={paths} handleLoginStatus={this.handleLoginStatus} />
        <Layout.Content className="main-layout-content">
          <Switch>
            {/* // this will scroll to top when switch changes. */}
            {window.scrollTo(0, 0)}
            {_.map(paths, path => <Route key={path.slug} exact={path.exact} path={path.route} component={path.component} />)}
            <Route component={NotFound} />
            <Redirect to='/404' />
          </Switch>
        </Layout.Content>
        {currentSlug === '' ?
          <Footer {...this.props} paths={paths} globalLinks={globalLinks} isLoggedIn={loginStatus} />
          : null
        }
      </MainLayout>
    )
  }
}

function mapStateToProps({ route }) {
  return { route }
}

export default withRouter(connect(mapStateToProps, { setCurrentRoute })(App))
