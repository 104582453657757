import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  reset,
} from "redux-form";
import { downloadFile } from "./downloadFile";
import {
  fetchDistributor,
  fetchDataFromSap,
  fetchPoStatus,
  postFeedback,
  fetchNotifications,
  approveRequest,
  rejectRequest,
} from "../../actions";
import * as _ from "lodash";
import {
  Layout,
  Row,
  Col,
  Input,
  Select,
  Icon,
  Table,
  List,
  Button,
  message,
  Modal,
  Dropdown,
  Menu,
  Checkbox,
  Collapse,
  Divider,
  Badge,
  Popover,
  DatePicker
} from "antd";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { numberFormat } from "../../utilities";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { PDFDocument, StandardFonts } from "pdf-lib";

import editIcon from "../../assets/svg/edit-icon.svg";
import DocumentMeta from "react-document-meta";
import config from "../../config";
import {
  fetchPermissions,
  fetchRecentOrders,
  fetchOrderStatus,
  postPoNumber,
  fetchDistributorOrderSummary
} from "../../actions";
import circle from "../../assets/images/home-banners/circle-pink.png";
import imgInvoiced from "../../assets/svg/invoiced.svg";
import imgInProceess from "../../assets/svg/inprocess.svg";
import sampleData from "./data.js";
import FilterIcon from "../../assets/icons/filter.svg";
import activeBrown from "../../assets/svg/brown.svg";

import "./OrderStatus.scss";
import { numFormat } from "../../utilities/format";
import rate1 from "../../assets/svg/rate-1.svg";
import rate2 from "../../assets/svg/rate-2.svg";
import rate3 from "../../assets/svg/rate-3.svg";
import rate4 from "../../assets/svg/rate-4.svg";
import rate5 from "../../assets/svg/rate-5.svg";
import recentOrders from "../../reducers/recentOrders";
import orderStatus from "../../reducers/orderStatus";

const wb = new ExcelJS.Workbook();
const CheckboxGroup = Checkbox.Group;
const plainOptions = ["EXCEL", "CSV", "PDF"];

const { Search } = Input;
const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const feedbackEmoji = [
  {
    rate: 1,
    svg: rate1,
  },
  {
    rate: 2,
    svg: rate2,
  },
  {
    rate: 3,
    svg: rate3,
  },
  {
    rate: 4,
    svg: rate4,
  },
  {
    rate: 5,
    svg: rate5,
  },
];

const renderInput = ({
  input,
  label,
  type,
  className,
  readOnly,
  meta: { touched, error, warning },
}) => (
    <div style={{ height: 50 }}>
      <input
        {...input}
        className={className}
        placeholder={label}
        readOnly={readOnly}
        type={type}
      />
      {touched &&
        ((error && <span style={{ color: "red" }}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );

const feedbackValidator = (value) =>
  value ? undefined : "Please provide your comments and suggestions";

class RecentOrder extends Component {
  orderStatusArray = [];
  // Inital State for the sorting
  sortState = {
    sortBy: 'distributor_orders.created_at',
    orderBy: 'asc'
  };

  excludedColumns = [
    'poCreatedAtWhole',
    'ORDER STATUS',
    'ORDERED QTY (IN CS)',
    'AMOUNT (IN USD)',
    'CBM',
    'GROSS WEIGHT'
    ];

  // Initial state for searching
  searchState = {};

  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      expand: [],
      tableData: [],
      keyword: "",
      filterBy: "distributor_orders.created_at",
      activeRate: null,
      showDownloadModal: false,
      showApprovalsModal: false,
      selectedRow: null,
      selectedRowKeys: [],
      indeterminate: false,
      checkAll: false,
      checkedList: [],
      showFeedbackModal: false,
      showQuestion: false,
      showThankYouModal: false,
      isPostingFeedback: false,
      isSendingResponse: false,
      // isApprovalsOpen: true,
      isDownloadOpen: false,
      showStatusUpdateModal: false,
      editPoNumberId: null,
      poInput: "",
      poNumber: "",
      isOpen: false,
      isUniquePoNumber: false,
      notifications: [],
      isApproveType: [""],
      urlTransactionNo: "",
      urlPoRef: "",
      urlResponse: "",
      radio: 'all',
      orderSummary: {},
      filterByValue: 'PO DATE'
    };
    this.imageUploader = React.createRef();
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  // showApprovals = () => {
  //   this.setState({ isApprovalsOpen: true });
  // };

  // hideApprovals = () => {
  //   this.setState({ isApprovalsOpen: false });
  // };

  showDownloadOptions = () => {
    this.setState({ isDownloadOpen: true });
  };

  hideDownloadOptions = () => {
    this.setState({ isDownloadOpen: false });
  };

  onChangeCheckbox = (checkedList) => {
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  componentDidMount() {
    this.props.fetchPermissions();
    this.props.fetchDistributorOrderSummary();
    this.props.fetchRecentOrders();
    this.props.fetchDataFromSap();
    this.props.fetchDistributor();
    this.props.fetchNotifications();
    // this.props.fetchDistributor().then(() => {
    //   this.props.fetchOrderStatus(this.getDataBasedOnCustomerCode());
    // });

    const urlParams = new URLSearchParams(window.location.search);
    const response = urlParams.get("response");
    const transaction_number = urlParams.get("transaction_number");
    const po_reference = urlParams.get("po_reference");

    if (this.state.isApprovalsOpen) {
      if (response) {
        this.setState({
          showApprovalsModal: true,
          urlTransactionNo: transaction_number,
          urlPoRef: po_reference,
          urlResponse: response,
          isApproveType: ["1"],
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const distributorCustomerCode =
      this.props.distributor &&
      this.props.distributor.data &&
      this.props.distributor.data.distributor.customer_code;
    const urlParams = new URLSearchParams(window.location.search);

    if (this.props.notificationsData.data !== nextProps.notificationsData.data) {
      this.setState({ notifications: nextProps.notificationsData.data });
    }

    if (this.props.orderStatus.data !== nextProps.orderStatus.data) {
      this.setState({ tableData: nextProps.orderStatus.data });
    }
    
    if (this.props.distributorOrderSummary.data !== nextProps.distributorOrderSummary.data) {
      this.setState({ orderSummary: nextProps.distributorOrderSummary.data });
    }

    // if (this.props.poStatus.data !== nextProps.poStatus.data) {
    //   this.setState({ tableData: nextProps.poStatus.data });
    // }

    if (this.props.sapData !== nextProps.sapData) {
      nextProps.sapData &&
        nextProps.sapData.data &&
        nextProps.sapData.data.map((data) => {
          if (
            Number(data.customer_po_code) === Number(distributorCustomerCode)
          ) {
            this.orderStatusArray.push({ ...data });
          }
        });
      this.props.fetchOrderStatus(this.orderStatusArray).then((response) => {
        if (!response.error) {
          if (urlParams.get("po_number") !== 'null') {
            if (urlParams.get("po_number") || urlParams.get("po_number") == '') {
              const updatedOrder = response.response.find(
                (value) =>
                  value.poNumber === urlParams.get("po_number") ||
                  value.status !== "in process"
              );
              if (updatedOrder) {
                this.setState({
                  showStatusUpdateModal: true,
                  distributorOrderId: updatedOrder.distributorOrderId,
                });
              }
            }
          }
        }
      });
    }
  }

  orderStatusDisplay(status) {
    status = "inProgress";
    if (status === "inProgress") {
      return (
        <div
          style={{
            padding: 10,
            backgroundColor: "#EF9643",
            borderRadius: 20,
            textAlign: "center",
          }}
        >
          <span style={{ whiteSpace: "nowrap", color: "white" }}>
            IN PROGRESS
          </span>
        </div>
      );
    }
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    // //function inside function, this will convert the dot string into actual object
    this.setState({ keyword: searchTerm });

    // const convertStringDotNotation = (desc, obj) => {
    //   var arr = desc.split(".");
    //   while (arr.length && (obj = obj[arr.shift()]));
    //   return obj;
    // };
    // const { orderStatus } = this.props;
    // const newArrayObj = orderStatus.data;

    // // if data not yet loaded condition to avoid error
    // if (!newArrayObj) {
    //   return;
    // }

    // //if SearchTerm is Empty return original data.
    // if (!searchTerm) {
    //   return this.setState({ tableData: newArrayObj });
    // }
    // let orderStatusArray = [];
    // newArrayObj.forEach((rowItem, i) => {
    //   searchKeys.forEach((keys) => {
    //     let rowItemKeys = convertStringDotNotation(keys, {
    //       ...rowItem,
    //     });
    //     // cause of error by using convert - toString()
    //     // .toString();
    //     let isFound =
    //       rowItemKeys &&
    //       (rowItemKeys.toString().toLowerCase().replace(/[$,]/g, "").includes(searchTerm.toLowerCase().replace(/[$,]/g, "")) || rowItemKeys.toString().toLowerCase().includes(searchTerm.toLowerCase()));
    //     if (isFound) orderStatusArray.push(rowItem);
    //   });
    // });
    // //Removed Duplicate Entry in Array.
    // const newArrayElement = orderStatusArray.filter(function (elem, index, self) {
    //   return index === self.indexOf(elem);
    // });
    // this.setState({ tableData: newArrayElement });

    searchTerm = Array.isArray(searchTerm) ? searchTerm.filter(date => date !== '') : searchTerm.trim() ;

    this.searchState = searchTerm.length < 1 ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.props.fetchOrderStatus(this.orderStatusArray, {...this.sortState, ...{
      page: 1
    }, ...this.searchState});
  };

  handleFilterBy = (value, isSaveState = true) => {
    let category = ''
    value === 'all'
      ? category = 'all'
      : value === 'PO DATE'
        ? category = 'distributor_orders.created_at'
        : value === 'CUSTOMER PO NUMBER'
          ? category = 'distributor_orders.sales_ref_number'
          : value === 'PO NUMBER'
            ? category = 'distributor_orders.po_reference'
            : value === 'ORDER NUMBER'
              ? category = 'distributor_orders.transaction_number'
              : value === 'ORDER STATUS'
                ? category = 'distributor_orders.status'
                : value === 'ORDERED QTY (IN CS)'
                  ? category = 'quantity'
                  : value === 'AMOUNT (IN USD)'
                    ? category = 'amount'
                    : value === 'CBM'
                      ? category = 'cbm'
                      : value === 'GROSS WEIGHT'
                        ? category = 'grossWeight'
                        : value === 'CONTAINER OPTION'
                          ? category = 'distributor_orders.volume'
                          : value === 'TARGET ETD'
                            ? category = 'distributor_orders.estimated_delivery'
                            : category = ''
    if (isSaveState) {
      this.setState({ filterBy: category, keyword: "", filterByValue: value });
    } else {
      return category;
    }
    

    // const { orderStatus } = this.props;
    // const newArrayObj = orderStatus.data;

    // // if data not yet loaded condition to avoid error
    // if (!newArrayObj) {
    //   return;
    // } else {
    //   // return original data
    //   return this.setState({ tableData: newArrayObj });
    // }
  };

  handleClickRate = (rate) => {
    this.setState({ activeRate: rate, showQuestion: true });
  };

  // handleApproveRequest(id) {
  //   this.props.approveRequest(id);
  // }

  // handleDeclineRequest(id) {
  //   this.props.declineRequest(id);
  // }

  handleApproveRequest = (notifId) => {
    const { approveRequest } = this.props;

    this.setState({ isSendingResponse: true });

    approveRequest(notifId).then((response) => {
      if (!response.error) {
        this.setState({
          showApprovalsModal: false,
          isSendingResponse: false,
        });
      } else {
        this.setState({
          showApprovalsModal: false,
          isSendingResponse: false,
        });
      }
      this.props.fetchNotifications();
      this.props.history.push("/order-status");
    });
  };

  handleRejectRequest = (notifId) => {
    const { rejectRequest } = this.props;

    this.setState({ isSendingResponse: true });

    rejectRequest(notifId).then((response) => {
      if (!response.error) {
        this.setState({
          showApprovalsModal: false,
          isSendingResponse: false,
        });
      } else {
        this.setState({
          showApprovalsModal: false,
          isSendingResponse: false,
        });
      }
      this.props.fetchNotifications();
      this.props.history.push("/order-status");
    });
  };

  handleFeedbackSubmit = (values) => {
    const { activeRate, distributorOrderId } = this.state;
    const { comments } = values;
    const { distributor, postFeedback } = this.props;

    if (!activeRate) {
      message.error("Please input your feedback");
      return;
    }

    const data = {
      po_date: moment().format("YYYY-MM-DD"),
      distributor_id: distributor.data.distributor_id,
      comments: comments,
      feedback_score: activeRate,
      feedback_type: "order fulfillment",
      distributor_order_id: distributorOrderId,
    };

    this.setState({ isPostingFeedback: true });

    postFeedback(data).then((response) => {
      if (!response.error) {
        this.setState({
          activeRate: null,
          showThankYouModal: true,
          showFeedbackModal: false,
          isPostingFeedback: false,
        });
      } else {
        this.setState({
          activeRate: null,
          showFeedbackModal: false,
          isPostingFeedback: false,
        });
      }
      this.props.history.push("/order-status");
    });
  };

  handleSavePoNumber = (orderId) => {
    const distributorCustomerCode =
      this.props.distributor &&
      this.props.distributor.data &&
      this.props.distributor.data.distributor.customer_code;
    const { poInput, isUniquePoNumber } = this.state;

    if (isUniquePoNumber) {
      message.error("Po Number must be Unique");
    } else {
      this.props.postPoNumber(orderId, poInput).then((response) => {
        if (!response.error) {
          this.props.sapData &&
            this.props.sapData.data &&
            this.props.sapData.data.map((data) => {
              if (
                Number(data.customer_po_code) ===
                Number(distributorCustomerCode)
              ) {
                this.orderStatusArray.push({ ...data });
              }
            });
          this.props.fetchOrderStatus(this.orderStatusArray, {...this.sortState, ...{
            page: 1
          }, ...this.searchState}).then((response) => {
            if (!response.error) {
              this.setState({ editPoNumberId: null, poInput: "" });
            }
          });
        }
      });
    }
  };

  onChangePoNumber = (poNumber) => {
    poNumber = poNumber.replace(
      /[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi,
      ""
    );

    const findUniquePoNumber = this.state.tableData.data.find(
      (row) => row.poNumber.toLowerCase() == poNumber.toLowerCase()
    );

    this.setState({
      poInput: poNumber,
      isUniquePoNumber: findUniquePoNumber === undefined ? false : true,
    });
  };

  // download(downloadType, rowData) {
  //   const distributorCustomerCode =
  //     this.props.distributor &&
  //     this.props.distributor.data &&
  //     this.props.distributor.data.distributor.customer_code;

  //   let sapArray = [];
  //   this.props.sapData.data.map((data) => {
  //     if (Number(data.customer_po_code) === Number(distributorCustomerCode)) {
  //       sapArray.push({ ...data });
  //     }
  //   });
  //   this.props.fetchPoStatus(sapArray, rowData.poNumber).then((res) => {
  //     const passedData = {
  //       ...rowData,
  //       orderItem: res.response,
  //     };
  //     this.props.downloadFile[downloadType](passedData);
  //   });
  // }

  download(downloadType, rowDataArray) {
    const { checkedList } = this.state
    checkedList.includes('EXCEL') && downloadFile.EXCEL(rowDataArray);
    checkedList.includes('CSV') && downloadFile.CSV(rowDataArray);
    checkedList.includes('PDF') && downloadFile.PDF(rowDataArray);
    // this.props.downloadFile[downloadType](rowDataArray)
  }

  // matchDataFromPendingUrl = (transNo, poRef) => {
  //   const { urlTransactionNo, urlPoRef } = this.state;
  //   if (urlTransactionNo === transNo && urlPoRef === poRef) {
  //     return (
  //       <img
  //         src={activeBrown}
  //         alt="active"
  //         style={{
  //           width: "0.7rem",
  //           height: "0.7rem",
  //           marginRight: "10px",
  //         }}
  //       />
  //     );
  //   }
  // };

  getStatus = sapData => {
    sapData = sapData || "";
    let status;
    if (sapData["billing_document"]) status = "invoiced";
    else if (sapData["delivery"]) status = "forLoading";
    else if (sapData["sales_document"]) status = "produced";
    else if (!sapData["sales_document"] && !sapData["delivery"] && !sapData["billing_document"]) status = "forProduction";
    else status = "";
    return status;
  };

  render() {
    const {
      filterBy,
      activeRate,
      showFeedbackModal,
      showQuestion,
      showThankYouModal,
      isPostingFeedback,
      isSendingResponse,
      showStatusUpdateModal,
      showDownloadModal,
      showApprovalsModal,
      checkedList,
      keyword,
      selectedRow,
      selectedRowKeys,
      radio,
      orderSummary,
      filterByValue
    } = this.state;

    let { notifications } = this.state;
    notifications = notifications ? notifications : [];


    const urlParams = new URLSearchParams(window.location.search);
    const {
      myPermissions,
      orderStatus,
      handleSubmit,
      sapData,
      poStatus,
      fetchPoStatus,
      fetching,
      distributor,
    } = this.props;


    // const pendingApprovals = notifications.filter((item) => {
    //   return (
    //     item.notif_type === "sku replacement" &&
    //     item.response === null &&
    //     item.distributor_id === distributor.data.distributor_id
    //   );
    // });
    // const pendingApprovalsCount = pendingApprovals.length;

    // const previousApprovals = notifications.filter((item) => {
    //   return (
    //     item.notif_type === "sku replacement" &&
    //     item.response !== null &&
    //     item.distributor_id === distributor.data.distributor_id
    //   );
    // });

    // const previousApprovalsCount = previousApprovals.length;

    // const content = (
    //   <div>
    //     <p>
    //       <strong>{pendingApprovalsCount}</strong> pending approvals here!
    //     </p>
    //   </div>
    // );


    let dataSource = this.state.tableData || [];
    let orderStatusData = dataSource.data || [];

    // dataSource.map((data, i) => {
    //   data.sapStatus = []

    //   sapData.data.map(sap => {
    //     data.distributor.product.map(product => {
    //       if (sap) {
    //         if(data.poNumber === sap.customer_po_number && product.variant.material_code === sap.material){
    //           data.sapStatus.push({
    //             status: this.getStatus(sap)
    //           })
    //         }
    //       } else {
    //         data.sapStatus.push({
    //           status: 'noSap'
    //         })
    //       }
    //     })
    //   })
    // })


    // const toggleApproval = () => {
    //   let approvalsContainer = document.getElementById("approvals-container");
    //   let approvalsWidget = document.getElementById("approvals-widget");

    //   // if (approvalsWidget.style.display === "none") {
    //   //   approvalsWidget.style.display = "block";
    //   //   approvalsContainer.style.display = "none";
    //   // } else {
    //   //   approvalsWidget.style.display = "none";
    //   //   approvalsContainer.style.display = "flex";
    //   // }
    // };

    // const downloadData = dataSource.map(value => {
    //   return {
    //     customer_po_number: value.customer_po_number,
    //     po_number: value.po_number,
    //     status: value.status === "in_process" ? "In Process" : "Invoiced",
    //     total_amount: value.total_amount,
    //     total_quantity: value.total_quantity,
    //     total_cbm: value.total_cbm.toFixed(2),
    //     etd: value.etd && moment(value.etd).format("MM-YYYY"),
    //     po_date: value.po_date
    //   }
    // })

    // dataSource =
    //   radio === 'all'
    //     ? dataSource :
    //     radio === "in process"
    //       ? dataSource.filter(value => value.status === 'In Process')
    //       : radio === "invoiced"
    //         ? dataSource.filter(value => value.status === 'Invoiced')
    //         : radio === 'cancelled'
    //           ? dataSource.filter(value => value.status === 'Cancelled')
    //           : 'no data'

    let downloadDataArray = []
    orderStatusData.map(data => {
      selectedRowKeys.map(rowKey => {
        if (data.orderNumber === rowKey) {
          downloadDataArray.push(data)
        }
      })
    })


    let isLoading =
      orderStatus.fetching || orderStatus.postPoLoading || sapData.fetching;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "id",
          text: "Select Odd Row",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
      getCheckboxProps: record => ({
        disabled: record.distributor.status === 0,
        name: record.distributor.status,
      })
    };


    const columns = [
      {
        title: "PO DATE",
        dataIndex: "poCreatedAt",
        key: "PO DATE",
        sorter: (a, b) =>
          { /* moment(a.poCreatedAt).unix() - moment(b.poCreatedAt).unix() */},
        render: ({ }, value) => <span>{value.poCreatedAt}</span>,
      },
      {
        title: "poCreatedAtWhole",
        dataIndex: "poCreatedAtWhole",
        key: "poCreatedAtWhole"
      },
      {
        title: (
          <span style={{ padding: 0 }}>
            CUSTOMER
            <br />
            PO NUMBER
          </span>
        ),
        dataIndex: "salesRefNumber",
        key: "CUSTOMER PO NUMBER",
        render: (value, i) => (
          <Link
            to={`/purchase-status?po_number=${encodeURIComponent(
              i.poNumber
            )}&po_date=${i.poCreatedAt}&order_number=${i.orderNumber
              }&sales_ref_no=${encodeURIComponent(i.salesRefNumber)}&target_etd=${i.targetedEtd
              }&status=${i.status === 'In Process' ? '1' : i.status === 'Cancelled' ? '0' : '2'
              }`}
            style={{
              color: "#0785E3",
              fontWeight: "normal",
              fontFamily: "Montserrat",
              padding: 0
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        title: (
          <>
            PO
            <br />
            NUMBER
          </>
        ),
        dataIndex: "poNumber",
        key: "PO NUMBER",
        sorter: (a, b) => {
          // Number(a.poNumber.split("-").pop()) -
          // Number(b.poNumber.split("-").pop())
        },
        render: (value, i) => (
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                minWidth: 150,
              }}
            >
              {
                this.state.editPoNumberId === i.distributorOrderId ? (
                  <div style={{ display: "column" }}>
                    <Input
                      value={this.state.poInput}
                      placeholder={value}
                      onChange={(e) => this.onChangePoNumber(e.target.value)}
                      maxLength={20}
                    />
                    {!this.state.poInput && (
                      <span style={{ color: "red", fontSize: 11 }}>
                        Required
                      </span>
                    )}
                  </div>
                ) : (
                    <>
                      {this.state.editPoNumberId !== i.distributorOrderId ? (
                        <Button
                          type="link"
                          disabled={
                            !myPermissions.some(
                              (r) => r.name === "distributor.orders.edit"
                            )
                          }
                          title="Edit"
                          style={{
                            padding: "0 5px",
                            color: "#0785E3",
                            background: "transparent",
                          }}
                          onClick={() =>
                            this.setState({
                              editPoNumberId: i.distributorOrderId,
                              poInput: value,
                            })
                          }
                        >
                          <img
                            src={editIcon}
                            alt="edit-icon"
                            className="order-status-edit-icon"
                            style={{ zIndex: 2 }}
                          />
                        </Button>
                      ) : (
                          ""
                        )}
                      <Link
                        to={`/purchase-status?po_number=${encodeURIComponent(
                          value
                        )}&po_date=${i.poCreatedAt}&order_number=${i.orderNumber
                          }&sales_ref_no=${encodeURIComponent(
                            i.salesRefNumber
                          )}&container_option=${i.containerOption}&target_etd=${i.targetedEtd}&status=${i.status === 'In Process' ? '1' : i.status === 'Cancelled' ? '0' : '2'}`}
                        style={{
                          color: "#0785E3",
                          fontWeight: "normal",
                          fontFamily: "Montserrat",
                          // whiteSpace: "nowrap"
                        }}
                      >
                        {value}
                      </Link>
                    </>
                  )
              }
            </div>
            {this.state.editPoNumberId === i.distributorOrderId ? (
              <div style={{ display: "flex", marginLeft: "5px" }}>
                <Button
                  shape="circle"
                  icon="save"
                  onClick={() => this.handleSavePoNumber(i.distributorOrderId)}
                  // style={{ background: '#FF4D4F', color: 'white', border: '1px solid #FF4D4F' }}
                  disabled={!this.state.poInput}
                  title="Save"
                  type="primary"
                  style={{ zIndex: 2 }}
                />
                <Button
                  shape="circle"
                  icon="close"
                  type="primary"
                  title="close"
                  onClick={() =>
                    this.setState({ editPoNumberId: null, poInput: "" })
                  }
                  style={{
                    background: "#FF4D4F",
                    color: "white",
                    border: "1px solid #FF4D4F",
                    margin: "0 5px",
                    zIndex: 2
                  }}
                />
              </div>
            ) : (
                ""
                // <Button
                //   title="Edit"
                //   style={{ border: 'none',  }}
                //   onClick={() =>
                //     this.setState({
                //       editPoNumberId: i.distributorOrderId,
                //       poInput: value
                //     })

                //   }
                // ><img src={editIcon} /></Button>
              )}
          </div>
        ),
      },
      {
        title: (
          <>
            ORDER
            <br />
            NUMBER
          </>
        ),
        dataIndex: "orderNumber",
        sorter: (a, b) => { /* Number(a.orderNumber) - Number(b.orderNumber) */},
        key: "ORDER NUMBER",
      },
      // {
      //   title: "REMARKS",
      //   dataIndex: "distributor.remarks",
      //   sorter: (a, b) => a.distributor.remarks.length - b.distributor.remarks.length,
      //   key: "REMARKS",
      //   align: "center",
      // },
      {
        title: "ORDER STATUS",
        dataIndex: "status",
        sorter: (a, b) => { /* a.status.length - b.status.length */},
        key: "ORDER STATUS",
        align: "center",
        render: (value, row) => {
          const distributorOrderStatus = row.status;
          return (



            <div className="order-status-tag">
              {distributorOrderStatus === 'In Process' ? (<span className="in-process">IN PROCESS</span>) :
                (distributorOrderStatus === 'Cancelled') ? (
                  <Popover
                    style={{ position: 'absolute', top: '-10%', left: '-40%' }}
                    content={'Remarks: ' + row.distributor.remarks}
                    trigger="hover"
                  >
                    <span className="cancelled">CANCELLED</span>
                  </Popover>
                ) :
                  (<span className="invoiced">INVOICED</span>)
              }
            </div>
          );
        },
      },
      {
        title: (
          <span>
            ORDERED
            <br />
            QTY (IN CS)
          </span>
        ),
        dataIndex: "orderedQty",
        key: "ORDERED QTY (IN CS)",
        align: "right",
      },
      {
        title: (
          <span>
            AMOUNT
            <br />
            (IN USD)
          </span>
        ),
        dataIndex: "amount",
        key: "AMOUNT (IN USD)",
        align: "right",
        render: (value) => <span>${value}</span>,
      },
      // {
      //   title: (
      //     <span>
      //       INVOICED
      //       <br />
      //       QTY (IN CS)
      //     </span>
      //   ),
      //   dataIndex: "invoiceQty",
      //   key: "INVOICED QTY (IN CS)",
      //   align: "right",
      // },
      {
        title: "CBM",
        dataIndex: "cbm",
        key: "CBM",
        align: "right",
      },
      {
        title: (<span>GROSS<br />WEIGHT</span>),
        dataIndex: "grossWeight",
        key: "GROSS WEIGHT",
        align: "right",
        render: (value) => <span>{value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</span>,
      },
      {
        title: (<span>CONTAINER<br />OPTION</span>),
        dataIndex: "containerOption",
        key: "CONTAINER OPTION",
      },
      {
        title: (<span>TARGET<br />ETD</span>),
        dataIndex: "targetedEtd",
        key: "TARGET ETD",
        align: "left",
        // width: 50,
        sorter: (a, b) =>
          { /* moment(a.targetedEtd).unix() - moment(b.targetedEtd).unix() */},
        render: ({ }, value) => {
          let color = value.status === "invoiced" ? " #479667" : "#EF9643";
          return (
            <span style={{ color: color, fontSize: 14, fontWeight: 600, overflowWrap: 'break-word', wordWrap: 'break-word' }}>
              {value.targetedEtd}
            </span>
          );
        },
      },


      // {
      //   title: "ACTION",
      //   dataIndex: "",
      //   key: "",
      //   align: "center",
      //   className: !myPermissions.some(
      //     (r) => r.name === "distributor.orders.download"
      //   ) ? 'column-hide' : '',
      //   render: ({ }, value) => {
      //     if (value.distributor.status === 1) {
      //       return (
      //         <div
      //           onClick={(e) => {
      //             e.stopPropagation();
      //           }}
      //         >
      //           <Dropdown
      //             overlay={
      //               <Menu>
      //                 <div style={{ padding: 10, fontWeight: "bold" }}>
      //                   <span>Download File</span>
      //                 </div>
      //                 <Menu.Item onClick={(e) => this.download("pdf", value)}>
      //                   PDF
      //                 </Menu.Item>
      //                 <Menu.Item onClick={(e) => this.download("csv", value)}>
      //                   CSV
      //                 </Menu.Item>
      //                 <Menu.Item onClick={(e) => this.download("excel", value)}>
      //                   EXCEL
      //                 </Menu.Item>
      //               </Menu>
      //             }
      //           >
      //             <Icon type="download" />
      //           </Dropdown>
      //         </div>
      //       );
      //     }
      //     return ""
      //   },
      // }
    ];


    const meta = {
      title: "Order History - URC Global Exports",
      description:
        "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Order History",
        },
      },
    };


    return (
      <DocumentMeta {...meta}>
        <Layout>
          <Layout.Content className="order-status-content">
            {/* <Link>
              <Icon type="left" /> Back to Order Status Page
            </Link> */}

            <Row className="order-status-row">
              <Col span={24}>
                <Row className='summary-header-first'>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <h1>Order History</h1>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Row className='summary-header-second'>
                      <Col md={8} sm={24} xs={24}>
                        <div className='total-text'>
                          <h1>TOTAL ORDERS   <span>
                            {orderSummary?.total_order || 0}
                          </span></h1>
                        </div>
                      </Col>
                      <Col md={8} sm={24} xs={24}>
                        <div className='total-text'>
                          <h1>TOTAL IN PROCESS    <span>
                            {orderSummary?.total_in_progress || 0}
                          </span></h1>
                        </div>
                      </Col>
                      <Col md={8} sm={24} xs={24}>
                        <div className='total-text'>
                          <h1>TOTAL INVOICED   <span>
                            {orderSummary?.total_invoice || 0}
                          </span></h1>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <MediaQuery minWidth={1024}>
                  <Row type="flex" className="order-status-filters">
                    <Col md={10} lg={10} className="search-container">
                    {['distributor_orders.created_at', 'distributor_orders.estimated_delivery'].includes(filterBy) ? 
                    <RangePicker className='search-date-container' 
                                style={ { width: 300 } }
                                size="large"
                                onChange={(date, dateString) => this.handleSearch(dateString, filterBy)}/>
                    
                    :
                    <Input
                        type="text"
                        style={{ width: 300 }}
                        prefix={<Icon type="search" />}
                        placeholder="Search"
                        size="large"
                        value={keyword}
                        onChange={(e) =>
                          this.handleSearch(
                            e.target.value,
                            filterBy === "all"
                              ? columns.map((value) => value.dataIndex)
                              : [filterBy]
                          )
                        }
                      /> 
                      }
                      <Select
                        style={{ width: 100 }}
                        placeholder="Filter"
                        size="large"
                        defaultValue={filterByValue}
                        dropdownClassName="recent-order-select"
                        onChange={(value) => this.handleFilterBy(value)}
                        showSearch
                      >
                        {/* <Option value="all">All</Option> */}

                        {columns.map((value) => (
                          <Option key={value.key} value={value.key}>
                            {value.key}
                          </Option>
                        )).filter(value => !this.excludedColumns.includes(value.key))}

                        {/* <Option value="created_at">Created Date</Option>
                        <Option value="so_number">SO Number</Option>
                        <Option value="po_number">PO Number</Option>
                        <Option value="status">Status</Option>
                        <Option value="actual_stocks">Actual Stocks</Option>
                        <Option value="amount">Amount ( IN USD) </Option>
                        <Option value="cbm">CBM</Option>
                        <Option value="gross_weight">Gross Weight</Option> */}
                      </Select>

                    </Col>



                    <Col xs={24} sm={24} md={14} lg={14} style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                      <Col xs={24} sm={24} md={7} lg={7} style={{ marginRight: 10 }}>
                        <Button
                          className="btn-small btn-secondary-gold"
                          disabled={
                            selectedRowKeys.length === 0
                          }
                          onClick={() =>
                            this.setState({ showDownloadModal: true })
                          }
                          style={{ width: "100%" }}
                        >
                          <Icon type="line-chart" />Extract Report
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={9} lg={9} style={{ marginRight: 10 }}>
                        <Button
                          className="btn-small btn-primary"
                          onClick={() =>
                            this.props.history.push("/container-dashboard")
                          }
                          style={{ width: "100%" }}
                        >
                          <Icon type="layout" />Container Summary Breakdown
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8}>
                        <Button
                          className="btn-small btn-primary"
                          onClick={() =>
                            this.props.history.push("/summary-dashboard")
                          }
                          style={{ width: "100%" }}
                        >
                          <Icon type="layout" />OMS Summary Dashboard
                        </Button>
                      </Col>

                      {/* <Col xs={24} sm={24} md={7} lg={7} style={{ padding: 10 }} >
                          <Button
                            className="btn-small btn-secondary-gold"
                            disabled={
                              selectedRowKeys.length === 0
                            }
                            onClick={() => this.setState({ showDownloadModal: true })}
                            style={{ width: "100%" }}><Icon type="line-chart" />Extract Multiple POs</Button>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} style={{ padding: 10 }}>
                          <Button className="btn-small btn-primary" onClick={() => this.props.history.push("/summary-dashboard")} style={{ width: "100%" }}><Icon type="layout" />OMS Summary Dashboard</Button>
                        </Col>
                        <Col xs={24} sm={24} md={9} lg={9} style={{ padding: 10 }}>
                          <Button className="btn-small btn-primary" onClick={() => this.props.history.push("/container-dashboard")} style={{ width: "100%" }}><Icon type="layout" />Container Summary Breakdown</Button>
                        </Col> */}
                    </Col>
                  </Row>
                  <Row type="flex" style={{ padding: 15 }}>
                    <label className="radio">
                      ALL
                        <input
                        type="radio"
                        name="status"
                        checked={radio === "all"}
                        value="all"
                        onChange={(e) => {
                          delete this.sortState.filters;
                          this.props.fetchOrderStatus(this.orderStatusArray, {...this.sortState, ...{
                            page: 1
                          }, ...this.searchState})

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      IN PROCESS
                        <input
                        type="radio"
                        name="status"
                        checked={radio === "in process"}
                        value="in process"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify( { 'distributor_orders.status': [1] } )
                          this.props.fetchOrderStatus(this.orderStatusArray, {...this.sortState, ...{
                            page: 1
                          }, ...this.searchState})

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      INVOICED
                        <input
                        type="radio"
                        name="status"
                        checked={radio === "invoiced"}
                        value="invoiced"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify( { 'distributor_orders.status': [2] } )
                          this.props.fetchOrderStatus(this.orderStatusArray, {...this.sortState, ...{
                            page: 1
                          }, ...this.searchState})

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      CANCELLED
                        <input
                        type="radio"
                        name="status"
                        checked={radio === "cancelled"}
                        value="cancelled"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify( { 'distributor_orders.status': [0] } )
                          this.props.fetchOrderStatus(this.orderStatusArray, {...this.sortState, ...{
                            page: 1
                          }, ...this.searchState})

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Row>
                </MediaQuery>
                <MediaQuery maxWidth={1024}>
                  <Row type="flex" className="order-status-filters">
                    <Col span={24} className="search-container">
                    {['distributor_orders.created_at', 'distributor_orders.estimated_delivery'].includes(filterBy) ? 
                      <RangePicker className='search-date-container' 
                      style={ { width: "95%" } }
                      size="large"
                      onChange={(date, dateString) => this.handleSearch(dateString, filterBy)}/>
                      
                      :
                      <Input
                        type="text"
                        prefix={<Icon type="search" />}
                        placeholder="Search Products"
                        size="large"
                        value={keyword}
                        onChange={(e) =>
                          this.handleSearch(
                            e.target.value,
                            filterBy === "all"
                              ? columns.map((value) => value.dataIndex)
                              : [filterBy]
                          )
                        }
                      />
                        }
                      <div className="filter">
                        <Select
                          suffixIcon={<img src={FilterIcon} />}
                          size="large"
                          defaultValue={[filterBy]}
                          onChange={(value) => this.handleFilterBy(value)}
                          dropdownClassName="--select"
                          autoFocus={true}
                          showSearch
                        >
                          {columns.map((value) => (
                            <Option
                              key={value.key}
                              value={value.key}
                            >
                              {value.key}
                            </Option>
                          )).filter(value => !this.excludedColumns.includes(value.key))}
                        </Select>
                      </div>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Row type="flex" justify="end" gutter={22}>
                          <Col xs={24} sm={24} md={8} lg={8}>
                            <Button
                              className="btn-small btn-secondary-gold"
                              disabled={
                                !myPermissions.some(
                                  (r) => r.name === "distributor.orders.download"
                                )
                              }
                              onClick={() =>
                                this.setState({ showDownloadModal: true })
                              }
                              style={{ width: "100%" }}
                            >
                              <Icon type="line-chart" />
                              Extract Report
                            </Button>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8}>
                            <Button
                              className="btn-small btn-primary"
                              onClick={() =>
                                this.props.history.push("/summary-dashboard")
                              }
                              style={{ width: "100%" }}
                            >
                              <Icon type="layout" />View OMS Summary Dashboard
                            </Button>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8}>
                            <Button
                              className="btn-small btn-primary"
                              onClick={() =>
                                this.props.history.push("/summary-dashboard")
                              }
                              style={{ width: "100%" }}
                            >
                              <Icon type="layout" />View Container Summary Breakdown
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                </MediaQuery>
              </Col>
              <MediaQuery minWidth={1024}>
                <Col span={24} className="order-status-table">
                  <Table
                    rowSelection={rowSelection}
                    dataSource={orderStatusData}
                    columns={columns}
                    components={{

                      header: {
                        row: (props) => {
                          return (
                            <tr >
                              {props.children.filter(child => child.key !== 'poCreatedAtWhole')}
                            </tr>
                          );
                        }
                      },

                      body: {
                        row: (props) => {
                          return (
                            <tr onClick={(e) => {
                              e.preventDefault();

                              const {
                                poNumber,
                                poCreatedAt,
                                orderNumber,
                                salesRefNumber,
                                containerOption,
                                status,
                                targetedEtd,
                              } = props.children[0].props.record;

                              this.props.history.push(
                                `purchase-status?po_number=${poNumber}&po_date=${poCreatedAt}&order_number=${orderNumber}&sales_ref_no=${salesRefNumber}&container_option=${containerOption}&target_etd=${targetedEtd}&status=${status === 'Cancelled' ? 0 : status === 'In Process' ? 1 : 2}`
                              );
                            }}>
                              {props.children.filter(child => child.key !== 'poCreatedAtWhole')}
                            </tr>
                          );
                        }
                      },

                    }}
                    rowKey="orderNumber"
                    style={{ overflow: "hidden", width: "100%", zIndex: 0 }}
                    loading={isLoading}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (e) => {
                          e.preventDefault();

                          const {
                            poNumber,
                            poCreatedAt,
                            orderNumber,
                            salesRefNumber,
                            containerOption,
                            status,
                            targetedEtd,
                          } = record;

                          this.props.history.push(
                            `purchase-status?po_number=${poNumber}&po_date=${poCreatedAt}&order_number=${orderNumber}&sales_ref_no=${salesRefNumber}&container_option=${containerOption}&target_etd=${targetedEtd}&status=${status === 'Cancelled' ? 0 : status === 'In Process' ? 1 : 2}`
                          );

                        },
                      };
                    }}
                    onChange={(pagination, filters, sorter) => {
                      // document.body.scrollTop = 0; // For Safari
                      // document.documentElement.scrollTop = 0;
                      this.sortState = {
                        sortBy: this.handleFilterBy(sorter.columnKey, false) || this.sortState.sortBy,
                        orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
                        filters: this.sortState.filters
                      }
                  
                      if (!this.sortState.filters) delete this.sortState.filters;

                      if (!sorter.order) {
                        ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
                      }
                  
                      this.props.fetchOrderStatus(this.orderStatusArray, {...this.sortState, ...{
                        page: pagination.current
                      }, ...this.searchState});
                    }}
                    pagination={{ total: dataSource.total, current: dataSource.current_page, pageSize: dataSource.per_page || 10 }}
                  />
                </Col>
              </MediaQuery>
              <MediaQuery minWidth={520} maxWidth={1024}>
                <Col span={24}>
                  <List
                    size="large"
                    loading={isLoading}
                    bordered
                    dataSource={orderStatusData}
                    renderItem={(item) => (
                      <List.Item key={item.materialCode}>
                        <Row style={{ width: "100%" }}>
                          <Col span={12}>
                            <h4>PO Number</h4>
                            <h4>PO Date</h4>
                            <h4>Order Number</h4>
                            <h4>Customer PO Number</h4>
                            {/* <h4>Order Status</h4> */}
                            <h4>Ordered Quantity (IN CS)</h4>
                            {/* <h4>Invoiced Quantity (IN CS)</h4> */}
                            <h4>Amount (IN USD)</h4>
                            <h4>CMB</h4>
                            <h4>Target ETD</h4>
                          </Col>
                          <Col span={12}>
                            <h4>
                              {/* <Link
                                to={`/purchase-status?po_number=${item.poNumber}&po_date=${item.poCreatedAt}&order_number=${item.orderNumber}&sales_ref_no=${item.salesRefNumber}&target_etd=${item.targetedEtd}`}
                                style={{
                                  color: "#82A8C5",
                                  fontWeight: "bold"
                                }}
                              >
                                {c}
                              </Link> */}

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  zIndex: 2
                                }}
                              >
                                <div
                                  style={{ margin: "0 10px", minWidth: 120, zIndex: 2 }}
                                >
                                  {
                                    // this.state.poInput
                                    this.state.editPoNumberId ===
                                      item.distributorOrderId ? (
                                        <div style={{ display: "column", zIndex: 2 }}>
                                          <Input
                                            value={this.state.poInput}
                                            placeholder={item.poNumber}
                                            onChange={(e) =>
                                              this.onChangePoNumber(
                                                e.target.value
                                              )
                                            }
                                          // onChange={e =>
                                          //   this.setState({
                                          //     poInput: e.target.value
                                          //   })
                                          // }
                                          />{" "}
                                          {!this.state.poInput && (
                                            <span
                                              style={{
                                                color: "red",
                                                fontSize: 10,
                                              }}
                                            >
                                              Required
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <Link
                                          to={`/purchase-status?po_number=${encodeURIComponent(
                                            item.poNumber
                                          )}&po_date=${item.poCreatedAt
                                            }&order_number=${item.orderNumber
                                            }&sales_ref_no=${encodeURIComponent(
                                              item.salesRefNumber
                                            )}&target_etd=${item.targetedEtd}
                                            &status=${item.status}`}
                                          style={{
                                            color: "#82A8C5",
                                            fontWeight: "bold",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {item.poNumber}
                                        </Link>
                                      )
                                  }
                                </div>
                                {this.state.editPoNumberId ===
                                  item.distributorOrderId ? (
                                    <div style={{ display: "flex" }}>
                                      <Button
                                        shape="circle"
                                        icon="save"
                                        onClick={() =>
                                          this.handleSavePoNumber(
                                            item.distributorOrderId
                                          )
                                        }
                                        // style={{ background: '#FF4D4F', color: 'white', border: '1px solid #FF4D4F' }}
                                        disabled={!this.state.poInput}
                                        title="Save"
                                        type="primary"
                                      />
                                      <Button
                                        shape="circle"
                                        icon="close"
                                        type="primary"
                                        title="close"
                                        onClick={() =>
                                          this.setState({
                                            editPoNumberId: null,
                                            poInput: "",
                                          })
                                        }
                                        style={{
                                          background: "#FF4D4F",
                                          color: "white",
                                          border: "1px solid #FF4D4F",
                                          margin: "0 5px",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <Button
                                      shape="circle"
                                      icon="edit"
                                      type="primary"
                                      title="Edit"
                                      onClick={() =>
                                        this.setState({
                                          editPoNumberId: item.distributorOrderId,
                                          poInput: item.poNumber,
                                        })
                                      }
                                    />
                                  )}
                              </div>
                            </h4>
                            <h4>{item.poCreatedAt}</h4>
                            <h4>{item.orderNumber}</h4>
                            <h4>
                              <Link
                                to={`/purchase-status?po_number=${encodeURIComponent(
                                  item.poNumber
                                )}&po_date=${item.poCreatedAt}&order_number=${item.orderNumber
                                  }&sales_ref_no=${encodeURIComponent(
                                    item.salesRefNumber
                                  )}&target_etd=${item.targetedEtd}
                                  &status=${item.status}`}
                              >
                                {item.salesRefNumber || "N/A"}
                              </Link>
                            </h4>
                            {/* <h4>
                              {item.distributor.status === 0
                                ? "CANCELLED"
                                : item.status === "invoiced"
                                  ? "INVOICED"
                                  : "IN PROCESS"}
                            </h4> */}
                            <h4>{item.orderedQty}</h4>
                            {/* <h4>{item.invoiceQty}</h4> */}
                            <h4>{item.amount}</h4>
                            <h4>{item.cbm}</h4>
                            <h4>{item.targetedEtd}</h4>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                    pagination={{ total: dataSource.total, current: dataSource.current_page, pageSize: dataSource.per_page || 10, 
                      onChange: page => {
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0;

                        this.props.fetchOrderStatus(this.orderStatusArray, {...this.sortState, ...{
                          page: page
                        }, ...this.searchState});
                      } }}
                  />
                </Col>
              </MediaQuery>

              <MediaQuery maxWidth={520}>
                <Col span={24}>
                  <List
                    size="large"
                    loading={isLoading}
                    bordered
                    dataSource={orderStatusData}
                    renderItem={(item) => (
                      <List.Item>
                        <Row
                          type="flex"
                          justify="space-between"
                          align="middle"
                          className="order-status-xs-content"
                        >
                          <Col span={12}>
                            <label>po number</label>
                          </Col>
                          <Col span={12} align="end">
                            <Link
                              to={`/purchase-status?po_number=${encodeURIComponent(
                                item.poNumber
                              )}&po_date=${item.poCreatedAt}&order_number=${item.orderNumber
                                }&sales_ref_no=${encodeURIComponent(
                                  item.salesRefNumber
                                )}&target_etd=${item.targetedEtd}
                                &status=${item.status === 'In Process' ? '1' : item.status === 'Cancelled' ? '0' : '2'}`}
                            >
                              {item.poNumber}
                            </Link>
                          </Col>

                          {/* <Col span={12}>
                            <label>order status</label>
                          </Col>
                          <Col span={12} align="end">
                            {item.distributor.status === 0 ? (
                              <div className="cancelled">CANCELLED</div>
                            ) : item.status === "invoiced" ? (
                              <div className="invoiced">INVOICED</div>
                            ) : (
                                  <div className="in-process">IN PROCESS</div>
                                )}
                          </Col> */}

                          <Col span={12}>
                            <label>target etd</label>
                          </Col>
                          <Col span={12} align="end">
                            <span className="target-etd">
                              {item.targetedEtd}
                            </span>
                          </Col>

                          <Col span={12}>
                            <label>po date</label>
                          </Col>
                          <Col span={12} align="end">
                            <span className="create-at theme-color">
                              {item.poCreatedAt}
                            </span>
                          </Col>

                          <Col span={12}>
                            <label>order number</label>
                          </Col>
                          <Col span={12} align="end">
                            <span className="order-num theme-color">
                              {item.orderNumber}
                            </span>
                          </Col>

                          <Col span={12}>
                            <label>customer po number</label>
                          </Col>
                          <Col span={12} align="end">
                            <span className="customer-po-num">
                              <Link
                                to={`/purchase-status?po_number=${encodeURIComponent(
                                  item.poNumber
                                )}&po_date=${item.poCreatedAt}&order_number=${item.orderNumber
                                  }&sales_ref_no=${encodeURIComponent(
                                    item.salesRefNumber
                                  )}&target_etd=${item.targetedEtd}
                                  &status=${item.status}`}
                              >
                                {item.salesRefNumber}
                              </Link>
                            </span>
                          </Col>

                          <Col span={12}>
                            <label>order qty. (in cs)</label>
                          </Col>
                          <Col span={12} align="end">
                            <span className="theme-color bolder">
                              { item.orderedQty }
                            </span>
                          </Col>

                          <Col span={12}>
                            <label>amount (in usd)</label>
                          </Col>
                          <Col span={12} align="end">
                            <span className="theme-color bolder">
                              $&nbsp;
                              {item.amount}
                            </span>
                          </Col>

                          <Col span={12}>
                            <label>invoiced qty. (in cs)</label>
                          </Col>
                          <Col span={12} align="end">
                            <span className="theme-color bolder">
                              { item.invoiceQty }
                            </span>
                          </Col>

                          <Col span={12}>
                            <label>cbm</label>
                          </Col>
                          <Col span={12} align="end">
                            <span className="theme-color bolder">
                              { item.cbm }
                            </span>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                    pagination={{ total: dataSource.total, current: dataSource.current_page, pageSize: dataSource.per_page || 10, 
                      onChange: page => {
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0;

                        this.props.fetchOrderStatus(this.orderStatusArray, {...this.sortState, ...{
                          page: page
                        }, ...this.searchState});
                      } }}
                  />
                </Col>
              </MediaQuery>
            </Row>
          </Layout.Content>

          <Modal
            className="feedback-modal"
            title={<span>We'd like to get your feedback</span>}
            visible={showFeedbackModal}
            onCancel={() => {
              this.setState({ showFeedbackModal: false });
              this.props.history.push("/order-status");
            }}
            footer={null}
            maskClosable={false}
            closable={false}
          >
            <h3>How was your overall order experience?</h3>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="feedback-rate"
            >
              {feedbackEmoji.map((value, i) => (
                <Col
                  key={i}
                  onClick={() => this.handleClickRate(value.rate)}
                  className={value.rate === activeRate ? "active" : ""}
                >
                  <img alt={value.rate} src={value.svg} />
                </Col>
              ))}
            </Row>
            {/* {showQuestion ? ( */}
            <form>
              <Row className="feedback-question">
                <Col span={24}>
                  <h3>Any comments/suggestions?</h3>
                  <div className="form-label">
                    <Field
                      name={`comments`}
                      className="inputStyle"
                      component={renderInput}
                      validate={feedbackValidator}
                    />
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="end" className="feedback-button">
                <Col>
                  <Button
                    htmlType="submit"
                    style={{ width: "192px" }}
                    className="btn-small btn-primary"
                    loading={isPostingFeedback}
                    onClick={handleSubmit((formData) => {
                      this.handleFeedbackSubmit(formData);
                    })}
                  >
                    {isPostingFeedback ? null : "Send Feedback Form"}
                  </Button>
                </Col>
              </Row>
            </form>
            {/* ) : null} */}
          </Modal>

          <Modal
            className="feedback-modal"
            title={<span>Feedback Received!</span>}
            visible={showThankYouModal}
            onCancel={() => this.setState({ showThankYouModal: false })}
            footer={null}
            closable={false}
          >
            <h3>Thank you for your feedback!</h3>
            <p>
              Rest assured that we are continuously enhancing your order
              experience with us.
            </p>
            <Row type="flex" justify="end" className="feedback-button">
              <Col span={24} align="right">
                <Button
                  style={{ margin: 0, minWidth: "80px" }}
                  className="btn-small btn-primary"
                  onClick={() => this.setState({ showThankYouModal: false })}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            className="feedback-modal"
            title={<span>Order Status Updated</span>}
            visible={showStatusUpdateModal}
            onCancel={() => {
              this.setState({ showStatusUpdateModal: false });
              this.props.history.push("/order-status");
            }}
            footer={null}
            maskClosable={false}
            closable={false}
          >
            <h3>
              Your order {urlParams.get("po_number") ? urlParams.get("po_number") : ''} was already INVOICED!
            </h3>
            <p>
              We'd like to get your feedback on your order experience with us.
              Please click the Feedback Form button below.
            </p>

            <Row type="flex" justify="end" className="feedback-button">
              <Col>
                <Button
                  htmlType="submit"
                  className="btn-small btn-primary"
                  onClick={() =>
                    this.setState({
                      showFeedbackModal: true,
                      showStatusUpdateModal: false,
                    })
                  }
                >
                  Feedback Form
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            title="Download Options"
            visible={showDownloadModal}
            width={350}
            onCancel={() => this.setState({ showDownloadModal: false })}
            footer={null}
            className="download-option-modal"
          >
            <Checkbox
              indeterminate={this.state.indeterminate}
              onChange={this.onCheckAllChange}
              checked={this.state.checkAll}
            >
              All
            </Checkbox>
            <br />
            <CheckboxGroup
              options={plainOptions}
              value={checkedList}
              onChange={this.onChangeCheckbox}
            />
            <Row>
              <Col span={24}><Button type="link" onClick={() => this.setState({ checkedList: [], indeterminate: false, checkAll: false })}>Clear All</Button></Col>
            </Row>

            <Row type="flex" style={{ margin: "10px 0px" }}>
              <Col span={12}><Button className="btn-small btn-default" onClick={() => this.setState({ showDownloadModal: false, checkedList: [], indeterminate: false, checkAll: false })}>Cancel</Button></Col>
              <Col span={12}><Button className="btn-small btn-primary" disabled={checkedList.length < 1} onClick={() => this.download(checkedList, downloadDataArray)}>Download</Button></Col>
            </Row>
          </Modal>

          {/* <Modal
            title="Your Approval is needed on these items"
            visible={showApprovalsModal}
            onCancel={() => this.setState({ showApprovalsModal: false })}
            width={"55%"}
            footer={null}
            className="approvals-modal"
          >
            <Collapse
              accordion
              className="approvals-accordion"
              defaultActiveKey={this.state.isApproveType}
            >
              <Panel
                header={`Pending Approvals (${pendingApprovalsCount})`}
                key="1"
                className="panel"
              >
                {pendingApprovals.map((approval) => {
                  return (
                    <div className="approvals">
                      <form>
                        <Row
                          type="flex"
                          style={{ margin: "10px 0px" }}
                          justify="space-between"
                          align="center"
                        >
                          <Col
                            span={24}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "top",
                            }}
                          >
                            <p
                              className="so-number"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              SO NUMBER:{" "}
                              <Link
                                to={`purchase-status?po_number=${approval.distributor_order.po_reference}&po_date=${approval.distributor_order.created_at}&order_number=${approval.distributor_order.transaction_number}&sales_ref_no=${approval.distributor_order.sales_ref_number}&target_etd=${approval.distributor_order.estimated_delivery}`}
                              >
                                {approval.distributor_order.transaction_number}
                              </Link>
                            </p>
                            {this.matchDataFromPendingUrl(
                              approval.distributor_order.transaction_number,
                              approval.distributor_order.po_reference
                            )}
                          </Col>
                          <Col className="" span={12}>
                            <p>{approval.description}</p>
                            <p className="date">
                              {moment(approval.updated_at).calendar()}
                            </p>
                          </Col>
                          <Col
                            className="buttons"
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className="btn-small btn-secondary"
                              onClick={() =>
                                this.handleApproveRequest(approval.id)
                              }
                              loading={isSendingResponse}
                            >
                              {isSendingResponse ? null : "Yes"}
                            </Button>
                            <Button
                              className="btn-small btn-secondary"
                              onClick={() =>
                                this.handleRejectRequest(approval.id)
                              }
                              loading={isSendingResponse}
                            >
                              {isSendingResponse ? null : "No"}
                            </Button>
                          </Col>

                          <Divider />
                        </Row>
                      </form>
                    </div>
                  );
                })}
              </Panel>
              <Panel
                header={`Show Previous Approvals (${previousApprovalsCount})`}
                key="2"
              >
                {previousApprovals.map((approval) => {
                  return (
                    <Row type="flex" style={{ margin: "10px 0px" }}>
                      <Col span={15}>
                        <p>{approval.description}</p>
                        <span className="date">
                          {moment(approval.updated_at).fromNow()}
                        </span>
                      </Col>
                      <Col
                        className="responses"
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                        }}
                      >
                        {approval.response === "yes" ? (
                          <span className="approved">Approved</span>
                        ) : (
                            <span className="not-approved">Not Approved</span>
                          )}
                      </Col>
                      <Divider />
                    </Row>
                  );
                })}
              </Panel>
            </Collapse>
          </Modal> */}

          {/* {this.state.isApprovalsOpen ? (
            pendingApprovalsCount ? (
              <div className="approvals-container">
                <Row type="flex">
                  <Col
                    className="approvals-description"
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-start",
                    }}
                  >
                    <p>
                      <span>
                        Looks like you have some approvals to take care of!
                        <br />
                        {pendingApprovalsCount}
                      </span>{" "}
                      Pending{" "}
                      {pendingApprovalsCount > 1 ? "Approvals" : "Approval"}
                    </p>
                  </Col>
                  <Col
                    className="buttons-column"
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="btn-small btn-secondary"
                      onClick={() =>
                        this.setState({ showApprovalsModal: true })
                      }
                    >
                      Check Approval
                    </Button>
                    <Button
                      className="btn-small btn-default"
                      onClick={this.hideApprovals}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
                ""
              )
          ) : (
              <Popover
                content={content}
                trigger="hover"
                className="approvals-hover"
              >
                <div className="approvals-widget" onClick={this.showApprovals}>
                  <Badge
                    count={
                      pendingApprovalsCount !== undefined && pendingApprovalsCount
                    }
                    style={{ background: "#774625" }}
                  >
                    <Icon
                      type="container"
                      style={{ fontSize: "1.2rem", color: "#774625" }}
                    />
                  </Badge>
                </div>
              </Popover>
            )} */}

          {/* {this.state.isDownloadOpen ? (
              <div className="modal" onClick={this.hideDownloadOptions}>
                <div className="download-modal" onClick={e => e.stopPropagation()}>
                    <Row tpye="flex" style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                      <Col style={{ display: "flex", flex: 1, justifyContent: "flex-start", flexGrow: 4 }}>
                        <h1>Download Options</h1>
                      </Col>
                      <Col style={{ display: "flex", flex: 1, justifyContent: "flex-end", flexGrow: 1 }}>
                        <div className="close" onClick={this.hideDownloadOptions} style={{ margin: "auto", padding: "auto" }}><Icon type="close" style={{ fontSize: "1rem" }} /></div>
                      </Col>
                    </Row>
                    <Divider />
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-evenly", alignContent: "center", alignItems: "center" }}>
                      <Row style={{ marginBottom: "10px" }} onClick={this.hideDownloadOptions}>
                        <BulkDownloadExcel
                          className="btn-small btn-primary"
                          title="EXCEL"
                          data={dataSource}
                          status={dataSource.map(data => data.status)}
                          rowIds={selectedRowKeys}
                          // disabled={
                          //   selectedRowKeys && !myPermissions.some(
                          //     (r) => r.name === "distributor.orders.download"
                          //   )
                          // }
                          style={{ width: "100%" }}
                        />
                      </Row>
                      <Row style={{ marginBottom: "10px" }} onClick={this.hideDownloadOptions}>
                        <BulkDownloadCSV
                          className="btn-small btn-primary"
                          title="CSV"
                          data={dataSource}
                          status={dataSource.map(data => data.status)}
                          rowIds={selectedRowKeys}
                          // disabled={
                          //   selectedRowKeys && !myPermissions.some(
                          //     (r) => r.name === "distributor.orders.download"
                          //   )
                          // }
                          style={{ width: "100%" }}
                        />
                      </Row>
                      <Row style={{ marginBottom: "20px" }} onClick={this.hideDownloadOptions}>
                        <BulkDownloadAll
                          className="btn-small btn-secondary-gold"
                          title="ALL"
                          data={dataSource}
                          status={dataSource.map(data => data.status)}
                          rowIds={selectedRowKeys}
                          // disabled={
                          //   selectedRowKeys && !myPermissions.some(
                          //     (r) => r.name === "distributor.orders.download"
                          //   )
                          // }
                          style={{ width: "100%" }}
                        />
                      </Row>
                    </div>
                </div>
              </div>
           ) : (
            <div style={{ display: "none" }}>
                <div>
                    <Row tpye="flex">
                      <Col style={{ display: "flex", flex: 1, justifyContent: "flex-start", flexGrow: 3 }}>
                        <h1 style={{ padding: "auto", margin: "auto" }}>Download Options</h1>
                      </Col>
                      <Col style={{ display: "flex", flex: 1, justifyContent: "flex-end", flexGrow: 1 }}>
                        <div className="close" onClick={this.hideDownloadOptions}><Icon type="close" style={{ fontSize: "1rem" }} /></div>
                      </Col>
                    </Row>
                      <Divider />
                    <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-evenly", alignContent: "center", alignItems: "center" }}>
                      <Row style={{ marginBottom: "10px" }}>
                        <BulkDownloadExcel
                          className="btn-small btn-primary"
                          title="EXCEL"
                          data={dataSource}
                          status={dataSource.map(data => data.status)}
                          rowIds={selectedRowKeys}
                          // disabled={
                          //   selectedRowKeys && !myPermissions.some(
                          //     (r) => r.name === "distributor.orders.download"
                          //   )
                          // }
                          style={{ width: "100%" }}
                        />
                      </Row>
                      <Row style={{ marginBottom: "10px" }}>
                        <BulkDownloadCSV
                          className="btn-small btn-primary"
                          title="CSV"
                          data={dataSource}
                          status={dataSource.map(data => data.status)}
                          rowIds={selectedRowKeys}
                          // disabled={
                          //   selectedRowKeys && !myPermissions.some(
                          //     (r) => r.name === "distributor.orders.download"
                          //   )
                          // }
                          style={{ width: "100%" }}
                        />
                      </Row>
                      <Row style={{ marginBottom: "10px" }}>
                        <BulkDownloadAll
                          className="btn-small btn-primary"
                          title="ALL"
                          data={dataSource}
                          status={dataSource.map(data => data.status)}
                          rowIds={selectedRowKeys}
                          // disabled={
                          //   selectedRowKeys && !myPermissions.some(
                          //     (r) => r.name === "distributor.orders.download"
                          //   )
                          // }
                          style={{ width: "100%" }}
                        />
                      </Row>
                    </div>
                </div>
              </div>
           )
           } */}

        </Layout>
      </DocumentMeta >
    );
  }
}

const mapStateToProps = (state) => {
  const {
    permissions,
    recentOrders,
    distributor,
    orderStatus,
    sapData,
    notifications,
    poStatus,
    distributorOrderSummary
  } = state;

  return {
    myPermissions: permissions.data,
    myPermissionsFetching: permissions.fetching,
    recentOrders: recentOrders,
    distributor,
    orderStatus,
    distributorOrderSummary,
    sapData,
    poStatus,
    downloadFile,
    notificationsData: notifications,
    fetching: notifications.fetching,
  };
};

export default connect(mapStateToProps, {
  fetchPermissions,
  fetchDistributor,
  fetchRecentOrders,
  fetchOrderStatus,
  fetchDataFromSap,
  fetchPoStatus,
  fetchNotifications,
  postPoNumber,
  postFeedback,
  approveRequest,
  rejectRequest,
  fetchDistributorOrderSummary
})(
  reduxForm({ form: "productOrderForm", enableReinitialize: true })(RecentOrder)
);
