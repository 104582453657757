import { POST_CONTACT, POST_CONTACT_FULFILLED, POST_CONTACT_REJECTED } from '../actions';

const initialData = {
  contactLoading: false,
  contactLoaded: false,
  contactFailed: false
}
export default function contact (state = initialData, action) {
  switch (action.type) {
    case POST_CONTACT:
      return {
        ...state,
        contactLoading: true,
        contactLoaded: false,
        contactFailed: false
      }
    case POST_CONTACT_FULFILLED:
      return {
        ...state,
        contactLoading: false,
        contactLoaded: true,
        contactFailed: false
      }
    case POST_CONTACT_REJECTED:
      return {
        ...state,
        contactLoading: false,
        contactLoaded: false,
        contactFailed: true
      }
    default:
      return {
        ...state
      }
  }
}
