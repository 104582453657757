import {
  FETCH_PO_REF_NO,
  FETCH_PO_REF_NO_FULFILLED,
  FETCH_PO_REF_NO_REJECTED
} from "../actions";

export default (
  state = {
    data: null,
    fetching: false,
    fetched: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case FETCH_PO_REF_NO:
      return { ...state, fetching: true, fetched: false, error: null };
    case FETCH_PO_REF_NO_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: action.payload
      };
    case FETCH_PO_REF_NO_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
        data: null
      };

    default:
      return state;
  }
};
