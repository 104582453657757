import moment from 'moment'

const validator = {

    //to validate the file type of the file
    validateFileType(fileList) {
        const validateFileType = []
        fileList.map((file) => {
            if (file.name.split('.').pop() !== "xlsx") validateFileType.push({ text: `The File ${file.name} must be in XLSX Format` })
        })
        return validateFileType
    },

    //to validate the file name of the file
    validateFileName(fileList) {
        const validateFileName = []
        const poHeader = fileList.find((file) => file.name === 'PO_HEADER.xlsx')
        !poHeader && validateFileName.push({ text: `The PO_HEADER could not be found in the uploaded list.` })

        const poOrder = fileList.find((file) => file.name === 'PO_ORDER.xlsx')
        !poOrder && validateFileName.push({ text: `The PO_ORDER could not be found in the uploaded list.` })

        return validateFileName
    },

    //to validate the container of the file
    validateCSV(csvData) {
        const headerValidationArray = []
        const orderValidationArray = []
        if (csvData.length === 0) headerValidationArray.push({ text: `The XLSX's Data are Empty` })
        csvData.map((fromCSVData, i) => {
            const { po_header } = fromCSVData
            const index = i + 3 //not include the header
            if (!po_header.customer_po_number) headerValidationArray.push({ text: `Customer PO Number required on your header file line ${index}.` })
            if (!po_header.special_instructions) headerValidationArray.push({ text: `Special Instructions required on your header file line ${index}.` })
            if (!po_header.port_destination) headerValidationArray.push({ text: `Port of Destination required on your header file line ${index}.` })
            if (!po_header.etd_date) headerValidationArray.push({ text: `ETD Date required on your header file line ${index}.` })
            if (po_header.etd_date_check !== moment(po_header.etd_date_check).format('YYYY-MM')) headerValidationArray.push({ text: `ETD must be format in 'YYYY-MM' on your header file line ${index}.` })
            if (fromCSVData.po_items.length === 0) headerValidationArray.push({ text: `The XSLX File does not have an order item on your order file line ${index}.` })
            fromCSVData.po_items.map((fromOrderData) => {
                if (!fromOrderData.customer_po_number) orderValidationArray.push({ text: `Customer PO Number required on your order file line ${fromOrderData.row}.` })
                if (!fromOrderData.material_code) orderValidationArray.push({ text: `Material Code required on your order file line ${fromOrderData.row}.` })
                if (!fromOrderData.quantity) orderValidationArray.push({ text: `Quantity required on your order file line ${fromOrderData.row}.` })
                else if (parseInt(fromOrderData.quantity) < 50) orderValidationArray.push({ text: `Quantity should greater than or equal to 50 on your order file line ${fromOrderData.row}.` })
            })
        })
        return headerValidationArray.concat(orderValidationArray)
    },

    //to validate and display the response error of the API
    responseErrorMessage(error) {
        const responseArray = []
        error.existing_pos.length !== 0 && error.existing_pos.map((items) => {
            responseArray.push({ text: `'${items}' has failed to upload because its already exist.` })
        })
        error.failed_pos.length !== 0 && error.failed_pos.map((items) => {
            responseArray.push({ text: `In '${items.customer_po_number}', ${items.errors && items.errors[0].error}.` })
        })
        error.failed_po_items.length !== 0 && error.failed_po_items.map((items) => {
            responseArray.push({ text: `Material Code '${items.material_code}' does not exist in line ${items.row} .` })
        })
        // this.forceUpdate();
        return responseArray
    }
}

export default validator;