import axios from 'axios';

import config from '../config';

export const FETCH_PRODUCT_CATEGORIES = 'FETCH_PRODUCT_CATEGORIES';
export const FETCH_PRODUCT_CATEGORIES_FULFILLED = 'FETCH_PRODUCT_CATEGORIES_FULFILLED';
export const FETCH_PRODUCT_CATEGORIES_REJECTED = 'FETCH_PRODUCT_CATEGORIES_REJECTED';

export const fetchProductCategoies = () => dispatch => {
  dispatch({
    type: FETCH_PRODUCT_CATEGORIES,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/products/categories`, { params: {}})
  .then(response => {
    dispatch({
      type: FETCH_PRODUCT_CATEGORIES_FULFILLED,
      payload: response.data
    });
  })
  .catch(err => {
    dispatch({
      type: FETCH_PRODUCT_CATEGORIES_REJECTED,
      payload: err
    })
  });
}