import axios from "axios";
import config from "../config";
import { headers } from "../utilities";

export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const FETCH_NOTIFICATION_FULFILLED = "FETCH_NOTIFICATION_FULFILLED";
export const FETCH_NOTIFICATION_REJECTED = "FETCH_NOTIFICATION_REJECTED";

export const FETCH_NOTIFICATION_COUNT = "FETCH_NOTIFICATION_COUNT";
export const FETCH_NOTIFICATION_COUNT_FULFILLED = "FETCH_NOTIFICATION_COUNT_FULFILLED";
export const FETCH_NOTIFICATION_COUNT_REJECTED = "FETCH_NOTIFICATION_COUNT_REJECTED";

export const APPROVE_NOTIFICATION = "APPROVE_NOTIFICATION";
export const APPROVE_NOTIFICATION_FULFILLED = "APPROVE_NOTIFICATION_FULFILLED";
export const APPROVE_NOTIFICATION_REJECTED = "APPROVE_NOTIFICATION_REJECTED";

export const REJECT_NOTIFICATION = "REJECT_NOTIFICATION";
export const REJECT_NOTIFICATION_FULFILLED = "REJECT_NOTIFICATION_FULFILLED";
export const REJECT_NOTIFICATION_REJECTED = "REJECT_NOTIFICATION_REJECTED";

export const fetchNotifications = () => (dispatch) => {
  dispatch({
    type: FETCH_NOTIFICATION,
    payload: {},
  });
  return axios
    .get(`${config.REACT_APP_API_BASE_URL}/notifications-distributor`, {
      headers: headers(),
    })
    .then((response) => {
      dispatch({
        type: FETCH_NOTIFICATION_FULFILLED,
        payload: response.data,
      });
      return { error: false, response };
    })
    .catch((err) => {
      dispatch({
        type: FETCH_NOTIFICATION_REJECTED,
        payload: err,
      });
      return { error: true, err };
    });
};

export const fetchNotificationCount = () => (dispatch) => {
  dispatch({
    type: FETCH_NOTIFICATION_COUNT,
    payload: {},
  });
  return axios
    .get(`${config.REACT_APP_API_BASE_URL}/notifications-count-unseen`, {
      headers: headers(),
    })
    .then((response) => {
      dispatch({
        type: FETCH_NOTIFICATION_COUNT_FULFILLED,
        payload: response.data,
      });
      return { error: false, response };
    })
    .catch((err) => {
      dispatch({
        type: FETCH_NOTIFICATION_COUNT_REJECTED,
        payload: err,
      });
      return { error: true, err };
    });
};

export const approveRequest = (id) => (dispatch) => {
  const data = {
    status: 1,
    response: "yes"
  };

  dispatch({
    type: APPROVE_NOTIFICATION,
    payload: {},
  });
  return axios
    .post(`${config.REACT_APP_API_BASE_URL}/notification/${id}`, data, {
      headers: headers(),
    })
    .then((response) => {
      dispatch({
        type: APPROVE_NOTIFICATION_FULFILLED,
        payload: response.data,
      });
      return { error: false, response };
    })
    .catch((err) => {
      dispatch({
        type: APPROVE_NOTIFICATION_REJECTED,
        payload: err,
      });
      return { error: true, err };
    });
};

export const rejectRequest = (id) => (dispatch) => {
  const data = {
    status: 1,
    response: "no"
  };

  dispatch({
    type: REJECT_NOTIFICATION,
    payload: {},
  });
  return axios
    .post(`${config.REACT_APP_API_BASE_URL}/notification/${id}`, data, {
      headers: headers(),
    })
    .then((response) => {
      dispatch({
        type: REJECT_NOTIFICATION_FULFILLED,
        payload: response.data,
      });
      return { error: false, response };
    })
    .catch((err) => {
      dispatch({
        type: REJECT_NOTIFICATION_REJECTED,
        payload: err,
      });
      return { error: true, err };
    });
};
