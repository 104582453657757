import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Layout, Card, Modal } from 'antd'
import { forgotPassword } from '../../actions'
import error from "../../assets/icons/error-icon.png";
import success from "../../assets/icons/check-icon.png";

// Import CSS
import './ForgotPassword.scss'

class ForgotPassword extends Component {
  state = {
    loading: false
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true })
        this.props.forgotPassword(values).then(response => {
          if (response.error) {
            Modal.error({
              title: 'Unable to process the request. Please try again.',
              content: response.message === 2003 ? "The email do not exist." : null,
              icon: <img src={error} alt="error" />
            })
          } else {
            Modal.success({
              title: 'Success! We have sent you an e-mail. You will be redirected to home page. Thank you.',
              content: '',
              icon: <img src={success} alt="success" />,
              onOk: () => this.props.history.push('/')
            })
            this.props.form.resetFields()
          }
          this.setState({ loading: false })
        })
      }
    });
  };

  render() {
    const { loading } = this.state
    const { form: { getFieldDecorator } } = this.props
    return (
      <Layout>
        <Layout.Content>
          <div className='forgot-password-container'>
            <Card className='card-width'>
              <h1>Forgot Password</h1>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item label='Please input your email for account recovery'>
                  {getFieldDecorator('email', {
                    rules: [{ type: 'email', message: 'Please input a valid email.' }, { required: true, whitespace: true, message: 'Please input your email.' }],
                  })(
                    <Input
                      placeholder="Email"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" loading={loading} className='btn-small btn-primary' style={{ width: "100%" }} htmlType="submit" >
                    {loading ? null : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Layout.Content>
      </Layout >
    );
  }
}


const ForgotPasswordForm = Form.create({ name: 'forgotPassword_form' })(ForgotPassword)
function mapStateToProps(state) {
  return {
  }
}
function mapDispatchToProps(dispatch) {
  return {
    forgotPassword: (data) => dispatch(forgotPassword(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm)
