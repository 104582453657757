import axios from 'axios';
import config from '../config';
import { headers } from '../utilities'

export const FETCH_DISTRIBUTOR = 'FETCH_DISTRIBUTOR';
export const FETCH_DISTRIBUTOR_FULFILLED = 'FETCH_DISTRIBUTOR_FULFILLED';
export const FETCH_DISTRIBUTOR_REJECTED = 'FETCH_DISTRIBUTOR_REJECTED';

export const UPDATE_DISTRIBUTOR = 'UPDATE_DISTRIBUTOR';
export const UPDATE_DISTRIBUTOR_FULFILLED = 'UPDATE_DISTRIBUTOR_FULFILLED';
export const UPDATE_DISTRIBUTOR_REJECTED = 'UPDATE_DISTRIBUTOR_REJECTED';

export const fetchDistributor = (data) => dispatch => {
  dispatch({
    type: FETCH_DISTRIBUTOR,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/my-info`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_DISTRIBUTOR_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: FETCH_DISTRIBUTOR_REJECTED,
        payload: err
      })
      return { error: true, err };
    });
}

export const updateDistributor = (data) => dispatch => {
  dispatch({
    type: UPDATE_DISTRIBUTOR,
    payload: {}
  });
  return axios.post(`${config.REACT_APP_API_BASE_URL}/profile`, data, { headers: headers() })
    .then(response => {
      dispatch({
        type: UPDATE_DISTRIBUTOR_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: UPDATE_DISTRIBUTOR_REJECTED,
        payload: err
      })
      return { error: true, err };
    });
}