import React, { Component } from 'react'
import { submitContact } from '../../actions/contact'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Modal, Input, Button, Layout, Row, Col, Select, Card } from 'antd'
import DocumentMeta from "react-document-meta";
import { getCode, getNames } from 'country-list'
import ReCAPTCHA from "react-google-recaptcha";

// Import CSS
import './Contact.scss'

const { Option } = Select;
// const RECAPTCHA_KEY = '6Lc8bcAZAAAAAKFweB3_L03q3pzP1mcjueXUbfW_' // PROD
const RECAPTCHA_KEY = '6LeYB8MZAAAAAEsLONXJls0zFfyl_HeUKRsSOccD'
class ContactUs extends Component {
  constructor() {
    super()
    this.state = {
      isModalVisible: false,
      reCaptchaKey: ''
    }
  }
  handleSubmit = (e) => {
    const { onSubmitContact, form } = this.props
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        const { country } = values
        onSubmitContact({ ...values, country: getCode(country), reCaptchaKey: this.state.reCaptchaKey }).then((res) => {
          if (!res.error) {
            this.setState({ isModalVisible: true })
          } else {
            Modal.error({
              title: "Unable to submit Inquiry. Please try again.",
              content: "",
            });
          }
        })
      }
    })
  };

  getModalInfoDisplay(key) {
    const { contactLoaded, contactFailed } = this.props
    let strResult = {
      title: '',
      message: ''
    }
    if (contactLoaded) {
      strResult = {
        title: 'Submit Success',
        message: 'You have sent an Email.'
      }
    } else if (contactFailed) {
      strResult = {
        title: 'Submit Failed',
        message: 'The email not been sent.'
      }
    }
    return strResult[key]
  }

  modalVisible() {
    this.setState({ isModalVisible: !this.state.isModalVisible })
  }

  componentWillReceiveProps(nextProps) {
    const { contactLoaded, contactFailed, form } = this.props
    if ((contactLoaded !== nextProps.contactLoaded) || (contactFailed !== nextProps.contactFailed)) {
      // this.setState({ isModalVisible: true })
      form.resetFields();
    }
  }

  render() {
    const { contactLoading, form: { getFieldDecorator } } = this.props
    const meta = {
      title: "Contact Us - URC Global Exports",
      description: "For inquiries about our products & services, you may contact us at customercare@urc.com.ph or 02-599-8772. You may also drop a message by filling out our contact form.",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Contact Us"
        }
      }
    };

    return (
      <DocumentMeta {...meta}>
        <Layout className='contact-us-container'>
          <Layout className='contact-us-banner'>
            <Layout.Content className='contact-us-header-label'>
              <h1> We'd like to hear from you!</h1>
              <p> Leave us a message!</p>
            </Layout.Content>
          </Layout>
          <Layout className='contact-us-cards'>
            <Layout.Content className='contact-us-content' >
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" sm={24} xs={24} md={16} lg={16} style={{ marginBottom: 20 }}>
                  <Card style={{ width: '100%' }}>
                    <Row>
                      <Form onSubmit={this.handleSubmit}>
                        <Row>
                          <Col sm={24} xs={24} md={24} lg={24}>
                            <h1 className='contact-form-title'>Contact Us Form</h1>
                          </Col>
                          <Col sm={24} xs={24} md={24} lg={24}>
                            <Form.Item label='FULL NAME' colon={false}>
                              <div style={{ margin: '5px' }}>
                                {getFieldDecorator('full_name', {
                                  rules: [{ required: true, whitespace: true, message: 'Please input your Name!' }]
                                })(
                                  <Input
                                    className='input-line'
                                    placeholder='Type Your Name'
                                  />
                                )}
                              </div>
                            </Form.Item>
                          </Col>
                          <Col sm={24} xs={24} md={24} lg={24}>
                            <Form.Item label='EMAIL ADDRESS' colon={false}>
                              <div style={{ margin: '5px' }}>
                                {getFieldDecorator('email_address', {
                                  rules: [{ type: 'email', message: 'The input is not valid E-mail' }, { required: true, whitespace: true, message: 'Please input your Email!' }]
                                })(
                                  <Input
                                    type='email'
                                    className='input-line'
                                    placeholder='Type Your Email Address'
                                  />
                                )}
                              </div>
                            </Form.Item>
                          </Col>
                          <Col sm={24} xs={24} md={24} lg={24}>
                            <Form.Item label='CONTACT NUMBER' colon={false}>
                              <div style={{ margin: '5px' }}>
                                {getFieldDecorator('contact_number', {
                                  rules: [{ required: true, whitespace: true, message: 'Please input your Mobile Number!' }]
                                })(
                                  <Input
                                    type='number'
                                    className='input-line'
                                    placeholder='Type Your Contact Information'
                                  />
                                )}
                              </div>
                            </Form.Item>
                          </Col>
                          <Col sm={24} xs={24} md={24} lg={24}>
                            <Form.Item label='COMPANY NAME' colon={false}>
                              <div style={{ margin: '5px' }}>
                                {getFieldDecorator('company_name', {
                                  rules: [{ required: true, whitespace: true, message: 'Please input your Company Name!' }]
                                })(
                                  <Input
                                    className='input-line'
                                    placeholder='Type Your Company Name'
                                  />
                                )}
                              </div>
                            </Form.Item>
                          </Col>
                          <Col sm={24} xs={24} md={24} lg={24}>
                            <Form.Item label='COUNTRY' colon={false} className="select-holder">
                              <div style={{ margin: '5px 0' }}>
                                {getFieldDecorator(`country`, {
                                  rules: [{ required: true, message: 'Please Select your Country!' }]
                                })(
                                  <Select placeholder={`Select Country`} showSearch>
                                    {getNames().map((country, i) => {
                                      return (
                                        <Option key={i} value={country} >{country}</Option>
                                      )
                                    })}
                                  </Select>
                                )}
                              </div>
                            </Form.Item>
                          </Col>

                          <Col sm={24} xs={24} md={24} lg={24}>
                            <Form.Item label='TYPE OF INQUIRY' colon={false}>
                              <div style={{ margin: '5px' }}>
                                {getFieldDecorator('type', {
                                  rules: [{ required: true, message: 'Please choose the type of Inquiry!' }]
                                })(
                                  <Select placeholder={`Select Type of Inquiry`} showSearch>
                                    <Option value='Product / Distribution Inquiry' >Product / Distribution Inquiry</Option>
                                    <Option value='Offered Services' >Offered Services</Option>
                                    <Option value='Website OMS Feedback' >Website OMS Feedback</Option>
                                    <Option value='Suggestions' >Suggestions</Option>
                                    <Option value='Others' >Others</Option>
                                  </Select>
                                )}
                              </div>
                            </Form.Item>
                          </Col>
                          <Col sm={24} xs={24} md={24} lg={24}>

                            <Form.Item label='MESSAGE' colon={false}>
                              <div  >
                                {getFieldDecorator('message', {
                                  rules: [{ required: true, message: 'Please input your Message!' }]
                                })(
                                  <Input
                                    className='input-line'
                                    placeholder='Type your message here'
                                  />
                                )}
                              </div>
                            </Form.Item>
                          </Col>
                          <Col sm={24} xs={24} md={24} lg={24}>
                            <div style={{ margin: '10px 0' }}>
                              <ReCAPTCHA
                                sitekey={RECAPTCHA_KEY}
                                onChange={(e) => this.setState({ reCaptchaKey: e })}
                                onExpired={() => this.setState({ reCaptchaKey: '' })}
                              />
                            </div>
                            <Form.Item>
                              <div className='send-form-button'>
                                <Button type='primary' htmlType='submit' loading={contactLoading} disabled={this.state.reCaptchaKey === ''} className='btn-main btn-primary'>
                                  {contactLoading ? null : "Submit"}
                                </Button>
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Row>
                  </Card>
                </Col>
                <Col className="gutter-row" sm={24} xs={24} md={8} lg={8}>
                  <Card style={{ width: '100%' }} className='contact-information-container'>
                    <Row>
                      <Col sm={24} xs={24} md={24} lg={24} className='information-title'>
                        <h1 className='contact-form-title'>Contact Information</h1>
                      </Col>

                      <Col sm={24} xs={24} md={24} lg={24} className='information-address'>
                        <h1 className=''>URC GLOBAL EXPORTS DIVISION</h1>
                        <p>168 Tagore Lane Singapore 787574</p>
                      </Col>

                      <Col sm={24} xs={24} md={24} lg={24} className='information-email'>
                        <h1 className=''>EMAIL</h1>
                        <p>inquire.globalexports@urc.com.sg</p>
                      </Col>
                    </Row>
                  </Card><br />
                  <Card style={{ width: '100%' }} className='contact-question'>
                    <Row>
                      <Col sm={24} xs={24} md={24} lg={24} className='question-content'>
                        <h1 className=''>Got More Questions?</h1>
                        <p>Visit our FAQ Page to know more about our website.</p>
                      </Col>
                      <Col sm={24} xs={24} md={24} lg={24} className='faq-button'>
                        <Link to='/faq'>View FAQ Page</Link>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Layout.Content>
          </Layout>
          <Modal
            className='contact-form-modal'
            title='_'
            centered
            visible={this.state.isModalVisible}
            onCancel={() => this.modalVisible()}
            zIndex={99999}
            width={700}
            footer={null}
            id="inquiry_sent"
          >
            <div id="inquiry_sent">
              <h2>Your Inquiry Has Been Sent!</h2>
              <p>Thank you for sending your inquiry to us! Please give us an ample amount of time to be able to respond to your concerns in the best way possible.</p>
              <Link to={'/'}>
                <center><Button className='btn-small btn-primary'>
                  Back To Home Page >
            </Button></center>
              </Link>
            </div>
          </Modal>

        </Layout>

      </DocumentMeta>
    )
  }
}



const inputStyle = {
  color: '#404040',
  fontSize: '16px',
  border: '0',
  outline: '0',
  background: 'transparent',
  borderBottom: '1px solid #bf914a',
  width: ' 100%',
  height: '40px',
  boxShadow: '0 2px 2px -2px #F0E6D8',
  padding: 0,
  opacity: 0.5,
  border: '2px solid #DFC8A5'
}

const selectStyle = {
  border: 'none',
  outline: '0',
  background: 'transparent',
  borderBottom: '1px solid #bf914a',
  borderTop: '0',
  boxShadow: '0 2px 2px -2px #EBDCC6',
  padding: 0,
  width: '100%',
}



const ContactForm = Form.create({ name: 'contact_form' })(ContactUs)

function mapStateToProps(state) {
  return {
    contactLoading: state.contact.contactLoading,
    contactLoaded: state.contact.contactLoaded,
    contactFailed: state.contact.contactFailed
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitContact: (data) => dispatch(submitContact(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
