import axios from 'axios';
import moment from "moment"
import config from '../config';
import { headers } from '../utilities'

export const FETCH_ORDER_SUMMARY = 'FETCH_ORDER_SUMMARY';
export const FETCH_ORDER_SUMMARY_FULFILLED = 'FETCH_ORDER_SUMMARY_FULFILLED';
export const FETCH_ORDER_SUMMARY_REJECTED = 'FETCH_ORDER_SUMMARY_REJECTED';


export const fetchOrderSummary = () => dispatch => {
    dispatch({
        type: FETCH_ORDER_SUMMARY,
        payload: {}
    });
    return axios.get(`${config.REACT_APP_API_BASE_URL}/container-summary-breakdown`, { headers: headers() })
        .then(response => {
            dispatch({
                type: FETCH_ORDER_SUMMARY_FULFILLED,
                payload: response.data
            });
        })
        .catch(err => {
            dispatch({
                type: FETCH_ORDER_SUMMARY_REJECTED,
                payload: err
            })
        });
}

// export const fetchOrderSummary = () => dispatch => {
//     dispatch({
//         type: FETCH_ORDER_SUMMARY,
//         payload: {}
//     });
//     container-summary-breakdown
//     return axios.get(`${config.REACT_APP_API_BASE_URL}/distributor-purchase-orders-summary`, { headers: headers() })
//         .then(response => {
//             const newArray = response.data.distributorOrder.map(value => {
//                 const orderStatus = response.data.orderStatus.filter(item => item.customer_po_number === value.po_reference)
//                 let products = value.product.map(item => {
//                     let matched = orderStatus.find(x => Number(x.material) === Number(item.variant.material_code))
//                     return {
//                         name: item.variant.name,
//                         packing_scheme: item.variant.packing_scheme,
//                         material_code: item.variant.material_code,
//                         cbm: item.variant.cbm,
//                         amount: parseFloat((item.quantity || 0) * (item.selling_price || 0)),
//                         quantity: (item.quantity || 0),
//                         status: matched ? getStatus(matched) : "for_production"
//                     }
//                 })
//                 let totalSapCbm = orderStatus.reduce((a, c) => {
//                     return a + parseFloat(c["volume"]);
//                 }, 0);

//                 return {
//                     etd: moment(value.estimated_delivery).startOf('month').format("MM-DD-YYYY"),
//                     po_number: value.po_reference,
//                     customer_po_number: value.sales_ref_number || 'N/A',
//                     products,
//                     po_date: moment(value.created_at).format("MM-DD-YYYY"),
//                     total_quantity: parseFloat(getTotal(products, "quantity")),
//                     total_amount: parseFloat(getTotal(products, "amount")),
//                     total_cbm: totalSapCbm,
//                     status: products.every(item => item.status === "invoiced") ? "invoiced" : "in_process"
//                 }
//             })
//             dispatch({
//                 type: FETCH_ORDER_SUMMARY_FULFILLED,
//                 payload: newArray.filter(value => value.products.length > 0)
//             });
//         })
//         .catch(err => {
//             dispatch({
//                 type: FETCH_ORDER_SUMMARY_REJECTED,
//                 payload: err
//             })
//         });
// }


const getStatus = data => {
    data = data || "";
    let status;
    if (data["billing_document"]) status = "invoiced";
    else if (data["delivery"]) status = "ready_to_load";
    else if (data["sales_document"]) status = "produced";
    else if (!data["sales_document"]) status = "for_production";
    else status = "";
    return status
};


const getTotal = (data, column) => data.reduce((a, c) => a + parseFloat(c[column] || 0), 0)
