import {
  ABOUT_DATA,
  ABOUT_DATA_FULFILLED,
  ABOUT_DATA_REJECTED
} from '../actions';

const initialData = {
  aboutLoading: false,
  aboutLoaded: false,
  aboutRejected: false,
  aboutData: [{
    "id": "",
    "title": "",
    "sub_title": "",
    "description": "{}",
  },]
}
export default function login(state = initialData, action) {
  switch (action.type) {
    case ABOUT_DATA:
      return {
        ...state,
        aboutLoading: true,
        aboutLoaded: false,
        aboutRejected: false,
      }
    case ABOUT_DATA_FULFILLED:
      return {
        ...state,
        aboutLoading: false,
        aboutLoaded: true,
        aboutRejected: false,
        aboutData: action.payload
      }
    case ABOUT_DATA_REJECTED:
      return {
        ...state,
        aboutLoading: false,
        aboutLoaded: false,
        aboutRejected: true,
      }
    default:
      return {
        ...state
      }
  }
}
