import * as _ from "lodash";
import {
  FETCH_ORDER_SUMMARY,
  FETCH_ORDER_SUMMARY_FULFILLED,
  FETCH_ORDER_SUMMARY_REJECTED,
} from "../actions";

export default (
  state = {
    data: [],
    size: 0,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case FETCH_ORDER_SUMMARY:
      return { ...state, fetching: true, fetched: false, error: null };
    case FETCH_ORDER_SUMMARY_FULFILLED:
      const data = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data,
        size: _.size(data),
      };
    case FETCH_ORDER_SUMMARY_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
        data: [],
      };
    default:
      return state;
  }
};
