import React, { Component } from 'react'
import Slider from 'react-slick'
import { Row, Col } from 'antd'
// Images Import
import PiattosCheese from '../../assets/images/piattos-cheese.png'
import GreatTasteWhite from '../../assets/images/great-taste-white.png'
import Xtrabig from '../../assets/images/xtra-big.png'
import MagicFlakes from '../../assets/images/magic-flakes.png'
import C2List from '../../assets/images/c2-list.png'
import XOList from '../../assets/images/xo-list.png'
import { connect } from 'react-redux'
import { redirectFilter } from '../../actions/home'
import { fetchFilterOptions } from '../../actions/filterOptions'

import { Link } from 'react-router-dom'

function NextBtnStyle(props) {
  const { onClick } = props
  return (
    <div className='react-slick-custom-btn-right' onClick={onClick}>
      <i className='right' />
    </div>
  )
}

function PrevBtnStyle(props) {
  const { onClick } = props
  return (
    <div className='react-slick-custom-btn-left' onClick={onClick}>
      <i className='left' />
    </div>
  )
}

class SellProduct extends Component {
  constructor() {
    super()
    this.state = { isDragging: false };
  }


  midDrag = false;

  toggleMidDrag = () => {
    this.midDrag = !this.midDrag;
  }

  componentDidMount() {
    this.props.fetchFilterOptions()
  }

  render() {
    const sellData = [
      {
        title: 'CHIPS & CRISPS',
        description: 'We are the market leader in Chips & Crisps in the Philippines with our continuous drive for product innovations & development through assessment of dynamic market trends.',
        imageLink: PiattosCheese,
        linkLabel: 'View All Chips & Crisps',
        linkFilter: '?brand=all&category=Chips and Crisps&country=all'
      },
      {
        title: 'BAKERY',
        description: 'We offer affordable biscuits and cakes that cater to consumers of all ages.',
        imageLink: MagicFlakes,
        linkLabel: 'View All Bakery',
        linkFilter: '?brand=all&category=Bakery&country=all'
      },
      {
        title: 'CONFECTIONERY',
        description: ' Our chocolates and candies are market leaders in the Philippines which also comes in a variety of fun and exciting flavors.',
        imageLink: XOList,
        linkLabel: 'View All Confectionery',
        linkFilter: '?brand=all&category=Confectionery&country=all'
      },
      {
        title: 'READY-TO-DRINK BEVERAGE',
        description: 'Our healthy ready-to-drink tea that comes from naturally brewed tea leaves.',
        imageLink: C2List,
        linkLabel: 'View All RTD Beverage',
        linkFilter: '?brand=all&category=Ready To Drink Beverage&country=all'
      },
      {
        title: 'POWDERED BEVERAGE',
        description: ' We innovated the modern coffee experience to become accessible to many, being the first and leader in the “creamy” subsegment.',
        imageLink: GreatTasteWhite,
        linkLabel: 'View All Powdered Beverage',
        linkFilter: '?brand=all&category=Powdered Beverage&country=all'
      },
      {
        title: 'NOODLES',
        description: ' We offer value-for-money instant noodles that is extra big in flavour and quantity.',
        imageLink: Xtrabig,
        linkLabel: 'View All Noodles',
        linkFilter: '?brand=all&category=Noodles&country=all'
      }
    ]

    let dragging = false;
    const settingsList = {
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false,
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      speed: 500,
      cssEase: 'linear',
      nextArrow: <NextBtnStyle />,
      prevArrow: <PrevBtnStyle />,
      responsive: [
        {
          breakpoint: 1370,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: '10px',
            infinite: true
            // dots: true
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 820,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    }
    return (
      <Row>
        <Col>
          <div className='sell-product-container'>
            <div className='sell-header-title'>
              <center>
                <h1>What We Sell</h1>
                <Col className='view-catalog'>
                  <span><Link to='/products'>OUR PRODUCTS</Link></span>
                </Col>
              </center>
            </div>
            <div className='sell-item-product-slider'>
              <Slider {...settingsList}>
                {
                  sellData.map((data, i) => {
                    return (
                      <div className='sell-item-container animated fadeIn' key={i}>
                        <Link to={`/products${data.linkFilter}`} draggable="false"
                          onClick={(e) => dragging ? e.preventDefault() : this.props.redirectFilter(`${data.linkFilter}`)}
                        >
                          <div className='sell-item-card'>
                            <div className='image-container'>
                              <img src={data.imageLink} alt='product-item' />
                            </div>
                            <div className='sell-item-description'>
                              <span className='sell-item-title'>{data.title}</span>
                              <p className='sell-item-info'> {`${data.description}`} </p>
                              <p className='sell-item-beverages'>{`${data.linkLabel} >`} </p>
                              <p className='sell-item-beverages'>{`${data.linkLabel} >`} </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          </div>
        </Col>
      </Row >
    )
  }
}

function mapStateToProps({ home }) {
  return home
}

export default connect(mapStateToProps, { redirectFilter, fetchFilterOptions })(SellProduct)