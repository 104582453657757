import axios from 'axios';

import config from '../config';

export const ABOUT_DATA = 'ABOUT_DATA';
export const ABOUT_DATA_FULFILLED = 'ABOUT_DATA_FULFILLED';
export const ABOUT_DATA_REJECTED = 'ABOUT_DATA_REJECTED';

export const fetchAbout = () => dispatch => {
  dispatch({
    type: ABOUT_DATA,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/about-index`, { params: {} })
    .then(response => {
      dispatch({
        type: ABOUT_DATA_FULFILLED,
        payload: response.data
      });
    })
    .catch(err => {
      dispatch({
        type: ABOUT_DATA_REJECTED,
        payload: err
      })
    });
}