import axios from 'axios';
import config from '../config';
import { headers } from '../utilities'

export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const IS_LOGGED_IN_FULFILLED = 'IS_LOGGED_IN_FULFILLED';
export const IS_LOGGED_IN_REJECTED = 'IS_LOGGED_IN_REJECTED';

export const checkIfLoggedIn = (data) => dispatch => {
  dispatch({
    type: IS_LOGGED_IN,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/my-info`, { headers: headers() })
    .then(response => {
      dispatch({
        type: IS_LOGGED_IN_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: IS_LOGGED_IN_REJECTED,
        payload: err
      })
      return { error: true, err };
    });
}