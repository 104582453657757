import * as _ from 'lodash';
import moment from 'moment';
import {
	FETCH_ORDER_STATUS,
	FETCH_ORDER_STATUS_FULFILLED,
	FETCH_ORDER_STATUS_REJECTED,
	POST_PO_REFERENCE,
	POST_PO_REFERENCE_FULFILLED,
	POST_PO_REFERENCE_REJECTED
} from '../actions';
import ColumnGroup from 'antd/lib/table/ColumnGroup';

export default (state = {
	data: null,
	fetching: false,
	fetched: false,
	postPoLoading: false,
	error: null
}, action) => {
	switch (action.type) {
		case FETCH_ORDER_STATUS:
			return { ...state, fetching: true, fetched: false, error: null }
		case FETCH_ORDER_STATUS_FULFILLED:
			action.payload.data.map((data, i) => {
				data.poCreatedAt = moment(data.poCreatedAt).format("MM/DD/YYYY")
				data.poCreatedAtWhole = moment(data.poCreatedAt).format("MMMM DD YYYY")
				data.targetedEtd = moment(data.targetedEtd).format("MMMM YYYY")
				data.status = data.distributor.status === 0 ? 'Cancelled' : (data.distributor.status === 1 ? 'In Process' : 'Invoiced')
			})

			return {
				...state, fetching: false, fetched: true, error: null,
				data: action.payload
			};
		case FETCH_ORDER_STATUS_REJECTED:
			return { ...state, fetching: false, fetched: false, error: action.payload, data: null };
		case POST_PO_REFERENCE:
			return { ...state, postPoLoading: true }
		case POST_PO_REFERENCE_FULFILLED:
			return { ...state, postPoLoading: false, };
		case POST_PO_REFERENCE_REJECTED:
			return { ...state, postPoLoading: false };
		default:
			return state;
	}
}
