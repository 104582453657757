import React from "react";
import { connect } from "react-redux";
import { Layout, Card, Modal, Row, Col, Button } from "antd";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
const validate = (values) => {
  const errors = {};
  if (!values.distributor_contact_id) {
    errors.distributor_contact_id = "Required";
  }
  if (!values.distributor_address_id) {
    errors.distributor_address_id = "Required";
  }
  if (!values.email_address) {
    errors.email_address = "Required";
  }
  if (!values.port_of_destination) {
    errors.port_of_destination = "Required";
  }
  if (!values.estimate_time_of_delivery) {
    errors.estimate_time_of_delivery = "Required";
  }
  if (!values.sales_ref_number) {
    errors.sales_ref_number = "Required";
  }

  if (!values.volume) {
    errors.volume = "Required";
  }

  if (!values.special_instructions && values.volume === 'others') {
    errors.special_instructions = "Required";
  }

  return errors;
};

class DeliveryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedContainerOptionsValue: 0 };
  }
  displayDistributorBasedOnType(type) {
    const { distributor } = this.props;
    let emailArray = [];
    distributor &&
      distributor.contact.map((data) => {
        if (data.type === type) emailArray.push(data);
      });
    return emailArray;
  }
  displayContactBasedOnId(id, column) {
    const { userInfo } = this.props;
    return userInfo.contact.find((contactData) => contactData.id === id)[
      column
    ];
  }

  handleContainerOptions = (e) => {
    this.setState({
      selectedContainerOptionsValue: e.target.value,
    });
  };

  render() {
    const {
      handleSubmit,
      isOrderDeliveryModalShow,
      onContactDetailsFormSubmit,
      onCloseDeliveryFormModal,
      handleChangeDate,
      distributor,
      startDate,
      renderSelectField,
      renderInput,
      renderDatepicker,
      formValidators,
      etdMonth,
    } = this.props;
    return (
      <Modal
        className="order-delivery-confirm-modal"
        visible={isOrderDeliveryModalShow}
        style={{ top: 20 }}
        onCancel={() => onCloseDeliveryFormModal()}
        footer={null}
        closable={false}
        width={750}
      >
        <Layout id="Clicked_Order_Submit">
          <Layout.Content className="order-delivery-header">
            <Row>
              <Col>
                <h1>Delivery Details</h1>
                <p>
                  The details shown below may be autofilled based on your
                  registered account details.
                </p>
              </Col>
            </Row>
          </Layout.Content>
          <Layout.Content className="order-confirmation-content">
            <Card style={{ padding: "1vh 1vw" }}>
              <Layout.Content>
                <form onSubmit={handleSubmit}>
                  <Row className="delivery-details-form" gutter={24}>
                    <Col md={12}>
                      <div className="form-label">
                        <h3>Customer Name</h3>
                        <p>{distributor.name}</p>
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="form-label">
                        <h3>Customer Code</h3>
                        <p>{distributor.customer_code}</p>
                      </div>
                    </Col>

                    <Col md={24}>
                      <div className="form-label select-holder">
                        <h3>Office Address</h3>
                        <Field
                          name={`distributor_address_id`}
                          placeholder="Click To Add Your Address"
                          className="inputStyle"
                          component={renderSelectField}
                        >
                          <option value="" selected className="select">
                            Click To Add Your Address
                          </option>
                          {distributor &&
                            distributor.address &&
                            distributor.address.map((data) => {
                              return (
                                <option
                                  value={data.id}
                                  title={data.name}
                                >{`${data.name.substr(0, 50)}`}</option>
                              );
                            })}
                        </Field>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-label select-holder">
                        <h3>Email Address</h3>
                        <Field
                          name={`email_address`}
                          placeholder="Click To Add Your Email"
                          className="inputStyle"
                          component={renderSelectField}
                        >
                          <option value="" selected className="select">
                            Click To Add Your Email
                          </option>
                          {this.displayDistributorBasedOnType("email").map(
                            (data) => {
                              return (
                                <option value={data.id}>{data.contact}</option>
                              );
                            }
                          )}
                        </Field>
                      </div>
                    </Col>
                    {/* <Col md={12}>
                                            <div className="form-label select-holder">
                                                <h3>Phone Number</h3>
                                                <Field
                                                    name={`distributor_contact_id`}
                                                    placeholder="Port Of Destination"
                                                    className="inputStyle"
                                                    component={renderSelectField}
                                                >
                                                    <option value='' selected className="select">
                                                        Click To Add Your Phone
                                                    </option>
                                                    {this.displayDistributorBasedOnType(
                                                        "mobile"
                                                    ).map(data => {
                                                        return (
                                                            <option value={data.id}>
                                                                {data.contact}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>
                                            </div>
                                        </Col> */}

                    <Col md={12}>
                      <div className="form-label select-holder">
                        <h3>Port of Destination</h3>
                        <Field
                          name={`port_of_destination`}
                          placeholder="Port Of Destination"
                          className="inputStyle"
                          component={renderSelectField}
                        >
                          <option value="" selected className="select">
                            Click To Add Your Port Destination
                          </option>
                          {distributor &&
                            Array.isArray(distributor.port_destination) &&
                            distributor.port_destination.map((data) => {
                              return <option value={data}>{data}</option>;
                            })}
                        </Field>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-label">
                        <h3>PO Ref Number</h3>
                        <Field
                          name={`po_ref_no`}
                          className="inputStyle no-border"
                          readOnly={true}
                          component={renderInput}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-label">
                        <h3>TARGET ETD</h3>
                        <Field
                          name={`estimate_time_of_delivery`}
                          onSelect={(e) => handleChangeDate(e)}
                          selected={startDate || new Date()}
                          component={renderDatepicker}
                          etdMonth={etdMonth || new Date()}
                          label="Click To Add Target ETD"
                          className="inputStyle"
                          autoComplete="off"
                          autoCorrect="off"
                          spellCheck="off"
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-label">
                        <h3>CUSTOMER PO NUMBER</h3>
                        <Field
                          name={`sales_ref_number`}
                          label="Type Your Customer PO Number"
                          className="inputStyle"
                          component={renderInput}
                        />
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="form-label select-holder">
                        <h3>Container Options</h3>
                        <Field
                          name={`volume`}
                          placeholder="Click To Add Your Container Options"
                          className="inputStyle"
                          component={renderSelectField}
                          onChange={this.handleContainerOptions}
                        >
                          <option value="" selected className="select">
                            Click To Add Your Container Options
                          </option>
                          <option value="20FT">20FT</option>
                          <option value="40FT">40FT</option>
                          <option value="40HC">40HC</option>
                          <option value="Others">Others</option>
                        </Field>
                      </div>
                    </Col>

                    <Col md={24}>
                      <div className="form-label">
                        <h3>Special Instructions</h3>
                        <Field
                          name={`special_instructions`}
                          placeholder="Type Your Special Instructions Here"
                          component={
                            this.state.selectedContainerOptionsValue ===
                            "others"
                              ? renderInput
                              : "input"
                          }
                          className="inputStyle"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="btn-container">
                      <Button
                        // onClick={handleSubmit(formData => {
                        //     onContactDetailsFormSubmit(formData);
                        // })}
                        htmlType="submit"
                        className="btn-small btn-primary"
                        style={{
                          width: 310,
                        }}
                      >
                        Proceed To Order Confirmation >
                      </Button>
                    </div>
                  </Row>
                </form>
              </Layout.Content>
            </Card>
          </Layout.Content>
        </Layout>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    drafts: {
      deliveryDetailsDraftData: {
        distributor_address_id,
        distributor_contact_id,
        distributor_email_id,
        special_instructions,
        port_destination,
        estimated_delivery,
        sales_ref_number,
      },
    },
  } = state;
  return {
    deliveryDetailsDraftData: state.drafts.deliveryDetailsDraftData,
    userInfo: state.distributor.data.distributor,
    initialValues: {
      po_ref_no: ownProps.po_ref_no,
      email_address: distributor_email_id,
      distributor_address_id,
      distributor_contact_id,
      port_of_destination: port_destination,
      estimate_time_of_delivery: new Date(estimated_delivery),
      sales_ref_number,
      special_instructions,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // onSubmitBulkUpload: (data) => dispatch(submitBulkUpload(data))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: "delivery_details_form",
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
      destroyOnUnmount: false,
      validate,
    })(DeliveryDetails)
  )
);
