import axios from "axios";

import config from "../config";
import { headers, numberFormat } from "../utilities";
import { serialize } from '../utilities/helper'
import sampleData from "../components/OrderStatus/data";
import ColumnGroup from "antd/lib/table/ColumnGroup";

export const FETCH_ORDER_STATUS = "FETCH_ORDER_STATUS";
export const FETCH_ORDER_STATUS_FULFILLED = "FETCH_ORDER_STATUS_FULFILLED";
export const FETCH_ORDER_STATUS_REJECTED = "FETCH_ORDER_STATUS_REJECTED";

export const FETCH_PO_STATUS = "FETCH_PO_STATUS";
export const FETCH_PO_STATUS_FULFILLED = "FETCH_PO_STATUS_FULFILLED";
export const FETCH_PO_STATUS_REJECTED = "FETCH_PO_STATUS_REJECTED";

export const PURCHASE_BREAKDOWN = "PURCHASE_BREAKDOWN";
export const PURCHASE_BREAKDOWN_FULFILLED = "PURCHASE_BREAKDOWN_FULFILLED";

export const POST_PO_REFERENCE = "POST_PO_REFERENCE";
export const POST_PO_REFERENCE_FULFILLED = "POST_PO_REFERENCE_FULFILLED";
export const POST_PO_REFERENCE_REJECTED = "POST_PO_REFERENCE_REJECTED";

export const fetchOrderStatus = (data, searchParams = undefined) => dispatch => {
  const queryString = searchParams !== undefined ? serialize(searchParams) : null;
  const newUrl = `${config.REACT_APP_API_BASE_URL}/orders${queryString ? queryString : ''}`;

  dispatch({
    type: FETCH_ORDER_STATUS,
    payload: {}
  });
  dispatch({
    type: FETCH_PO_STATUS,
    payload: {}
  });
  return axios
    .get(newUrl, { headers: headers() })
    .then(response => {
      let responseData = response.data;
      const ordersData = responseData.data.filter(value => value.product.length > 0);
      const sapOrderStatus = data;
      let orderStatusArray = [];
      ordersData.map(fromOrdersData => {

        const dataFoundFromSap = sapOrderStatus.find(fromSapOrderStatus => fromSapOrderStatus["customer_po_number"] === fromOrdersData["po_reference"]);
        const statusPerProductIds = dataFoundFromSap ? findOrderStatus(ordersData, sapOrderStatus, dataFoundFromSap["customer_po_number"], true) : 'For Production'

        let data = {
          poCreatedAt: fromOrdersData["created_at"], // cloudwalk
          poNumber: fromOrdersData["po_reference"], // sap
          orderNumber: fromOrdersData["transaction_number"], // cloudwalk
          containerOption: fromOrdersData["volume"], // cloudwalk
          salesRefNumber: fromOrdersData["sales_ref_number"] ? fromOrdersData["sales_ref_number"] : "", // Will add new field at order form // cloudwalk
          // status: dataFoundFromSap ? findOrderStatus(ordersData, sapOrderStatus, dataFoundFromSap["customer_po_number"]) : "in process", // sap
          // orderedQty: getOrderQuantityForOrderStatus(ordersData, sapOrderStatus, fromOrdersData["po_reference"], fromOrdersData['sales_ref_number']).toLocaleString(),
          orderedQty: fromOrdersData.orderedQty,
          // invoiceQty: getInvoiceQuantityFromOrderStatus(ordersData, sapOrderStatus, fromOrdersData['po_reference']),
          invoiceQty: getInvoicedQtyForOrderStatus(ordersData, sapOrderStatus, fromOrdersData["po_reference"]).toLocaleString(),
          // amount: getInvoiceAmountFromOrderStatus(ordersData, sapOrderStatus, fromOrdersData['po_reference']),
          // amount: numberFormat(getOrderAmountForOrderStatus(ordersData, sapOrderStatus, fromOrdersData["po_reference"], fromOrdersData['sales_ref_number'])),
          amount: fromOrdersData.amount,
          cbm: numberFormat(getCbmForOrderStatus(fromOrdersData)),
          grossWeight: parseFloat(getGrossWeightForOrderStatus(fromOrdersData)),
          // cbm: 0,
          targetedEtd: fromOrdersData["estimated_delivery"], // cloudwalk
          distributorOrderId: fromOrdersData && fromOrdersData["id"],

          //Addtional object for download files "EXCEL", "PDF", "CSV"
          distributor: fromOrdersData,
          statusPerProductIds: statusPerProductIds || ['For Production']
        };
        orderStatusArray.push(data);

        responseData.data = orderStatusArray;
      });
      dispatch({
        type: FETCH_ORDER_STATUS_FULFILLED,
        payload: responseData
      });
      // return response;
      return { error: false, response: responseData, type: `${FETCH_ORDER_STATUS_FULFILLED}_FULFILLED`, };
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_STATUS_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
};

export const fetchPoStatus = (data, poNumberFromComponent) => dispatch => {
  const params = serialize({ isPaginated: false});
  dispatch({
    type: FETCH_PO_STATUS,
    payload: {}
  });
  let poStatusArray = [];
  return axios
    .get(`${config.REACT_APP_API_BASE_URL}/orders${params}`, { headers: headers() })
    .then(response => {
      const urlParams = new URLSearchParams(window.location.search);
      const po_number = poNumberFromComponent !== undefined ? poNumberFromComponent : urlParams.get("po_number");
      const ordersData = response.data;
      const sapData = data;
      // Declare data by PO Number / Match the "PO Number Reference" to "po_number" in the URL
      const ordersDataByPoNumber = ordersData.find(fromOrdersData => fromOrdersData.po_reference === po_number);
      ordersDataByPoNumber.product &&
        ordersDataByPoNumber.product.map(fromProductData => {
          // Find the data in "SAP DATA", then match the MATERIAL CODE into "material code of portal"
          const sapOrderStatus = sapData.find(fromSapOrderStatus => fromSapOrderStatus["customer_po_number"] === po_number &&
            fromSapOrderStatus && fromProductData.variant && Number(fromProductData.variant["material_code"]) === Number(fromSapOrderStatus["material"]));
          // Data to display
          let objectData = {
            customerCode: sapOrderStatus ? sapOrderStatus["customer_po_code"] : ordersDataByPoNumber.distributor.customer_code, // sap
            materialCode: fromProductData && fromProductData.variant["material_code"],
            skuDescription: fromProductData && fromProductData.variant["name"], // cloudwalk
            packingScheme: fromProductData.variant && fromProductData.variant["packing_scheme"], // cloudwalk
            // orderQty: getOrderQuantityInEveryMaterialCode(fromProductData.variant['material_code'], sapData, fromProductData, po_number).toLocaleString(),
            // orderAmount: numberFormat(getOrderQuantityInEveryMaterialCode(fromProductData.variant['material_code'], sapData, fromProductData, po_number) * fromProductData['selling_price']),
            orderQty: fromProductData && fromProductData["quantity"].toLocaleString(),
            orderAmount: fromProductData && numberFormat(parseFloat(fromProductData["quantity"]) * fromProductData["selling_price"]),
            status: sapOrderStatus ? getStatus(sapOrderStatus) : "for production", // hide for now in component
            actualStocks: getActualStocksInEveryMaterialCode(fromProductData.variant["material_code"], sapData, fromProductData, po_number).toLocaleString(),
            amount: numberFormat(getInvoiceAmountInEveryMaterialCode(fromProductData.variant["material_code"], sapData, fromProductData, po_number)) || 0,
            // cbm: numberFormat(getCbmInEveryMaterialCode(fromProductData.variant["material_code"], sapData, po_number)) || 0,
            cbm: (fromProductData.variant['cbm'] * fromProductData['quantity']).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
            grossWeight: fromProductData && parseFloat((fromProductData.variant['gross_weight'] * fromProductData['quantity']).toFixed(2)).toLocaleString(),
            pricelist: fromProductData && fromProductData.variant.pricelist_per_distributor[0],
            remarks: ordersDataByPoNumber && ordersDataByPoNumber.remarks !== null ? ordersDataByPoNumber.remarks : '',
            // For Mother PO view only
            statusView: ordersDataByPoNumber && ordersDataByPoNumber.status,
            // For Downloading File (EXCEL, PDF, CSV)
            productVariant: fromProductData && fromProductData
          };
          poStatusArray.push(objectData);
        });
      dispatch({
        type: FETCH_PO_STATUS_FULFILLED,
        payload: poStatusArray
      });
      return { response: poStatusArray };
    })
    .catch(err => {
      dispatch({
        type: FETCH_PO_STATUS_REJECTED,
        payload: err
      });
    });
};

// HELPER FUNCTIONS BELOW

export const getInvoiceAmountFromOrderStatus = (
  ordersData,
  sapData,
  po_number
) => {
  let orderQuantityArray = [];
  const ordersDataByPoNumber = ordersData.find(
    fromOrdersData => fromOrdersData.po_reference === po_number
  );
  ordersDataByPoNumber.product &&
    ordersDataByPoNumber.product.map(fromProductData => {
      const sapOrderStatus = sapData.find(
        fromSapOrderStatus =>
          fromSapOrderStatus["customer_po_number"] === po_number &&
          fromSapOrderStatus &&
          fromProductData.variant &&
          Number(fromProductData.variant["material_code"]) ===
          Number(fromSapOrderStatus["material"])
      );
      orderQuantityArray.push(
        parseFloat(
          getInvoiceAmountInEveryMaterialCodeFromOrderStatus(
            sapOrderStatus && sapOrderStatus["material"],
            sapData,
            fromProductData
          )
            .toString()
            .replace(/,/g, "")
        )
      );
    });
  const orderQuantity = orderQuantityArray.reduce((a, c) => {
    return a + parseFloat(c);
  });
  return orderQuantity.toLocaleString();
};

export const getInvoiceAmountInEveryMaterialCodeFromOrderStatus = (
  materialCodeFromOrdersData,
  sapDataWhole,
  fromProductData
) => {
  const filteredByMaterialCode = sapDataWhole.filter(
    fromSapData =>
      Number(fromSapData["material"]) === Number(materialCodeFromOrdersData)
  );
  return filteredByMaterialCode.reduce((a, c) => {
    return (
      a +
      parseFloat(
        getAmountForPo(getStatus(c), fromProductData, c)
          .toString()
          .replace(/,/g, "")
      )
    );
  }, 0);
};

export const getOrderAmountForOrderStatus = (
  ordersData,
  sapData,
  po_number,
  sales_ref_number
) => {
  const filteredOrdersDataByPo = ordersData.filter(
    fromOrdersData => ((fromOrdersData["po_reference"] === po_number) && (fromOrdersData['sales_ref_number'] === sales_ref_number))
  );
  const quantityArray = [];
  filteredOrdersDataByPo.map(fromOrdersDataByPo => {
    fromOrdersDataByPo.product.map(getDataFromOrders => {
      quantityArray.push(
        getDataFromOrders["quantity"] * getDataFromOrders["selling_price"]
      );
    });
  });
  return quantityArray.reduce((a, c) => {
    return a + parseFloat(c);
  });
};

export const getOrderQuantityForOrderStatus = (
  ordersData,
  sapData,
  po_number,
  sales_ref_number
) => {
  const filteredOrdersDataByPo = ordersData.filter(
    fromOrdersData => ((fromOrdersData["po_reference"] === po_number) && (fromOrdersData['sales_ref_number'] === sales_ref_number))
  );
  const quantityArray = [];
  filteredOrdersDataByPo.map(fromOrdersDataByPo => {
    fromOrdersDataByPo.product.map(getDataFromOrders => {
      quantityArray.push(getDataFromOrders["quantity"]);
    });
  });

  return quantityArray.reduce((a, c) => {
    return a + parseFloat(c);
  });
};

export const getInvoicedQtyForOrderStatus = (
  ordersData,
  sapData,
  po_number
) => {
  const filteredByPoFromSapData = sapData.filter(
    sapDataFilterBy => sapDataFilterBy["customer_po_number"] === po_number
  );
  const invoicedQtyArray = [];
  filteredByPoFromSapData.map(foundFromSap => {
    invoicedQtyArray.push(
      parseFloat(
        getActualStocks(
          "invoiced",
          foundFromSap["product_variant"],
          foundFromSap
        )
          .toString()
          .replace(/,/g, "")
      )
    );
  });
  return (
    invoicedQtyArray &&
    invoicedQtyArray.reduce((a, c) => {
      return a + parseFloat(c);
    }, 0)
  );
};

export const getCbmForOrderStatus = (fromOrdersData) => {

  return (
    fromOrdersData &&
    fromOrdersData.product.reduce((a, c) => {
      return a + parseFloat(c['variant']["cbm"] * c['quantity']);
    }, 0)
  );
};

export const getGrossWeightForOrderStatus = (fromOrdersData) => {

  return (
    fromOrdersData &&
    fromOrdersData.product.reduce((a, c) => {
      return a + parseFloat(c['variant']["gross_weight"] * c['quantity']);
    }, 0)
  );
};


export const getInvoiceQuantityFromOrderStatus = (
  ordersData,
  sapData,
  po_number
) => {
  let invoiceQuantityArray = [];
  const ordersDataByPoNumber = ordersData.find(
    fromOrdersData => fromOrdersData.po_reference === po_number
  );
  ordersDataByPoNumber.product &&
    ordersDataByPoNumber.product.map(fromProductData => {
      const sapOrderStatus = sapData.find(
        fromSapOrderStatus =>
          fromSapOrderStatus["customer_po_number"] === po_number
      );
      invoiceQuantityArray.push(
        parseFloat(
          getActualStocksInEveryMaterialCode(
            sapOrderStatus && sapOrderStatus["material"],
            sapData,
            fromProductData
          )
            .toString()
            .replace(/,/g, "")
        )
      );
    });
  const orderQuantity = invoiceQuantityArray.reduce((a, c) => {
    return a + parseFloat(c);
  });
  return orderQuantity.toLocaleString();
};

export const getOrderQuantityFromOrderStatus = (
  ordersData,
  sapData,
  po_number
) => {
  let orderQuantityArray = [];
  const ordersDataByPoNumber = ordersData.find(
    fromOrdersData => fromOrdersData.po_reference === po_number
  );
  ordersDataByPoNumber.product &&
    ordersDataByPoNumber.product.map(fromProductData => {
      const sapOrderStatus = sapData.find(
        fromSapOrderStatus =>
          fromSapOrderStatus["customer_po_number"] === po_number &&
          fromSapOrderStatus &&
          fromProductData.variant &&
          Number(fromProductData.variant["material_code"]) ===
          Number(fromSapOrderStatus["material"])
      );
      orderQuantityArray.push(
        parseFloat(
          getOrderQuantityInEveryMaterialCode(
            sapOrderStatus && sapOrderStatus["material"],
            sapData,
            fromProductData,
            po_number
          )
            .toString()
            .replace(/,/g, "")
        )
      );
    });
  const orderQuantity = orderQuantityArray.reduce((a, c) => {
    return a + parseFloat(c);
  });
  return orderQuantity.toLocaleString();
};

export const computeAmount = (ordersPoReference, ordersData) => {
  const fromOrdersData = ordersData.find(
    data => data.po_reference === ordersPoReference
  );
  const sumPrice =
    fromOrdersData.product &&
    fromOrdersData.product.reduce(
      (a, c) => a + parseFloat(c.selling_price) * parseInt(c.quantity),
      0
    );
  return sumPrice;
};

export const findOrderStatus = (ordersData, sapData, activePoNumberByRow, returnWithId) => {
  const statusArray = []
  const ordersDataFilterByPoNumber = ordersData.filter((fromOrdersData) => fromOrdersData['po_reference'] === activePoNumberByRow)
  ordersDataFilterByPoNumber && ordersDataFilterByPoNumber[0].product.map((fromProductData) => {
    const sapOrderStatus = sapData.find(fromSapOrderStatus => fromSapOrderStatus["customer_po_number"] === activePoNumberByRow &&
      fromSapOrderStatus && fromProductData.variant && Number(fromProductData.variant["material_code"]) === Number(fromSapOrderStatus["material"]));
    let objectData = {
      id: fromProductData.id,
      status: sapOrderStatus ? getStatus(sapOrderStatus) : "for production", // hide for now in component
    };
    statusArray.push(objectData);
  })

  if (returnWithId) {

    return statusArray

  } else {
    // this will removed the data that has an "EMPTY VALUE STATUS", for ex: I ordered items and the material code does not match in SAP Data.
    // const filteredStatusArray = statusCollectionArray.filter((data) => { return data.status })
    let returnStatus;
    // using "every" function of array will determine if all the status value are in "invoiced" state
    // const isInvoiced = filteredStatusArray.every((data) => data.status === 'invoiced') // true or false
    const isInvoiced = statusArray.every(data => data.status === "invoiced"); // true or false (FOR NOW, WILL NOT FILTER THE EMPTY STATUS)
    if (isInvoiced) {
      returnStatus = "invoiced";
    } else {
      returnStatus = "in process";
    }
    return returnStatus;
  }
};

// export const findOrderStatus = (ordersData, sapData, activePoNumberByRow) => {
//   const dataFromSap = sapData.filter(foundFromSap => {
//     return foundFromSap["customer_po_number"] === activePoNumberByRow;
//   });
//   const statusArray = [];
//   dataFromSap.map(mapByStatus => {
//     statusArray.push(getStatus(mapByStatus));
//   });

//   // // this will removed the data that has an "EMPTY VALUE STATUS", for ex: I ordered items and the material code does not match in SAP Data.
//   // const filteredStatusArray = statusCollectionArray.filter((data) => { return data.status })
//   let returnStatus;
//   // using "every" function of array will determine if all the status value are in "invoiced" state
//   // const isInvoiced = filteredStatusArray.every((data) => data.status === 'invoiced') // true or false
//   const isInvoiced = statusArray.every(data => data === "invoiced"); // true or false (FOR NOW, WILL NOT FILTER THE EMPTY STATUS)
//   if (isInvoiced) {
//     returnStatus = "invoiced";
//   } else {
//     returnStatus = "in process";
//   }
//   return returnStatus;
// };

export const computeCbm = (ordersData, sapData, activePoNumberByRow) => {
  // get the active row by PO Number of fetchOrderStatus Function
  const ordersDataByPoNumber = ordersData.find(
    fromOrdersData => fromOrdersData.po_reference === activePoNumberByRow
  );
  let cbmArray = [];
  // after getting the ordersData by Po number, map it to product. then find the material code from productData to SapData.
  ordersDataByPoNumber.product &&
    ordersDataByPoNumber.product.map(fromProductData => {
      const sapOrderStatus = sapData.find(
        fromSapOrderStatus =>
          fromSapOrderStatus["customer_po_number"] === activePoNumberByRow &&
          fromProductData.variant &&
          Number(fromProductData.variant["material_code"]) ===
          Number(fromSapOrderStatus["material"])
      );
      cbmArray.push({ cbm: sapOrderStatus["volume"] });
    });
  return cbmArray.reduce((a, c) => {
    return a + parseFloat(c.cbm);
  }, 0);
};

export const getOrderQuantityInEveryMaterialCode = (
  materialCodeFromOrdersData,
  sapDataWhole,
  fromProductData,
  poNumber
) => {
  const filteredByMaterialCode = sapDataWhole.filter(
    fromSapData =>
      Number(fromSapData["material"]) === Number(materialCodeFromOrdersData) &&
      fromSapData["customer_po_number"] === poNumber
  );
  return filteredByMaterialCode.reduce((a, c) => {
    return (
      a +
      parseFloat(
        getActualStocks(getStatus(c), fromProductData, c)
          .toString()
          .replace(/,/g, "")
      )
    );
  }, 0);
};

export const getActualStocksInEveryMaterialCode = (
  materialCodeFromOrdersData,
  sapDataWhole,
  fromProductData,
  poNumber
) => {
  const filteredByMaterialCode = sapDataWhole.filter(
    fromSapData =>
      Number(fromSapData["material"]) === Number(materialCodeFromOrdersData) &&
      fromSapData["customer_po_number"] === poNumber
  );
  return filteredByMaterialCode.reduce((a, c) => {
    return (
      a +
      parseFloat(
        getActualStocks("invoiced", fromProductData, c)
          .toString()
          .replace(/,/g, "")
      )
    );
  }, 0);
};

export const getInvoiceAmountInEveryMaterialCode = (
  materialCodeFromOrdersData,
  sapDataWhole,
  fromProductData,
  poNumber
) => {
  const filteredByMaterialCode = sapDataWhole.filter(
    fromSapData =>
      Number(fromSapData["material"]) === Number(materialCodeFromOrdersData) &&
      fromSapData["customer_po_number"] === poNumber
  );
  return filteredByMaterialCode.reduce((a, c) => {
    return (
      a +
      parseFloat(
        getAmountForPo("invoiced", fromProductData, c)
          .toString()
          .replace(/,/g, "")
      )
    );
  }, 0);
};

export const getCbmInEveryMaterialCode = (
  materialCodeFromOrdersData,
  sapData,
  poNumber
) => {
  const filteredByMaterialCode = sapData.filter(
    fromSapData =>
      Number(fromSapData["material"]) === Number(materialCodeFromOrdersData) &&
      fromSapData["customer_po_number"] === poNumber
  );
  return filteredByMaterialCode.reduce((a, c) => {
    return a + parseFloat(c["volume"]);
  }, 0);
};

export const getAmountForPo = (status, orderData, sapData) => {
  let po;
  status = status ? status : "";
  if (status === "forProduction") {
    po =
      (orderData && orderData["quantity"]) *
      (orderData && parseFloat(orderData["selling_price"])); // cloudwalk
  } else if (status === "produced") {
    po =
      (sapData && parseInt(sapData["order_qty"].toString().replace(/,/g, ""))) *
      (orderData && parseFloat(orderData["selling_price"]));
  } else if (status === "forLoading") {
    po =
      (sapData &&
        parseInt(sapData["delivered_qty"].toString().replace(/,/g, ""))) *
      (orderData && parseFloat(orderData["selling_price"]));
  } else if (status === "invoiced") {
    po =
      (sapData &&
        parseInt(sapData["billed_qty"].toString().replace(/,/g, ""))) *
      (orderData && parseFloat(orderData["selling_price"]));
  }
  return po || 0;
};

export const getStatus = data => {
  data = data || "";
  let status;
  if (data["billing_document"]) status = "invoiced";
  else if (data["delivery"]) status = "forLoading";
  else if (data["sales_document"]) status = "produced";
  else if (!data["sales_document"]) status = "forProduction";
  else status = "";
  return status;
};

export const getActualStocks = (status, orderData, sapData) => {
  let actualStocks;
  if (status === "forProduction") {
    actualStocks = orderData && orderData["quantity"]; // cloudwalk
  } else if (status === "produced") {
    actualStocks = sapData && sapData["order_qty"]; // sap
  } else if (status === "forLoading") {
    actualStocks = sapData && sapData["delivered_qty"]; // sap
  } else if (status === "invoiced") {
    actualStocks = sapData && sapData["billed_qty"]; // sap
  }
  return actualStocks;
};

export const purchaseBreakdown = sapData => dispatch => {
  return axios
    .get(`${config.REACT_APP_API_BASE_URL}/orders`, { headers: headers() })
    .then(response => {
      const ordersData = response.data;
      const urlParams = new URLSearchParams(window.location.search);
      const urlPoNumber = urlParams.get("po_number");
      const listDataByPoNumberFromSap = sapData.filter(
        fromSapData => fromSapData.customer_po_number === urlPoNumber
      );

      let purchaseBreakdownArray = [];
      listDataByPoNumberFromSap.map(dataFromSap => {
        const ordersDataFindByCustomerPoNumber = ordersData.find(
          ordersData => ordersData["po_reference"] === urlPoNumber
        );
        const ordersDataFindByMaterialCode = ordersDataFindByCustomerPoNumber.product.find(
          ordersData =>
            Number(ordersData.variant["material_code"]) ===
            Number(dataFromSap["material"])
        );
        const fromOrdersData = ordersDataFindByMaterialCode;

        const data = {
          soNumber: dataFromSap["sales_document"],
          salesRefNumber: ordersDataFindByCustomerPoNumber['sales_ref_number'],
          poNumber: dataFromSap["customer_po_number"],
          materialCode: fromOrdersData
            ? fromOrdersData.variant["material_code"]
            : dataFromSap["product_variant"]["material_code"],
          skuDescription: fromOrdersData
            ? fromOrdersData.variant["name"]
            : dataFromSap["product_variant"]["name"],
          packingScheme: fromOrdersData
            ? fromOrdersData.variant["packing_scheme"]
            : dataFromSap["product_variant"]["packing_scheme"],
          status: getStatus(dataFromSap),
          pricelist: fromOrdersData && fromOrdersData.variant.pricelist_per_distributor[0],
          actualStocks: getActualStocks(
            getStatus(dataFromSap),
            fromOrdersData,
            dataFromSap
          ),
          amount: numberFormat(getSellingPriceByQty(getStatus(dataFromSap), fromOrdersData, dataFromSap)),
          cbm: dataFromSap["volume"],
          grossWeight: fromOrdersData
            ? numberFormat(
              Number(dataFromSap["product_variant"]["gross_weight"]) *

              Number(getActualStocks(getStatus(dataFromSap), fromOrdersData, dataFromSap)
                .toString().replace(/,/g, ""))
            ).toLocaleString()
            : numberFormat(
              dataFromSap["product_variant"]["gross_weight"] *
              Number(
                getActualStocks(
                  getStatus(dataFromSap),
                  dataFromSap["product_variant"],
                  dataFromSap
                )
                  .toString()
                  .replace(/,/g, "")
              )
                .toFixed(2)
            ).toLocaleString()
        };
        purchaseBreakdownArray.push(data);
      });
      dispatch({
        type: PURCHASE_BREAKDOWN,
        payload: purchaseBreakdownArray
      });
    });
};


export const getSellingPriceByQty = (status, fromOrdersData, sapData) => {
  let po;
  status = status ? status : "";
  if (status === "forProduction") {
    po =
      (fromOrdersData && fromOrdersData["quantity"]) *
      (sapData && parseFloat(sapData['product_variant']['pricelist_per_distributor'][0]["selling_price"])); // cloudwalk
  } else if (status === "produced") {
    po =
      (sapData && parseInt(sapData["order_qty"].toString().replace(/,/g, ""))) *
      (sapData && parseFloat(sapData['product_variant']['pricelist_per_distributor'][0]["selling_price"]));
  } else if (status === "forLoading") {
    po =
      (sapData &&
        parseInt(sapData["delivered_qty"].toString().replace(/,/g, ""))) *
      (sapData && parseFloat(sapData['product_variant']['pricelist_per_distributor'][0]["selling_price"]));
  } else if (status === "invoiced") {
    po =
      (sapData &&
        parseInt(sapData["billed_qty"].toString().replace(/,/g, ""))) *
      (sapData && parseFloat(sapData['product_variant']['pricelist_per_distributor'][0]["selling_price"]));
  }
  return po || 0;
};

export const postPoNumber = (id, poReference) => dispatch => {
  const data = {
    po_reference: poReference
  };
  dispatch({
    type: POST_PO_REFERENCE,
    payload: {}
  });
  return axios
    .post(
      `${config.REACT_APP_API_BASE_URL}/update-po-reference/${id}`,
      { ...data },
      { headers: headers() }
    )
    .then(response => {
      dispatch({
        type: POST_PO_REFERENCE_FULFILLED,
        payload: response.data
      });
      return { error: false, response: response };
    })
    .catch(err => {
      dispatch({
        type: POST_PO_REFERENCE_REJECTED,
        payload: err
      });
      return { error: true, response: err };
    });
};
