import axios from 'axios';
import * as _ from 'lodash';

import config from '../config';
import { headers } from '../utilities'

export const FETCH_LOGGED_IN_PRODUCTS = 'FETCH_LOGGED_IN_PRODUCTS';
export const FETCH_LOGGED_IN_PRODUCTS_FULFILLED = 'FETCH_LOGGED_IN_PRODUCTS_FULFILLED';
export const FETCH_LOGGED_IN_PRODUCTS_REJECTED = 'FETCH_LOGGED_IN_PRODUCTS_REJECTED';

const initialFilter = { brand: 'all', product: 'all', country: 'all' };

export const fetchLoggedInProducts = (filters = {}) => dispatch => {
  const params = _.mapValues(Object.assign({}, initialFilter, filters), filter => filter.join ? filter.join(',') : filter)
  dispatch({
    type: FETCH_LOGGED_IN_PRODUCTS,
    payload: {}
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/products-with-variants-distributor`, { headers: headers(), params })
    .then(response => {
      dispatch({
        type: FETCH_LOGGED_IN_PRODUCTS_FULFILLED,
        payload: response.data.map(product => {
          product.variant = product.variant.filter(variant => variant.pricelist.length !== 0)
          return product
        })
      });
    })
    .catch(err => {
      dispatch({
        type: FETCH_LOGGED_IN_PRODUCTS_REJECTED,
        payload: err
      })
    });
}