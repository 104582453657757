import React, { Component } from "react";
import {
    Layout, Row, Col, Input, Select, Button, Table, Divider, Collapse, Badge, Popover, Modal, Icon, Checkbox, Spin, Skeleton,
    Result, message
} from 'antd'
import { connect } from 'react-redux'
import {
    Field,
    FieldArray,
    reduxForm,
    formValueSelector,
    reset,
} from "redux-form";
import './ContainerSummary.scss'
// import { downloadFile } from './downloadFile'
import { downloadMultipleFile } from './downloadMultipleFile'
import { fetchContainerSummaryBreakdown, fetchDistributor, fetchNotifications, approveRequest, rejectRequest, fetchPermissions, postFeedback } from '../../actions'
import { Link } from "react-router-dom";
import moment from "moment";
import activeBrown from "../../assets/svg/brown.svg";
import rate1 from "../../assets/svg/rate-1.svg";
import rate2 from "../../assets/svg/rate-2.svg";
import rate3 from "../../assets/svg/rate-3.svg";
import rate4 from "../../assets/svg/rate-4.svg";
import rate5 from "../../assets/svg/rate-5.svg";

const { Search } = Input;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['EXCEL', 'CSV', 'PDF'];
const { Panel } = Collapse;

const feedbackEmoji = [
    {
        rate: 1,
        svg: rate1,
    },
    {
        rate: 2,
        svg: rate2,
    },
    {
        rate: 3,
        svg: rate3,
    },
    {
        rate: 4,
        svg: rate4,
    },
    {
        rate: 5,
        svg: rate5,
    },
];

const renderInput = ({
    input,
    label,
    type,
    className,
    readOnly,
    meta: { touched, error, warning },
}) => (
        <div style={{ height: 50 }}>
            <input
                {...input}
                className={className}
                placeholder={label}
                readOnly={readOnly}
                type={type}
            />
            {touched &&
                ((error && <span style={{ color: "red" }}>{error}</span>) ||
                    (warning && <span>{warning}</span>))}
        </div>
    );

const feedbackValidator = (value) =>
    value ? undefined : "Please provide your comments and suggestions";
class ContainerSummary extends Component {
    constructor() {
        super()
        this.state = {
            activeRadio: 'all',
            filterBy: 'all',
            tableData: [],
            showDownloadModal: false,
            indeterminate: false,
            checkAll: false,
            checkedList: [],
            notifications: [],
            showApprovalsModal: false,
            isApprovalsOpen: true,
            isSendingResponse: false,
            isApproveType: [""],
            urlTransactionNo: "",
            urlPoRef: "",
            urlResponse: "",
            selectedRowKeys: [],
            //
            isPostingFeedback: false,
            activeRate: null,
            showQuestion: false,
            showFeedbackModal: false,
            showThankYouModal: false,
            showStatusUpdateModal: false,
        }
    }

    componentDidMount() {
        this.props.fetchPermissions();
        this.props.fetchContainerSummaryBreakdown()
        this.props.fetchDistributor();
        this.props.fetchNotifications();

        const urlParams = new URLSearchParams(window.location.search);
        const response = urlParams.get("response");
        const transaction_number = urlParams.get("transaction_number");
        const po_reference = urlParams.get("po_reference");


        if (this.state.isApprovalsOpen) {
            if (response) {
                this.setState({
                    showApprovalsModal: true,
                    urlTransactionNo: transaction_number,
                    urlPoRef: po_reference,
                    urlResponse: response,
                    isApproveType: ["1"],
                });
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const urlParams = new URLSearchParams(window.location.search);

        if (this.props.containerSummaryData !== nextProps.containerSummaryData) {
            if (urlParams.get("po_number") !== 'null') {
                if (urlParams.get("po_number") || urlParams.get("po_number") == '') {
                    const updatedOrder = nextProps.containerSummaryData.find(
                        (value) =>
                            value.po_number === urlParams.get("po_number") ||
                            value.status !== "in process"
                    );
                    if (updatedOrder) {
                        this.setState({
                            showStatusUpdateModal: true,
                            distributorOrderId: updatedOrder.distributorOrderId,
                        });
                    }
                }
            }
            this.setState({ tableData: nextProps.containerSummaryData })
        }

        if (this.props.notificationsData.data.data !== nextProps.notificationsData.data.data) {
            this.setState({ notifications: nextProps.notificationsData.data.data });
        }
    }

    onChangeFilterRadio(status) {
        if (status === "all") {
            this.setState({
                activeRadio: status,
                tableData: this.props.containerSummaryData
            })
            return;
        }

        let filteredByStatus = this.props.containerSummaryData.filter((data) => data.status === status)

        this.setState({
            activeRadio: status,
            tableData: filteredByStatus
        })
    }

    handleFilterBy(data) {
        this.setState({
            filterBy: data,
            // tableData: this.props.containerSummaryData.filter((data) => data.type === this.state.activeRadio)
            // activeFaqsBasedOnActiveKeys: this.props.faqCategoryData.find((data) => Number(data.id) === (Number(this.state.activeCategoryId))).faqs
        })
    }

    onChange = checkedList => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
            checkAll: checkedList.length === plainOptions.length,
        });
    };


    onCheckAllChange = e => {
        this.setState({
            checkedList: e.target.checked ? plainOptions : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    handleDownload = (data) => {
        const { checkedList } = this.state
        // checkedList.includes('EXCEL') && downloadFile.excel(data);
        // checkedList.includes('CSV') && downloadFile.csv(data);
        checkedList.includes('EXCEL') && downloadMultipleFile.EXCEL(data);
        checkedList.includes('CSV') && downloadMultipleFile.CSV(data);
        checkedList.includes('PDF') && downloadMultipleFile.PDF(data);
        // checkedList.includes('PDF') && downloadFile.pdf(data);
    }

    handleSearch = (searchTerm, searchKeys) => {
        if (this.state.filterBy !== 'all') {
            searchKeys = [this.state.filterBy]
        }
        //function inside function, this will convert the dot string into actual object
        const convertStringDotNotation = (desc, obj) => {
            var arr = desc.split(".");
            while (arr.length && (obj = obj[arr.shift()]));
            return obj;
        }

        const { orderData } = this.props
        let newArrayObj
        if (this.state.activeRadio === 'all') {
            newArrayObj = this.props.containerSummaryData
        } else {
            newArrayObj = this.props.containerSummaryData.filter((data) => data.status === this.state.activeRadio)
        }

        //if SearchTerm is Empty return original data.
        if (!searchTerm) {
            return this.setState({ tableData: newArrayObj })
        }
        let newArray = []
        newArrayObj.forEach((rowItem, i) => {
            searchKeys.forEach(keys => {
                let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
                let isFound =
                    rowItemKeys &&
                    (rowItemKeys.toString().toLowerCase().replace(/[$,]/g, "").includes(searchTerm.toString().toLowerCase().replace(/[$,]/g, "")) ||
                        rowItemKeys.toString().toLowerCase().includes(searchTerm.toString().toLowerCase()));
                if (isFound) newArray.push(rowItem)
            })
        })
        //Removed Duplicate Entry in Array.
        const newArrayElement = newArray.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        })
        this.setState({ tableData: newArrayElement })
    }

    showApprovals = () => {
        this.setState({ isApprovalsOpen: true });
    };

    hideApprovals = () => {
        this.setState({ isApprovalsOpen: false });
    };

    handleApproveRequest = (notifId) => {
        const { approveRequest } = this.props;

        this.setState({ isSendingResponse: true });

        approveRequest(notifId).then((response) => {
            if (!response.error) {
                this.setState({
                    showApprovalsModal: false,
                    isSendingResponse: false,
                });
            } else {
                this.setState({
                    showApprovalsModal: false,
                    isSendingResponse: false,
                });
            }
            this.props.fetchNotifications();
            this.props.history.push("/container-dashboard");
        });
    };

    handleRejectRequest = (notifId) => {
        const { rejectRequest } = this.props;

        this.setState({ isSendingResponse: true });

        rejectRequest(notifId).then((response) => {
            if (!response.error) {
                this.setState({
                    showApprovalsModal: false,
                    isSendingResponse: false,
                });
            } else {
                this.setState({
                    showApprovalsModal: false,
                    isSendingResponse: false,
                });
            }
            this.props.fetchNotifications();
            this.props.history.push("/container-dashboard");
        });
    };

    // matchDataFromPendingUrl = (transNo, poRef) => {
    //     const { urlTransactionNo, urlPoRef } = this.state;
    //     if (urlTransactionNo === transNo && urlPoRef === poRef) {
    //         return (
    //             <img
    //                 src={activeBrown}
    //                 alt="active"
    //                 style={{
    //                     width: "0.7rem",
    //                     height: "0.7rem",
    //                     marginRight: "10px",
    //                 }}
    //             />
    //         );
    //     }
    // };

    handleClickRate = (rate) => {
        this.setState({ activeRate: rate, showQuestion: true });
    };

    handleFeedbackSubmit = (values) => {
        const { activeRate, distributorOrderId } = this.state;
        const { comments } = values;
        const { distributor, postFeedback } = this.props;

        if (!activeRate) {
            message.error("Please input your feedback");
            return;
        }

        const data = {
            po_date: moment().format("YYYY-MM-DD"),
            distributor_id: distributor.data.distributor_id,
            comments: comments,
            feedback_score: activeRate,
            feedback_type: "order fulfillment",
            distributor_order_id: parseInt(moment().format('YMMDDhhss')),
        };

        this.setState({ isPostingFeedback: true });

        postFeedback(data).then((response) => {
            if (!response.error) {
                this.setState({
                    activeRate: null,
                    showThankYouModal: true,
                    showFeedbackModal: false,
                    isPostingFeedback: false,
                });
            } else {
                this.setState({
                    activeRate: null,
                    showFeedbackModal: false,
                    isPostingFeedback: false,
                });
            }
            this.props.history.push("/container-dashboard");
        });
    };

    getTotal = (status) => {
        if (status === 'all') {
            return this.props.containerSummaryData.length
        } else {
            return this.props.containerSummaryData.filter((data) => data.status === status).length
        }
    }


    render() {

        const urlParams = new URLSearchParams(window.location.search);

        // rowSelection object indicates the need for row selection
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys: selectedRowKeys });
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        // const downloadData = this.state.tableData.map(value => {
        //     return {
        //         created_at: value.created_at,
        //         so_number: value.so_number,
        //         po_number: value.po_number,
        //         status: value.status,
        //         actual_stocks: value.actual_stocks,
        //         amount: value.amount,
        //         cbm: value.cbm,
        //         gross_weight: value.gross_weight
        //     }
        // })


        const {
            showDownloadModal,
            checkedList,
            selectedRowKeys,
            activeRate,
            isPostingFeedback,
            showThankYouModal,
            showFeedbackModal,
            showStatusUpdateModal,
            tableData,
            showApprovalsModal,
            isSendingResponse
        } = this.state
        const { distributor,
            myPermissions,
            myPermissionsFetching,
            handleSubmit
        } = this.props

        if (myPermissionsFetching) {
            return (
                <Layout>
                    <Spin spinning={myPermissionsFetching}>
                        <Skeleton active />
                    </Spin>
                </Layout>
            );
        }

        let { notifications } = this.state;
        notifications = notifications ? notifications : [];

        tableData.sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix())

        let downloadDataArray = []

        tableData.map(data => {
            selectedRowKeys.map(rowKey => {
                if (data.so_number === rowKey) {
                    downloadDataArray.push(data)
                }
            })
        })

        const pendingApprovals = notifications.filter((item) => {
            return (
                item.notif_type === "sku replacement" &&
                item.response === null
            );
        });

        const pendingApprovalsCount = pendingApprovals.length;

        const previousApprovals = notifications.filter((item) => {
            return (
                item.notif_type === "sku replacement" &&
                item.response !== null &&
                item.distributor_id === distributor.data.distributor_id
            );
        });

        const previousApprovalsCount = previousApprovals.length;

        const content = (
            <div>
                <p>
                    <strong>{pendingApprovalsCount}</strong> pending {pendingApprovalsCount === 1 ? 'approval' : 'approvals'} here!
                </p>
            </div>
        );


        const columns = [
            {
                title: 'CREATED DATE',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: (a, b) => {
                    const x = a.created_at.toLowerCase();
                    const y = b.created_at.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                },
                render: text => text,
            },
            {
                title: 'SO NUMBER',
                dataIndex: 'so_number',
                key: 'so_number',
                sorter: (a, b) => {
                    const x = a.so_number.toLowerCase();
                    const y = b.so_number.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                },
                render: (text, data) => <Link to={`/so-list?so_number=${text}&created_date=${data.created_at}`}>{text}</Link>,
            },
            {
                title: 'CUSTOMER PO NUMBER',
                dataIndex: 'customer_po_number',
                key: 'customer_po_number',
                sorter: (a, b) => {
                    const x = a.customer_po_number.toLowerCase();
                    const y = b.customer_po_number.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                },
                // render: text => text,
                render: (text, data) => <Link to={`/so-list?so_number=${data.so_number}&created_date=${data.created_at}`}>{data.customer_po_number}</Link>,
            },
            {
                title: 'STATUS',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                sorter: (a, b) => {
                    const x = a.status.toLowerCase();
                    const y = b.status.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                },
                render: text => <div className='table-status'>
                    <div className={`${text === 'invoiced' ? 'invoiced' : 'inProcess'}-status`}><span>{text === 'invoiced' ? 'INVOICED' : 'IN PROCESS'}</span></div>
                </div>,
            },
            {
                title: 'ACTUAL STOCKS',
                dataIndex: 'actual_stocks',
                key: 'actual_stocks',
                align: 'right',
                sorter: (a, b) => {
                    const x = a.actual_stocks;
                    const y = b.actual_stocks;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                },
                render: text => text.toLocaleString(undefined),
            },
            {
                title: 'AMOUNT (IN USD)',
                dataIndex: 'amount',
                key: 'amount',
                align: 'right',
                sorter: (a, b) => {
                    const x = a.amount;
                    const y = b.amount;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                },
                render: text => `$${text.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
            },
            {
                title: 'CBM',
                dataIndex: 'cbm',
                key: 'cbm',
                align: 'right',
                sorter: (a, b) => {
                    const x = a.cbm;
                    const y = b.cbm;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                },
                render: text => (text || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
            {
                title: 'GROSS WEIGHT',
                dataIndex: 'gross_weight',
                key: 'gross_weight',
                align: 'right',
                sorter: (a, b) => {
                    const x = a.gross_weight;
                    const y = b.gross_weight;
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                },
                render: text => (text || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
            {
                title: 'INVOICED DATE',
                dataIndex: 'billing_creation_date',
                key: 'billing_creation_date',
                sorter: (a, b) => {
                    const x = a.billing_creation_date.toLowerCase();
                    const y = b.billing_creation_date.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                },
                render: (text, data) => {
                    return (
                        <span style={{ fontWeight: 600, color: data.status === 'invoiced' ? "#028010" : '#D66F0E' }}>{text}</span>
                    )
                },
            },
        ];
        return (
            <Layout className='summary-container'>
                {myPermissions.some(r => r.name === 'distributor.orders.view') ?
                    <Layout.Content>
                        <Row className='summary-header-first'>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <h1>Container Summary Breakdown</h1>
                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <Row className='summary-header-second'>
                                    <Col md={8} sm={24} xs={24}>
                                        <div className='total-text'>
                                            <h1>TOTAL ORDERS  <span>{this.getTotal('all')}</span></h1>
                                        </div>
                                    </Col>
                                    <Col md={8} sm={24} xs={24}>
                                        <div className='total-text'>
                                            <h1>TOTAL IN PROCESS  <span>{this.getTotal('in process')}</span></h1>
                                        </div>
                                    </Col>
                                    <Col md={8} sm={24} xs={24}>
                                        <div className='total-text'>
                                            <h1>TOTAL INVOICED <span>{this.getTotal('invoiced')}</span></h1>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='summary-filter' gutter={12}>
                            <Col sm={24} xs={24} md={6} lg={6}>
                                <Search
                                    placeholder="Search"
                                    onSearch={value => this.handleSearch(value, [
                                        'created_at',
                                        'created_at_whole',
                                        'so_number',
                                        'customer_po_number',
                                        'gross_weight',
                                        // 'status',
                                        'amount',
                                        'cbm',
                                        'actual_stocks',
                                    ])}
                                    style={{ width: '100%', marginBottom: 10 }}
                                />
                            </Col>
                            <Col sm={24} xs={24} md={4} lg={4}>
                                <Select style={{ width: '100%', marginBottom: 10 }} defaultValue="All" onChange={(e) => this.handleFilterBy(e)} showSearch>
                                    <Option value="all">All</Option>
                                    <Option value="created_at">Created Date</Option>
                                    <Option value="so_number">SO Number</Option>
                                    <Option value="po_number">PO Number</Option>
                                    <Option value="actual_stocks">Actual Stocks</Option>
                                    <Option value="amount">Amount ( IN USD) </Option>
                                    <Option value="cbm">CBM</Option>
                                    <Option value="gross_weight">Gross Weight</Option>
                                </Select>
                            </Col>

                            <Col md={14} lg={14} xs={24} sm={24} style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                <Row style={{ width: '100%' }} gutter={4}>
                                    <Col sm={24} xs={24} md={8} lg={8} className='extract-btn'>
                                        <Button
                                            className="btn-small btn-secondary-gold"
                                            style={{ width: '100%', marginBottom: 10 }}
                                            disabled={selectedRowKeys.length > 0 && myPermissions.some(
                                                (r) => r.name === "distributor.orders.download"
                                            ) ? false : true}
                                            onClick={() =>
                                                this.setState({ showDownloadModal: true })
                                            }
                                        >
                                            <Icon type="line-chart" />Extract Multiple POs
                                    </Button>
                                    </Col>
                                    <Col sm={24} xs={24} md={8} lg={8}>
                                        <Button
                                            className="btn-small btn-primary"
                                            onClick={() =>
                                                this.props.history.push("/order-status")
                                            }
                                            style={{ width: "100%", marginBottom: 10 }}
                                        >
                                            <Icon type="layout" />Order History
                                    </Button>
                                    </Col>
                                    <Col sm={24} xs={24} md={8} lg={8}>
                                        <Button
                                            className="btn-small btn-primary"
                                            onClick={() =>
                                                this.props.history.push("/summary-dashboard")
                                            }
                                            style={{ width: "100%", marginBottom: 10 }}
                                        >
                                            <Icon type="layout" />OMS Summary Dashboard
                                    </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row style={{ margin: '15px 0' }}>
                            <Col sm={24} xs={24} md={24} lg={24} className='filter-radio'>
                                <label className="radio">ALL
                                <input type="radio" name="volume" checked={this.state.activeRadio === 'all'} value="all" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="radio">IN PROCESS
                                <input type="radio" name="volume" checked={this.state.activeRadio === 'in process'} value="in process" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="radio">INVOICED
                                <input type="radio" name="volume" checked={this.state.activeRadio === 'invoiced'} value="invoiced" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="radio">CANCELLED
                                <input type="radio" name="volume" checked={this.state.activeRadio === 'cancelled'} value="cancelled" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                    <span className="checkmark"></span>
                                </label>
                            </Col>
                        </Row>


                        <Row className='summary-table'>
                            <Table
                                rowSelection={rowSelection}
                                className="pb-parent-columns"
                                loading={this.props.containerBreakdownLoading}
                                columns={columns}
                                dataSource={tableData}
                                rowKey="so_number"
                                onRowClick={(r, index) => {
                                    this.props.history.push(`/so-list?so_number=${r.so_number}&created_date=${r.created_at}&customer_po_number=${r.customer_po_number}&billing_creation_date=${r.billing_creation_date}&status=${r.status}`)
                                }}
                            />
                        </Row>
                    </Layout.Content>

                    : <Result
                        status="warning"
                        title="You have no permission to view this page."
                    />
                }
                <Modal
                    title="Download Option"
                    visible={showDownloadModal}
                    width={350}
                    onCancel={() => this.setState({ showDownloadModal: false })}
                    footer={null}
                    className="download-option-modal"
                >
                    <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        All
                    </Checkbox>
                    <br />
                    <CheckboxGroup
                        options={plainOptions}
                        value={checkedList}
                        onChange={this.onChange}
                    />
                    <Row>
                        <Col span={24}><Button type="link" onClick={() => this.setState({ checkedList: [], indeterminate: false, checkAll: false })}>Clear All</Button></Col>
                    </Row>

                    <Row type="flex" style={{ margin: "10px 0px" }}>
                        <Col span={12}><Button className="btn-small btn-default" onClick={() => this.setState({ showDownloadModal: false, checkedList: [], indeterminate: false, checkAll: false })}>Cancel</Button></Col>
                        <Col span={12}><Button className="btn-small btn-primary"
                            //  disabled={checkedList.length < 1} 
                            onClick={() => this.handleDownload(downloadDataArray)}>Download</Button></Col>
                    </Row>
                </Modal>

                {/* ORDER RECONFIG SECTION */}
                <Modal
                    title="Your Approval is needed on these items"
                    visible={showApprovalsModal}
                    onCancel={() => this.setState({ showApprovalsModal: false })}
                    width={"55%"}
                    footer={null}
                    className="approvals-modal"
                >
                    <Collapse
                        accordion
                        className="approvals-accordion"
                        defaultActiveKey={this.state.isApproveType}
                    >
                        <Panel
                            header={`Pending Approvals (${pendingApprovalsCount})`}
                            key="1"
                            className="panel"
                        >
                            {pendingApprovals.map((approval) => {
                                return (
                                    <div className="approvals">
                                        <form>
                                            <Row
                                                type="flex"
                                                style={{ margin: "10px 0px" }}
                                                justify="space-between"
                                                align="center"
                                            >
                                                <Col
                                                    span={24}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "top",
                                                    }}
                                                >
                                                    <p
                                                        className="so-number"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        SO NUMBER:{" "}&nbsp;
                                    <Link
                                                            to={`/so-list?so_number=${approval.progress}&created_date=${moment(approval.created_at).format('MM/DD/YYYY')}`}
                                                        >
                                                            {approval.progress}
                                                        </Link>
                                                    </p>

                                                </Col>
                                                <Col className="" span={12}>
                                                    <p>{approval.description}</p>
                                                    <span className="date">
                                                        {moment(approval.updated_at).fromNow()}
                                                    </span>
                                                </Col>
                                                <Col
                                                    className="buttons"
                                                    style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    <Button
                                                        className="btn-small btn-secondary"
                                                        onClick={() =>
                                                            this.handleApproveRequest(approval.id)
                                                        }
                                                        loading={isSendingResponse}
                                                    >
                                                        {isSendingResponse ? null : "Yes"}
                                                    </Button>
                                                    <Button
                                                        className="btn-small btn-secondary"
                                                        onClick={() =>
                                                            this.handleRejectRequest(approval.id)
                                                        }
                                                        loading={isSendingResponse}
                                                    >
                                                        {isSendingResponse ? null : "No"}
                                                    </Button>
                                                </Col>

                                                <Divider />
                                            </Row>
                                        </form>
                                    </div>
                                );
                            })}
                        </Panel>
                        <Panel
                            header={`Show Previous Approvals (${previousApprovalsCount})`}
                            key="2"
                        >
                            {previousApprovals.map((approval) => {
                                return (
                                    <Row type="flex" style={{ margin: "10px 0px" }}>
                                        <Col span={15}>
                                            <p
                                                className="so-number"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                SO NUMBER:{" "}&nbsp;
                                                     <Link
                                                    to={`/so-list?so_number=${approval.progress}&created_date=${moment(approval.created_at).format('MM/DD/YYYY')}`}
                                                >
                                                    {approval.progress}
                                                </Link>
                                            </p>
                                            <p>{approval.description}</p>
                                            <span className="date">
                                                {moment(approval.updated_at).fromNow()}
                                            </span>
                                        </Col>
                                        <Col
                                            className="responses"
                                            style={{
                                                display: "flex",
                                                flex: 1,
                                                justifyContent: "center",
                                            }}
                                        >
                                            {approval.response === "yes" ? (
                                                <span className="approved">Approved</span>
                                            ) : (
                                                    <span className="not-approved">Not Approved</span>
                                                )}
                                        </Col>
                                        <Divider />
                                    </Row>
                                );
                            })}
                        </Panel>
                    </Collapse>
                </Modal>

                {this.state.isApprovalsOpen ? (
                    pendingApprovalsCount ? (
                        <div className="approvals-container" style={{ zIndex: 99999 }}>
                            <Row type="flex">
                                <Col
                                    className="approvals-description"
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <p>
                                        <span>
                                            Looks like you have some approvals to take care of!
                                <br />
                                            {pendingApprovalsCount}
                                        </span>{" "}
                            Pending{" "}
                                        {pendingApprovalsCount > 1 ? "Approvals" : "Approval"}
                                    </p>
                                </Col>
                                <Col
                                    className="buttons-column"
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        className="btn-small btn-secondary"
                                        onClick={() =>
                                            this.setState({ showApprovalsModal: true })
                                        }
                                    >
                                        Check Approval
                            </Button>
                                    <Button
                                        className="btn-small btn-default"
                                        onClick={this.hideApprovals}
                                    >
                                        Cancel
                            </Button>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                            <div className="approvals-container" style={{ zIndex: 99999 }}>
                                <Row type="flex">
                                    <Col
                                        className="approvals-description"
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        <p>
                                            <span>
                                                You have no approvals to take care of.
                            <br />
                                                {pendingApprovalsCount}
                                            </span>{" "}
                        Pending{" "}
                                            {pendingApprovalsCount > 1 || pendingApprovalsCount === 0 ? "Approvals" : "Approval"}
                                        </p>
                                    </Col>
                                    <Col
                                        className="buttons-column"
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Button
                                            className="btn-small btn-secondary"
                                            onClick={() =>
                                                this.setState({ showApprovalsModal: true })
                                            }
                                        >
                                            Check Approval
                        </Button>
                                        <Button
                                            className="btn-small btn-default"
                                            onClick={this.hideApprovals}
                                        >
                                            Cancel
                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )
                ) : (
                        <Popover
                            content={content}
                            trigger="hover"
                            className="approvals-hover"
                        >
                            <div className="approvals-widget" onClick={this.showApprovals}>
                                <Badge
                                    count={
                                        pendingApprovalsCount !== undefined && pendingApprovalsCount
                                    }
                                    style={{ background: "#774625" }}
                                >
                                    <Icon
                                        type="container"
                                        style={{ fontSize: "1.2rem", color: "#774625" }}
                                    />
                                </Badge>
                            </div>
                        </Popover>
                    )}



                <Modal
                    className="feedback-modal"
                    title={<span>We'd like to get your feedback</span>}
                    visible={showFeedbackModal}
                    onCancel={() => {
                        this.setState({ showFeedbackModal: false });
                        this.props.history.push("/container-dashboard");
                    }}
                    footer={null}
                    maskClosable={false}
                    closable={false}
                >
                    <h3>How was your overall order experience?</h3>
                    <Row
                        type="flex"
                        justify="space-between"
                        align="middle"
                        className="feedback-rate"
                    >
                        {feedbackEmoji.sort((a, b) => b.rate - a.rate).map((value, i) => (
                            <Col
                                key={i}
                                onClick={() => this.handleClickRate(value.rate)}
                                className={value.rate === activeRate ? "active" : ""}
                            >
                                <img alt={value.rate} src={value.svg} />
                            </Col>
                        ))}
                    </Row>
                    {/* {showQuestion ? ( */}
                    <form>
                        <Row className="feedback-question">
                            <Col span={24}>
                                <h3>Any comments/suggestions?</h3>
                                <div className="form-label">
                                    <Field
                                        name={`comments`}
                                        className="inputStyle"
                                        component={renderInput}
                                        validate={feedbackValidator}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" justify="end" className="feedback-button">
                            <Col>
                                <Button
                                    htmlType="submit"
                                    style={{ width: "192px" }}
                                    className="btn-small btn-primary"
                                    loading={isPostingFeedback}
                                    onClick={handleSubmit((formData) => {
                                        this.handleFeedbackSubmit(formData);
                                    })}
                                >
                                    {isPostingFeedback ? null : "Send Feedback Form"}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    {/* ) : null} */}
                </Modal>

                <Modal
                    className="feedback-modal"
                    title={<span>Feedback Received!</span>}
                    visible={showThankYouModal}
                    onCancel={() => this.setState({ showThankYouModal: false })}
                    footer={null}
                    closable={false}
                >
                    <h3>Thank you for your feedback!</h3>
                    <p>
                        Rest assured that we are continuously enhancing your order
                        experience with us.
                    </p>
                    <Row type="flex" justify="end" className="feedback-button">
                        <Col span={24} align="right">
                            <Button
                                style={{ margin: 0, minWidth: "80px" }}
                                className="btn-small btn-primary"
                                onClick={() => this.setState({ showThankYouModal: false })}
                            >
                                Ok
                            </Button>
                        </Col>
                    </Row>
                </Modal>

                <Modal
                    className="feedback-modal"
                    title={<span>Order Status Updated</span>}
                    visible={showStatusUpdateModal}
                    onCancel={() => {
                        this.setState({ showStatusUpdateModal: false });
                        this.props.history.push("/order-status");
                    }}
                    footer={null}
                    maskClosable={false}
                    closable={false}
                >
                    <h3>
                        Your order {urlParams.get("po_number") ? urlParams.get("po_number") : ''} was already INVOICED!
                    </h3>
                    <p>
                        We'd like to get your feedback on your order experience with us.
                        Please click the Feedback Form button below.
                    </p>

                    <Row type="flex" justify="end" className="feedback-button">
                        <Col>
                            <Button
                                htmlType="submit"
                                className="btn-small btn-primary"
                                onClick={() =>
                                    this.setState({
                                        showFeedbackModal: true,
                                        showStatusUpdateModal: false,
                                    })
                                }
                            >
                                Feedback Form
                            </Button>
                        </Col>
                    </Row>
                </Modal>

            </Layout>
        )
    }
}


const mapStateToProps = state => {
    const { notifications, distributor, permissions } = state;

    return {
        myPermissions: permissions.data,
        myPermissionsFetching: permissions.fetching,
        containerSummaryData: state.containerSummary.containerBreakdownData,
        containerBreakdownLoading: state.containerSummary.containerBreakdownLoading,
        distributor,
        notificationsData: notifications,
        notificationsFetching: notifications.fetching,
    };
};

export default connect(mapStateToProps, {
    fetchPermissions,
    fetchContainerSummaryBreakdown,
    fetchDistributor,
    fetchNotifications,
    approveRequest,
    rejectRequest,
    postFeedback
})(reduxForm({ form: "container-summary", enableReinitialize: true })(ContainerSummary));