import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getCode } from "country-list";
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  reset,
  change,
} from "redux-form";
import * as _ from "lodash";
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  List,
  Card,
  Button,
  Modal,
  Spin,
  Icon,
  message,
  Skeleton,
  Result,
} from "antd";
import MediaQuery from "react-responsive";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as lunr from "lunr";
import DocumentMeta from "react-document-meta";
import moment from "moment";

import config from "../../config";
import {
  fetchProducts,
  fetchProductPriceList,
  fetchDrafts,
  fetchDistributor,
  createProductOrder,
  fetchPermissions,
  saveAsDraft,
  fetchPoRefNo,
  postPoRefNo,
  postFeedback,
  fetchDraftById,
  isCustomerPoNoUnique,
} from "../../actions";
import FormField, {
  minValue,
  number,
  isChar,
  required,
} from "../shared/FormField";

import ModalCsvUpload from "./Modals/csvUpload";
import ModalDeliveryDetails from "./Modals/deliveryDetails";
import warningIcon from "../../assets/icons/warning-icon.png";
import swap from "../../assets/icons/swap-dark.svg";
import dots from "../../assets/icons/dots.svg";
import arrow from "../../assets/icons/arrow.svg";

import rate1 from "../../assets/svg/rate-1.svg";
import rate2 from "../../assets/svg/rate-2.svg";
import rate3 from "../../assets/svg/rate-3.svg";
import rate4 from "../../assets/svg/rate-4.svg";
import rate5 from "../../assets/svg/rate-5.svg";
import error from "../../assets/icons/error-icon.png";
import success from "../../assets/icons/check-icon.png";
import { numFormat, country } from "../../utilities/format";
import { numberFormat } from "../../utilities";

import "./Order.scss";
import ColumnGroup from "antd/lib/table/ColumnGroup";

const { ExclamationCircleOutlined } = Icon;

const feedbackEmoji = [
  {
    rate: 1,
    svg: rate1,
  },
  {
    rate: 2,
    svg: rate2,
  },
  {
    rate: 3,
    svg: rate3,
  },
  {
    rate: 4,
    svg: rate4,
  },
  {
    rate: 5,
    svg: rate5,
  },
];

const makePONumber = (distributor, newPad) => {
  return `${distributor &&
    distributor.distributor &&
    distributor.distributor.customer_code &&
    distributor.distributor.name
    ? String(distributor.distributor.customer_code) === "300026"
      ? "UNO"
      : String(distributor.distributor.customer_code) === "300028"
        ? "APO"
        : String(distributor.distributor.customer_code) === "300017"
          ? "SFC"
          : String(distributor.distributor.customer_code) === "300010"
            ? "MAN"
            : String(distributor.distributor.customer_code) === "300064"
              ? "CAS"
              : String(distributor.distributor.customer_code) === "300166"
                ? "DHN"
                : String(distributor.distributor.customer_code) === "300029"
                  ? "GIA"
                  : String(distributor.distributor.customer_code) === "300367"
                    ? "DNQ"
                    : String(distributor.distributor.customer_code) === "300378"
                      ? "MPD"
                      : String(distributor.distributor.customer_code) === "300085"
                        ? "MTK"
                        : String(distributor.distributor.customer_code) === "300031"
                          ? "SKL"
                          : distributor.distributor.name
                            .replace(/\s+/g, "")
                            .substring(0, 3)
                            .toUpperCase()
    : ""
    }-${moment().format("YY")}-${newPad}`;
};

const renderDatepicker = (field) => {
  const {
    input,
    label,
    etdMonth,
    meta: { touched, error, warning },
  } = field;
  return (
    <div style={{ height: 50 }}>
      <DatePicker
        {...input}
        {...field}
        placeholderText={label}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        minDate={moment().add(etdMonth, "M").startOf("month").toDate()}
      />
      {touched &&
        ((error && <span style={{ color: "red" }}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
};

const renderInput = ({
  input,
  label,
  type,
  className,
  readOnly,
  meta: { touched, error, warning },
}) => (
    <div style={{ height: 50 }}>
      <input
        {...input}
        className={className}
        placeholder={label}
        readOnly={readOnly}
        type={type}
      />
      {touched &&
        ((error && <span style={{ color: "red" }}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );

const renderSelectField = ({
  input,
  className,
  defaultValue,
  meta: { touched, error },
  children,
}) => {
  return (
    <div style={{ height: 50 }}>
      <select
        {...input}
        className={className}
        defaultValue={defaultValue}
        style={{
          color:
            input.value === null || input.value === "" ? "#B2B2B2" : "#404040",
        }}
        value={input.value}
      >
        {children}
      </select>
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  );
};

const qtyValidators = [
  // required('required'),
  minValue(50, "Min 50 required"),
  number("Required Whole Number"),
  isChar("Should not contain special characters"),
];
const formValidators = (value) => (value ? undefined : "Required");
const numberValidator = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

const orderProductList = ({
  fields,
  products,
  formValues,
  resetForm,
  validate = { formValidators },
  handleSaveAsDraft,
  myPermissions,
  productOrderLoading,
  productsIsFetching,
  priceListIsFetching,
  isDraftLoading
}) => {
  const totals = { cbm: 0, price: 0, quantity: 0, grossWeight: 0 };
  const orderedProducts = _.filter(
    formValues.orders,
    (order) => parseInt(order.quantity) > 0
  );
  if (orderedProducts.length > 0) {
    totals.cbm = _.chain(orderedProducts)
      .map(
        (product) => (product.cbm || 0) * parseInt(product.quantity).toFixed(2)
      )
      .sum()
      .value();
    totals.price = _.chain(orderedProducts)
      .map(
        (product) => (product.selling_price || 0) * parseInt(product.quantity)
      )
      .sum()
      .value();
    totals.grossWeight = _.chain(orderedProducts)
      .map(
        (product) => (product.gross_weight || 0) * parseInt(product.quantity)
      )
      .sum()
      .value();
    totals.quantity = _.chain(orderedProducts)
      .map((product) => parseInt(product.quantity))
      .sum()
      .value();
  }

  const handleQty = (e) => {
    if (e === null) {
    }
  };


  // products.map((data, i) => data[i].variant.status === 1)

  return (
    <List
      loading={productsIsFetching || priceListIsFetching}
      bordered
      itemLayout="horizontal"
      dataSource={
        products && formValues.orders && formValues.orders.length > 0
          ? fields
            .map((field) => field)
            .sort(
              (a, b) =>
                moment(b.created_at).unix() - moment(a.created_at).unix()
            )
          : []
      }
      renderItem={(field) => {
        // const { product_id, product_variant_id, selling_price, cbm } = _.get(formValues, field)
        // const item = _.find(products, {product_id, product_variant_id})
        // const {product: {id, name, brand = {name: null}, country_made, image}, variant = {variants: null, packing_scheme: null}} = item
        const { product_id, product_variant_id } = _.get(formValues, field);

        // const item = _.find(products, { product_id, product_variant_id });
        // const item = _.find(_.filter(products, function(product) { return product && product.variant && product.variant.status === 1 && product.variant.status !== null }), { product_id, product_variant_id });
        const item = _.find(_.filter(_.filter(products, (product) => { return product && product.selling_price !== 0 }), (product) => { return product && product.variant !== null }), { product_id, product_variant_id });

        // products.filter((data) => data.variant[0].status ===0)

        // item.filter(data => data.status !== 0)

        const inList = orderedProducts.filter((orderedProducts) => {
          return item !== undefined
            ? orderedProducts.product_variant_id === item.product_variant_id
            : "";
        });

        if (!item) return <Fragment />;

        const { id, brand = { name: null } } = item.product;

        return (
          <List.Item className="product">
            <MediaQuery minWidth={1224}>
              <Row
                gutter={24}
                type="flex"
                align="middle"
                justify="center"
                className="product-list-row"
              >
                <Col span={8} className="product-information">
                  <span
                    className="product-image"
                    style={{
                      backgroundImage: `url(${item && item.variant && item.variant.image
                        ? `${config.REACT_APP_FILES_URL}thumbs_${item.variant.image}`
                        : "https://via.placeholder.com/100x100?text=No+Image"
                        })`,
                    }}
                  />
                  <span className="product-id">
                    <h5>{brand.name}</h5>
                    <h3>{`${item && item.product && item.product.name} ${item && item.variant &&
                      item.variant.variants && item.variant.variants.flavor
                      ? item.variant.variants.flavor.toLowerCase()
                      : ""
                      }`}</h3>
                    <span>
                      {item.special_code !== null
                        ? item.special_code
                        : item && item.variant && item.variant.material_code}
                    </span>
                  </span>
                </Col>
                <Col span={3}>{`${item && item.variant && item.variant.packing_scheme || "N/A"}`}</Col>
                <Col span={1}>{getCode(item.product.country_made)}</Col>
                <Col span={3} align="right">
                  $ {numFormat("dot/comma", item.selling_price) || "0.00"}
                </Col>
                <Col span={2} align="right">
                  {inList.length > 0
                    ? numFormat(
                      "dot/comma",
                      item.variant.gross_weight *
                      inList.map((value) => {
                        return value.quantity;
                      }) || 0
                    )
                    : "0.00" || "0.00"}
                </Col>
                <Col span={2} align="right">
                  {inList.length > 0
                    ? numFormat(
                      "dot/comma",
                      item.variant.cbm *
                      inList.map((value) => {
                        return value.quantity;
                      })
                    )
                    : "0.00" || "0.00"}
                </Col>
                <Col span={2} align="right">
                  $
                  {inList.length > 0
                    ? numFormat(
                      "dot/comma",
                      item.selling_price *
                      inList.map((value) => {
                        return value.quantity;
                      })
                    )
                    : "0.00" || "0.00"}
                </Col>
                <Col span={3} align="right" className="order-form-qty-md">
                  <Field
                    id={`${id}_${item.product_variant_id}`}
                    type="number"
                    name={`${field}.quantity`}
                    min={0}
                    step={10}
                    component={FormField}
                    validate={qtyValidators}
                    onChange={handleQty}
                    disabled={
                      !myPermissions.some(
                        (r) => r.name === "distributor.orders.create"
                      )
                    }
                  />
                </Col>
              </Row>
            </MediaQuery>
            <MediaQuery maxWidth={1224} minWidth={601}>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                className="product-info-mobile"
              >
                <Col span={24}>
                  <Row type="flex" justify="space-between" align="top">
                    <Col span={15}>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={9}>
                          <span
                            className="product-image"
                            style={{
                              backgroundImage: `url(${item && item.variant && item.variant.image
                                ? `${config.REACT_APP_FILES_URL}thumbs_${item.variant.image}`
                                : `"https://via.placeholder.com/100x100?text=No+Image"`
                                })`,
                            }}
                          />
                        </Col>
                        <Col span={15}>
                          <label>{brand.name}</label>
                          <br />
                          <span className="product-name">
                            {item && item.variant && item.variant.name.toLowerCase()}
                          </span>
                          <br />
                          <div className="spe-code">
                            {item.special_code !== null
                              ? item.special_code
                              : item && item.variant && item.variant.material_code}
                            <br />
                            {item.product.country_made
                              ? getCode(item.product.country_made)
                              : "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8} offset={1}>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={24}>
                          <label>PACKING SCHEME</label> <br />
                          <span className="packing-scheme">{`   ${item.variant && item.variant.packing_scheme
                            ? item.variant.packing_scheme.toLowerCase()
                            : ""
                            }  `}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row type="flex" justify="space-between" align="top">
                    <Col span={4}>
                      <label>AMOUNT/CASE</label> <br />
                      <span>
                        $&nbsp;
                        {numFormat("dot/comma", item.selling_price) || "0.00"}
                      </span>
                    </Col>
                    <Col span={4}>
                      <label>GROSS WT.</label> <br />
                      <span>
                        {inList.length > 0
                          ? numFormat(
                            "dot/comma",
                            item.variant.gross_weight *
                            inList.map((value) => {
                              return value.quantity;
                            }) || 0
                          )
                          : "0.00" || "0.00"}
                      </span>
                    </Col>
                    <Col span={4}>
                      <label>CBM</label> <br />
                      <span className="cbm">
                        {inList.length > 0
                          ? numFormat(
                            "dot/comma",
                            item.variant.cbm *
                            inList.map((value) => {
                              return value.quantity;
                            })
                          )
                          : "0.00" || "0.00"}
                      </span>
                    </Col>
                    <Col span={8}>
                      <label>ORDERED AMOUNT (IN USD)</label> <br />
                      <span className="cbm">
                        $
                        {inList.length > 0
                          ? numFormat(
                            "dot/comma",
                            item.selling_price *
                            inList.map((value) => {
                              return value.quantity;
                            })
                          )
                          : "0.00" || "0.00"}
                      </span>
                    </Col>

                    <Col span={4}>
                      <label>QTY.</label>
                      <br />
                      <div className="order-form-qty">
                        <Field
                          id={`${id}_${item.product_variant_id}`}
                          type="number"
                          name={`${field}.quantity`}
                          min={0}
                          step={10}
                          component={FormField}
                          validate={qtyValidators}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </MediaQuery>
            {/* mobile max wight 600 */}
            <MediaQuery maxWidth={600}>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                className="product-info-mobile"
              >
                <Col span={24}>
                  <Row type="flex" justify="space-between" align="top">
                    <Col span={6}>
                      <span
                        className="product-image"
                        style={{
                          backgroundImage: `url(${item && item.variant && item.variant.image
                            ? `${config.REACT_APP_FILES_URL}thumbs_${item.variant.image}`
                            : `"https://via.placeholder.com/100x100?text=No+Image"`
                            })`,
                        }}
                      />
                    </Col>
                    <Col span={18}>
                      <label>{brand.name}</label>
                      <br />
                      <span className="product-name">
                        {item && item.variant && item.variant.name.toLowerCase()}
                      </span>
                      <br />
                      <div className="spe-code">
                        {item.special_code !== null
                          ? item.special_code
                          : item && item.variant && item.variant.material_code}
                        &nbsp;&nbsp;
                        {item.product.country_made
                          ? getCode(item.product.country_made)
                          : "N/A"}
                      </div>
                      <br />
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={16}>
                          <label>PACKING SCHEME</label>
                        </Col>
                        <Col span={8}>
                          <span className="packing-scheme">{`   ${item.variant && item.variant.packing_scheme
                            ? item.variant.packing_scheme.toLowerCase()
                            : ""
                            }  `}</span>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={16}>
                          <label>AMOUNT/CASE</label>
                        </Col>
                        <Col span={8}>
                          <span>
                            $&nbsp;
                            {numFormat("dot/comma", item.selling_price) ||
                              "0.00"}
                          </span>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={16}>
                          <label>GROSS WT.</label>
                        </Col>
                        <Col span={8}>
                          <span>
                            {inList.length > 0
                              ? numFormat(
                                "dot/comma",
                                item.variant.gross_weight *
                                inList.map((value) => {
                                  return value.quantity;
                                }) || 0
                              )
                              : "0.00" || "0.00"}
                          </span>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={16}>
                          <label>CBM</label>
                        </Col>
                        <Col span={8}>
                          <span className="cbm">
                            {inList.length > 0
                              ? numFormat(
                                "dot/comma",
                                item.variant.cbm *
                                inList.map((value) => {
                                  return value.quantity;
                                })
                              )
                              : "0.00" || "0.00"}
                          </span>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={16}>
                          <label>ORDERED AMOUNT (IN USD)</label>
                        </Col>
                        <Col span={8}>
                          <span className="cbm">
                            $
                            {inList.length > 0
                              ? numFormat(
                                "dot/comma",
                                item.selling_price *
                                inList.map((value) => {
                                  return value.quantity;
                                })
                              )
                              : "0.00" || "0.00"}
                          </span>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between" align="top">
                        <Col span={16}>
                          <label>QTY.</label>
                        </Col>
                        <Col span={8}>
                          <div className="order-form-qty">
                            <Field
                              id={`${id}_${item.product_variant_id}`}
                              type="number"
                              name={`${field}.quantity`}
                              min={0}
                              step={10}
                              component={FormField}
                              validate={qtyValidators}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </MediaQuery>
          </List.Item>
        );
      }}
      header={
        <MediaQuery minWidth={1224}>
          <Row gutter={24} style={{ paddingRight: "17px" }}>
            <Col span={8}>
              <h3>Products</h3>
            </Col>
            <Col span={3}>
              <h3>
                Packing
                <br />
                Scheme
              </h3>
            </Col>
            <Col span={1}>
              <h3>Country</h3>
            </Col>
            <Col span={3} align="right">
              <h3>
                Amount
                <br />
                (USD/case)
              </h3>
            </Col>
            <Col span={2} align="right">
              <h3>Gross Wt.</h3>
            </Col>
            <Col span={2} align="right">
              <h3>CBM</h3>
            </Col>
            <Col span={2} align="right">
              <h3>
                Ordered Amount <br />
                (IN USD)
              </h3>
            </Col>
            <Col span={3} align="right">
              <h3>
                Ordered
                <br />
                QTY (IN CS)
              </h3>
            </Col>
          </Row>
        </MediaQuery>
      }
      footer={
        <Row gutter={24} className="fixed-bottom">
          <MediaQuery minWidth={1224}>
            <Col className="order-totals" span={24}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={10}>
                  <label>TOTAL:</label>
                </Col>
                <Col span={4}>
                  <label>AMOUNT (USD/CASE) </label>
                  <span>$&nbsp;{numFormat("dot/comma", totals.price)}</span>
                </Col>
                <Col span={3}>
                  <label>GROSS WT. </label>
                  <span>{numberFormat(totals.grossWeight)}</span>
                </Col>
                <Col span={3}>
                  <label>CBM</label>
                  <span>{numberFormat(totals.cbm)}</span>
                </Col>
                <Col span={4}>
                  <label>QUANTITY (IN CASE)</label>
                  <span>{numFormat("comma", totals.quantity)}</span>
                </Col>
              </Row>
            </Col>
          </MediaQuery>
          <MediaQuery maxWidth={1224}>
            <Row
              type="flex"
              justify="center"
              align="middle"
              className="fixed-b"
            >
              <Col span={24}>
                <Row type="flex" justify="space-between" align="top">
                  <Col span={7}>
                    <label>
                      TOTAL
                      <br />
                      AMOUNT
                    </label>
                    <br />
                    <span>$&nbsp;{numFormat("dot/comma", totals.price)}</span>
                  </Col>
                  <Col span={7}>
                    <label>
                      TOTAL
                      <br />
                      GROSS WT.
                    </label>
                    <br />
                    <span>{numFormat("dot/comma", totals.grossWeight)}</span>
                  </Col>
                  <Col span={6}>
                    <label>
                      TOTAL
                      <br />
                      CBM
                    </label>
                    <br />
                    <span>{numberFormat(totals.cbm)}</span>
                  </Col>
                  <Col span={4}>
                    <label>
                      TOTAL
                      <br />
                      QTY.
                    </label>
                    <br />
                    <span>{numFormat("comma", totals.quantity)}</span>
                  </Col>
                </Row>

                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  className="clear-savedraft-submit"
                >
                  <Col xs={24} sm={8} md={8} lg={8} align="center">
                    <Button
                      htmlType="reset"
                      onClick={resetForm}
                      disabled={
                        !totals.quantity ||
                        !myPermissions.some(
                          (r) => r.name === "distributor.orders.cancel"
                        )
                      }
                      className="btn-small btn-default"
                    >
                      Clear
                    </Button>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} align="center">
                    <Button
                      htmlType="button"
                      onClick={handleSaveAsDraft}
                      disabled={!totals.quantity || isDraftLoading}
                      className="btn-small btn-secondary"
                      loading={isDraftLoading}

                    >
                      Save As Draft
                    </Button>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} align="center">
                    <Button
                      htmlType="submit"
                      disabled={!totals.quantity}
                      className="btn-small btn-primary"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </MediaQuery>
          <MediaQuery minWidth={1224}>
            <Col className="actions" span={24}>
              <Button
                type="default"
                htmlType="reset"
                onClick={resetForm}
                disabled={
                  !totals.quantity ||
                  !myPermissions.some(
                    (r) => r.name === "distributor.orders.cancel"
                  )
                }
                className="btn-main btn-default"
              >
                Clear
              </Button>
              {myPermissions.some(
                (r) => r.name === "distributor.orders.create"
              ) ? (
                  <Button
                    htmlType="button"
                    onClick={handleSaveAsDraft}
                    disabled={!totals.quantity || isDraftLoading}
                    className="btn-main btn-secondary"
                    loading={isDraftLoading}
                  >
                    Save As Draft
                  </Button>
                ) : (
                  ""
                )}
              {myPermissions.some(
                (r) => r.name === "distributor.orders.create"
              ) ? (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!totals.quantity || productOrderLoading}
                    className="btn-main btn-primary"
                  >
                    Submit
                  </Button>
                ) : (
                  ""
                )}
            </Col>
          </MediaQuery>
        </Row>
      }
    />
  );
};

class Order extends Component {
  lunrized;
  constructor() {
    super();
    this.state = {
      isOrderSubmitLoading: false,
      isDraftLoading: false,
      isOrderDeliveryModalShow: false,
      isCsvUploadModalShow: false,
      isOrderProductModalShow: false,
      isOrderFormLoading: false,
      activeRate: null,
      showFeedbackModal: false,
      showQuestion: false,
      showThankYouModal: false,
      isPostingFeedback: false,
      productOrderListData: [],
      startDate: null,
      productDataForModal: {
        data: [],
      },
      distributorInputData: {},
      // distributorInputData: {
      //   distributorName: "",
      //   distributorCode: "",
      //   officeAddress: "",
      //   phoneNumber: "",
      //   emailAddress: ""
      // },
      filters: {},
      searchValue: "",
      newData: [],
    };
    this.onContactDetailsFormSubmit = this.onContactDetailsFormSubmit.bind(
      this
    );
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  csvModalVisible = () => {
    this.setState({
      isCsvUploadModalShow: !this.state.isCsvUploadModalShow,
    });
  };

  resetFieldsAsDraft = () => {
    const {
      priceList: { pricelistData },
      dispatch,
    } = this.props;
    let i;
    // history.push('/order')
    for (i = 0; i < pricelistData.length; i++) {
      dispatch(change("productOrderForm", `orders[${i}].quantity`, 0));
    }
  };

  isOrderDraft(priceList, drafts, draftId) {
    _.each(priceList.data, (product, i) => {
      _.each(drafts.data, (draft) => {
        if (draft.id === parseInt(draftId)) {
          _.each(draft.product, (draftProduct) => {
            if (
              product.product_variant_id === draftProduct.product_variant_id
            ) {
              return (priceList.data[i].quantity = draftProduct.quantity);
            }
          });
        }
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      let searchParams = new URLSearchParams(this.props.location.search);
      if (searchParams.has("draftId")) {
      }
      this.isOrderDraft(
        nextProps.priceList,
        nextProps.drafts,
        searchParams.get("draftId")
      );
    }
    if (this.props.distributor !== nextProps.distributor) {
      const { distributor } = nextProps;
      const etdMonth =
        distributor &&
        distributor.distributor &&
        distributor.distributor.etd_month;
      this.setState({
        startDate: moment().add(parseInt(etdMonth), "M").toDate(),
      });
    }
  }

  componentDidMount() {
    let searchParams = new URLSearchParams(this.props.location.search);
    this.props.fetchDistributor();
    this.props.fetchProducts();
    this.props.fetchDrafts();
    this.props.fetchPermissions();
    this.props.fetchDraftById(searchParams.get("draftId"));

    this.props.fetchProductPriceList().then(() => {
      const { priceList } = this.props;
      this.setState({ ...this.state, items: priceList.data });
      this.lunrized = lunr(function () {
        this.ref("id");
        this.field("product_name", {
          extractor: (doc = {}) => doc.product.name,
        });
        _.each(priceList.data, (product) => this.add(product));
      });
    });
    // Get latest po number
    this.props.fetchPoRefNo();
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchValue, newData } = this.state;
    if (prevState.searchValue !== this.state.searchValue) {
      const result = Object.values(this.props.priceList.data).filter((row) => {
        const search = (arg) => {
          return (
            arg &&
            arg
              .toString()
              .toLowerCase()
              .includes(this.state.searchValue.toString().toLowerCase().trim())
          );
        };

        const { product, variant, special_code, selling_price } = row;

        return (
          [product].filter(row => row !== null).some(
            (row) =>
              search(row.name) ||
              search(row.country_made) ||
              search(row.description) ||
              search(row.category) ||
              search(row.brand.name)
          ) ||
          [variant].filter(row => row !== null).some(
            (row) =>
              search(row.name) ||
              search(row.material_code) ||
              search(row.packing_scheme) ||
              search(row.variants.size) ||
              search(row.variants.flavor) ||
              search(row.cbm) ||
              search(row.gross_weight)
          ) ||
          search(special_code) ||
          search(selling_price) ||
          null
        );
      });

      this.setState({
        // this is temporary fix for empty selling_price and variant 
        // empty selling_price and variant should not be return by backend
        newData: result.filter(data => (data.variant !== null && data.selling_price !== 0))
        // newData: result,
      });
    }
  }

  handleDeliveryDetailsSubmit = (data) => {
    this.props.isCustomerPoNoUnique(data).then((response) => {
      if (response.response.data) {
        Modal.error({
          // icon: <img src={warningIcon} height={50} width={50}  />,
          icon: (
            <Icon type="exclamation-circle" style={{ fontSize: "1.3rem" }} />
          ),
          title: "Complete the validation",
          content: "Customer PO Number must be unique",
          okText: "Ok",
        });
      } else {
        this.onContactDetailsFormSubmit(data);
      }
    });
  };

  // handleProductSearch(product) {
  //   // const filters = Object.assign({}, this.state.filters, { product });
  //   // const { priceList } = this.props;
  //   // this.setState({ ...this.state, filters });
  //   // this.setState({
  //   //   ...this.state,
  //   //   items: !product
  //   //     ? priceList.data
  //   //     : this.lunrized
  //   //         .search(`${product} ${product}*`)
  //   //         .map(res => _.find(priceList.data, ["id", parseInt(res.ref)]))
  //   // });
  // }

  onOpenDeliveryDetailFormModal = (value) => {
    let searchParams = new URLSearchParams(this.props.location.search);
    if (searchParams.has("draftId")) {
      const etdDate =
        this.props.drafts.deliveryDetailsDraftData.estimated_delivery ||
        new Date();
      this.handleChangeDate(new Date(etdDate));
    }

    let orders = _.filter(
      value.orders,
      (order) => parseInt(order.quantity) > 0
    );
    this.setState({
      isOrderDeliveryModalShow: true,
      productOrderListData: { ...value, orders },
    });
  };

  onOrderFormSubmit = async (value) => {
    const { distributor } = this.props;
    const distributorId = this.props.distributor.distributor.id;
    const searchParams = new URLSearchParams(this.props.location.search);
    const {
      productOrderListData,
      distributorInputData,
      startDate,
    } = this.state;

    let orderData = {
      orders: productOrderListData.orders,
      distributor_id: distributorId,
      distributor_draft_id: searchParams.get("draftId"),
      estimated_date: startDate,
      ...distributorInputData,
    };

    let poData = {
      orders: productOrderListData.orders,
      distributor_id: distributorId,
      po_number: distributorInputData.po_ref_no,
    };

    this.setState({ isOrderFormLoading: true });

    // Get latest PO number again
    await this.props.fetchPoRefNo().then((response) => {
      if (response.error) {
        Modal.error({
          title: "Unable to place your order. Please try again.",
          content: "",
          icon: <img src={error} alt="error" />,
        });

        this.setState({
          isOrderSubmitLoading: false,
          isOrderProductModalShow: false,
          isOrderFormLoading: false,
        });
      } else {
        let prefix_number =
          response && response.response && response.response.data.prefix_number
            ? response.response.data.prefix_number
            : 0;
        const zeroPad = (num, places) => String(num).padStart(places, "0");
        let number = parseInt(prefix_number) + 1;
        let newPad = zeroPad(number, 2);
        let newPoRefNo = makePONumber(distributor, newPad);

        orderData = {
          ...orderData,
          po_ref_no: newPoRefNo,
        };

        poData = {
          orders: productOrderListData.orders,
          distributor_id: distributorId,
          po_number: newPoRefNo,
        };

        // Post PO number
        this.props.postPoRefNo(poData).then((response) => {
          if (response.error) {
            Modal.error({
              title: "Unable to place your order. Please try again.",
              content: "",
              icon: <img src={error} alt="error" />,
            });

            this.setState({
              isOrderSubmitLoading: false,
              isOrderProductModalShow: false,
              isOrderFormLoading: false,
            });
          } else {
            // Post Order
            this.props.createProductOrder(orderData).then((response) => {
              if (response.error) {
                Modal.error({
                  title: "Unable to place your order. Please try again.",
                  content: "",
                  icon: <img src={error} alt="error" />,
                });
              } else {
                const orderId =
                  response.response.data.product[0].distributor_order_id;
                this.resetOrderForm();
                Modal.success({
                  title: (
                    <span id="Confirmed_Order">Thank you for your order!</span>
                  ),
                  content:
                    "You shall receive an email notification once we have received your order.",
                  icon: <div style={{ paddingLeft: 15 }}><img src={success} alt="success" style={{ height: 25, width: 25 }} /></div>,
                  onOk: () => {
                    this.setState({
                      distributorOrderId: orderId,
                      showFeedbackModal: true,
                    });
                  },
                });
              }
              this.props.dispatch(reset("delivery_details_form"));
              this.setState({
                isOrderSubmitLoading: false,
                isOrderProductModalShow: false,
                isOrderFormLoading: false,
              });
            });
          }
        });
      }
    });
  };

  onContactDetailsFormSubmit(value) {
    const { priceList } = this.props;
    let fromPricelist = priceList.pricelistData ? priceList.pricelistData : [];
    let fromOrders =
      this.state.productOrderListData.orders !== undefined
        ? this.state.productOrderListData.orders
        : [];

    let productOrderDataModal = [];
    let qty = 0,
      cbm = 0,
      selling_price = 0;

    fromOrders.forEach((fromOrder, i) => {
      fromPricelist.forEach((fromPricelist, j) => {
        if (fromOrder.product_variant_id === fromPricelist.product_variant_id) {
          qty = qty + Number(fromOrder.quantity);
          cbm =
            cbm +
            Number(fromOrder.quantity) * Number(fromPricelist.variant.cbm);
          selling_price =
            selling_price + fromOrder.quantity * fromOrder.selling_price;
          productOrderDataModal.push({ ...fromPricelist, ...fromOrder });
        }
      });
    });

    const {
      distributor_address_id,
      email_address,
      distributor_contact_id,
      port_of_destination,
      po_ref_no,
      special_instructions,
      sales_ref_number,
      volume,
    } = value;

    this.setState({
      isOrderProductModalShow: !this.state.isOrderProductModalShow,
      isOrderDeliveryModalShow: !this.state.isOrderDeliveryModalShow,
      productDataForModal: {
        data: productOrderDataModal,
        totalQty: qty,
        totalCbm: cbm,
        totalSellingPrice: selling_price,
      },

      distributorInputData: {
        volume,
        distributor_address_id,
        email_address,
        distributor_contact_id,
        port_of_destination,
        po_ref_no,
        special_instructions,
        sales_ref_number,
      },
    });
  }

  resetOrderForm() {
    let searchParams = new URLSearchParams(this.props.location.search);
    if (searchParams.has("draftId")) this.resetFieldsAsDraft();
    else this.props.dispatch(reset("productOrderForm"));
  }

  onCloseDeliveryFormModal = () => {
    this.props.dispatch(reset("delivery_details_form"));
    this.setState({ isOrderDeliveryModalShow: false });
  };

  displayDistributorBasedOnType(type) {
    const { distributor } = this.props;
    let emailArray = [];
    distributor &&
      distributor.distributor.contact.map((data) => {
        if (data.type === type) emailArray.push(data);
      });
    return emailArray;
  }

  displayDistributorBasedOnId(id, type, column, key) {
    id = parseInt(id);
    const { distributor } = this.props;
    let returnData;
    distributor &&
      distributor.distributor[column].map((data) => {
        if (data.id === id) returnData = data[key];
      });
    return returnData;
  }

  handleChangeDate(date) {
    this.setState({
      startDate: date,
    });
  }

  handleSaveAsDraft(value) {
    this.setState({ isDraftLoading: true })
    const searchParams = new URLSearchParams(this.props.location.search);
    this.props
      .saveAsDraft(
        {
          ...value,
          orders: _.filter(
            value.orders,
            (order) => parseInt(order.quantity) > 0
          ),
        },
        searchParams.get("draftId")
      )
      .then((response) => {
        this.setState({ isDraftLoading: false })
        if (response.error) {
          // message.error('Unable to place your order. Please try again.')
          Modal.error({
            title: "Unable to save as draft your order. Please try again.",
            content: "",
            icon: <img src={error} alt="error" />,
          });
        } else {
          // message.success('Your order has been placed.')
          // Modal.success({
          //   title: "Draft!",
          //   content: "You shall now be redirected to the draft order page.",
          //   icon: <img src={success} alt="success" />,
          // });
          Modal.success({
            title: (
              <span>Draft!</span>
            ),
            content:
              "You shall now be redirected to the draft order page.",
            icon: <div style={{ paddingLeft: 15 }}><img src={success} alt="success" style={{ height: 25, width: 25 }} /></div>,
          });
          this.props.history.push("/drafts");
        }
      });
  }

  handleClickRate = (rate) => {
    this.setState({ activeRate: rate, showQuestion: true });
  };

  handleFeedbackSubmit = (values) => {
    const { activeRate, distributorOrderId, comments } = this.state;
    const { distributor, postFeedback } = this.props;

    if (!activeRate) {
      message.error("Please input your feedback");
      return;
    }
    if (!comments) {
      message.error("Please provide your comments and suggestions");
      return;
    }
    const data = {
      po_date: moment().format("YYYY-MM-DD"),
      distributor_id: distributor.distributor_id,
      comments: comments,
      feedback_score: activeRate,
      feedback_type: "order placement",
      distributor_order_id: distributorOrderId,
    };

    this.setState({ isPostingFeedback: true });

    postFeedback(data).then((response) => {
      if (!response.error) {
        this.setState({
          activeRate: null,
          showThankYouModal: true,
          showFeedbackModal: false,
          isPostingFeedback: false,
          comments: "",
        });
      } else {
        this.setState({
          activeRate: null,
          showFeedbackModal: false,
          isPostingFeedback: false,
          comments: "",
        });
      }
    });
  };

  handleProductSearch = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  searchResultIndicator = () => {
    const { searchValue, newData } = this.state;
    if (searchValue) {
      if (newData.length == 1) {
        return (
          <div className="search-result-indicator">{`${newData.length}  Product Found`}</div>
        );
      } else if (newData.length >= 1) {
        return (
          <div className="search-result-indicator">{`${newData.length}  Products Found`}</div>
        );
      } else {
        return "";
      }
    }
  };

  render() {
    let {
      myPermissions,
      myPermissionsIsFetching,
      formValues,
      handleSubmit,
      distributor,
    } = this.props;

    const {
      activeRate,
      showThankYouModal,
      showFeedbackModal,
      isPostingFeedback,
      distributorInputData,
      comments,
    } = this.state;

    distributor = (distributor && distributor.distributor) || "";
    const etdMonth = distributor && distributor.etd_month;
    const meta = {
      title: "Order Now - URC Global Exports",
      description:
        "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Order Now",
        },
      },
    };

    function selectedAddress(param, distributor) {
      const { distributor_address_id = "N/A" } = param;
      const { address } = distributor;

      if (address && distributor_address_id) {
        const sample = [address].filter(
          (row, key) => row[key].id == distributor_address_id
        );
        if (sample[0]) {
          const iterate = [...sample[0]].map((row) => row.name);
          return iterate[0];
        }
      }
    }
    let searchParams = new URLSearchParams(this.props.location.search);

    if (myPermissionsIsFetching) {
      return (
        <DocumentMeta {...meta}>
          <Layout>
            <Spin spinning={myPermissionsIsFetching}>
              <Skeleton active />
            </Spin>
          </Layout>
        </DocumentMeta>
      );
    }

    return (
      <DocumentMeta {...meta}>
        <Layout>
          {myPermissions.some((r) => r.name === "distributor.orders.view") ? (
            <Layout.Content className="product-order-content">
              <Row gutter={48}>
                <Col className="product-order-form-page" span={24}>
                  <Card bordered={false}>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="header"
                    >
                      <Col>
                        <h2>Order Form</h2>
                      </Col>
                      <Col>
                        <Link to={"/order-history"}>VIEW RECENT ORDERS </Link>
                      </Col>
                    </Row>
                    <MediaQuery minWidth={1224}>
                      <div className="product-order-query">
                        <Form
                          className="product-order-query-form"
                          layout="horizontal"
                        >
                          <Row gutter={16}>
                            <Col md={16} className="search-container">
                              <Form.Item>
                                <Input.Search
                                  prefix={<Icon type="search" />}
                                  placeholder="Search"
                                  value={this.state.searchValue}
                                  onChange={this.handleProductSearch}
                                  size="large"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              md={8}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                style={{
                                  color: "white",
                                  width: 208,
                                  height: 42,
                                  background: "#BF914A",
                                  borderRadius: 46,
                                }}
                                disabled={
                                  !myPermissions.some(
                                    (r) => r.name === "distributor.orders.edit"
                                  )
                                }
                                onClick={() => this.csvModalVisible()}
                              >
                                Bulk Upload
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={1224}>
                      <div className="product-order-query">
                        <Form
                          className="product-order-query-form"
                          layout="horizontal"
                        >
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                          >
                            <Col span={24} className="search-container">
                              <Form.Item>
                                <Input.Search
                                  type="text"
                                  placeholder="Search"
                                  prefix={<Icon type="search" />}
                                  value={this.state.searchValue}
                                  onChange={this.handleProductSearch}
                                  size="large"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </MediaQuery>
                    <div className="product-order-result">
                      {this.searchResultIndicator()}
                      <Form
                        className="product-order-query-form"
                        onSubmit={handleSubmit(
                          this.onOpenDeliveryDetailFormModal.bind(this)
                        )}
                      >
                        <FieldArray
                          name="orders"
                          products={
                            this.state.searchValue
                              ? this.state.newData
                              : this.state.items
                          }
                          handleSaveAsDraft={handleSubmit(
                            this.handleSaveAsDraft.bind(this)
                          )}
                          submitForm={handleSubmit(
                            this.onOpenDeliveryDetailFormModal.bind(this)
                          )}
                          resetForm={this.resetOrderForm.bind(this)}
                          formValues={formValues}
                          isOrderSubmitLoading={this.state.isOrderSubmitLoading}
                          isDraftLoading={this.state.isDraftLoading}
                          productOrderLoading={this.props.productOrderLoading}
                          productsIsFetching={this.props.productsIsFetching}
                          priceListIsFetching={this.props.priceListIsFetching}
                          myPermissions={myPermissions}
                          component={orderProductList}
                        />
                      </Form>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Layout.Content>
          ) : (
              <Result
                status="warning"
                title="You have no permission to view this page."
              />
            )}

          <Modal
            className="feedback-modal"
            title={<span>We'd like to get your feedback</span>}
            visible={showFeedbackModal}
            onCancel={() => this.setState({ showFeedbackModal: false })}
            footer={null}
            maskClosable={false}
            closable={false}
          >
            <h3>How was your overall order experience?</h3>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="feedback-rate"
            >
              {feedbackEmoji.sort((a, b) => b.rate - a.rate).map((value, index) => (
                <Col
                  key={index}
                  onClick={() => this.handleClickRate(value.rate)}
                  className={value.rate === activeRate ? "active" : ""}
                >
                  <img alt={value.rate} src={value.svg} />
                </Col>
              ))}
            </Row>
            <Form>
              <Row className="feedback-question">
                <Col span={24}>
                  <h3>Any comments/suggestions?</h3>
                  <div className="form-label">
                    <Input
                      size="large"
                      onChange={(e) =>
                        this.setState({ comments: e.target.value })
                      }
                      value={comments}
                      className="inputStyle"
                    />
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="end" className="feedback-button">
                <Col>
                  <Button
                    htmlType="submit"
                    className="btn-small btn-primary"
                    style={{ width: "192px" }}
                    loading={isPostingFeedback}
                    onClick={() => this.handleFeedbackSubmit()}
                  >
                    {isPostingFeedback ? null : "Send Feedback Form"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal>

          <Modal
            className="feedback-modal"
            title={<span>Feedback Received!</span>}
            visible={showThankYouModal}
            footer={null}
            closable={false}
          >
            <h3>Thank you for your feedback!</h3>
            <p>
              Rest assured that we are continuously enhancing your order
              experience with us.
            </p>
            <Row type="flex" justify="end" className="feedback-button">
              <Col span={24} align="right">
                <Button
                  style={{ margin: 0, minWidth: "80px" }}
                  className="btn-small btn-primary"
                  onClick={() => this.setState({ showThankYouModal: false })}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </Modal>
          {this.state.isCsvUploadModalShow && (
            <ModalCsvUpload
              visible={this.state.isCsvUploadModalShow}
              csvModalVisible={this.csvModalVisible}
            />
          )}
          {this.state.isOrderDeliveryModalShow && (
            <ModalDeliveryDetails
              distributor={distributor}
              formValidators={formValidators}
              renderSelectField={renderSelectField}
              renderInput={renderInput}
              renderDatepicker={renderDatepicker}
              etdMonth={etdMonth}
              po_ref_no={this.props.po_ref_no}
              // handleSubmit={handleSubmit}
              displayDistributorBasedOnType={this.displayDistributorBasedOnType}
              isOrderDeliveryModalShow={this.state.isOrderDeliveryModalShow}
              startDate={this.state.startDate}
              onCloseDeliveryFormModal={this.onCloseDeliveryFormModal}
              onContactDetailsFormSubmit={this.onContactDetailsFormSubmit}
              handleChangeDate={this.handleChangeDate}
              onSubmit={this.handleDeliveryDetailsSubmit}
            />
          )}

          {this.state.isOrderProductModalShow && (
            <Modal
              className="order-product-confirm-modal"
              visible={this.state.isOrderProductModalShow}
              style={{ top: 20 }}
              onCancel={() => {
                this.setState({
                  isOrderProductModalShow: false,
                  isOrderDeliveryModalShow: false,
                });
                this.props.dispatch(reset("delivery_details_form"));
              }}
              footer={null}
              closable={false}
              width={850}
              id="order_sent"
            >
              <MediaQuery minWidth={1224}>
                <Layout>
                  <Layout.Content className="order-confirmation-header">
                    <Row>
                      <Col>
                        <h1>Order Summary</h1>
                      </Col>
                    </Row>
                  </Layout.Content>
                  <Layout.Content className="order-confirmation-content">
                    <Card style={{ padding: "1vh 1vw" }}>
                      <Row>
                        <Col className="delivery-details-title">
                          <h2 className="details-text">Order details</h2>
                          <h2
                            className="details-edit"
                            onClick={() => {
                              this.setState({
                                isOrderProductModalShow: false,
                                isOrderDeliveryModalShow: true,
                              });
                            }}
                          >
                            Edit
                          </h2>
                        </Col>
                      </Row>
                      <Layout.Content>
                        <Row className="delivery-details-info">
                          <Col md={8}>
                            <h3>Customer Name</h3>
                            <p>{distributor.name || "N/A"}</p>
                          </Col>
                          <Col md={8}>
                            <h3>Customer Code</h3>
                            <p>{distributor.customer_code || "N/A"}</p>
                          </Col>
                          <Col md={8}>
                            <h3>Office Address</h3>
                            <p>
                              {selectedAddress(
                                distributorInputData,
                                distributor
                              )}
                            </p>
                          </Col>
                          <Col md={8}>
                            <h3>Email Address</h3>
                            <p>
                              {this.displayDistributorBasedOnId(
                                distributorInputData.email_address,
                                "email",
                                "contact",
                                "contact"
                              ) || "N/A"}
                            </p>
                          </Col>

                          <Col md={8}>
                            <h3>phone number</h3>
                            <p>
                              {this.displayDistributorBasedOnId(
                                distributorInputData.distributor_contact_id,
                                "contact",
                                "contact",
                                "contact"
                              ) || "N/A"}
                            </p>
                          </Col>
                          <Col md={8}>
                            <h3>Port of Destination</h3>
                            <p>
                              {distributorInputData.port_of_destination ||
                                "N/A"}
                            </p>
                          </Col>

                          <Col md={8}>
                            <h3>Target ETD</h3>
                            <p>
                              {moment(this.state.startDate).format("MM/YYYY") ||
                                "N/A"}
                            </p>
                          </Col>
                          <Col md={8}>
                            <h3>PO Ref Number</h3>
                            <p>{distributorInputData.po_ref_no || "N/A"}</p>
                          </Col>

                          <Col md={8}>
                            <h3>Customer PO Number</h3>
                            <p>
                              {distributorInputData.sales_ref_number || "N/A"}
                            </p>
                          </Col>

                          <Col md={8}>
                            <h3>PO Date</h3>
                            <p>{moment(new Date()).format("DD/MM/YYYY")}</p>
                          </Col>

                          <Col md={8}>
                            <h3>Container Options</h3>
                            <p>{distributorInputData.volume || "N/A"}</p>
                          </Col>

                          <Col md={24}>
                            <h3>special instructions</h3>
                            <p>
                              {distributorInputData.special_instructions ||
                                "N/A"}
                            </p>
                          </Col>
                        </Row>
                      </Layout.Content>
                    </Card>
                    <Card style={{ padding: "1vh 1vw" }}>
                      <Row>
                        <Col className="delivery-details-title">
                          <h2 className="details-text">Product details</h2>
                          <h2
                            className="details-edit"
                            onClick={() => {
                              this.setState({
                                isOrderProductModalShow: false,
                                isOrderDeliveryModalShow: false,
                              });
                            }}
                          >
                            Edit
                          </h2>
                        </Col>
                      </Row>
                      <Layout.Content>
                        <Row
                          type="flex"
                          align="middle"
                          className="os-product-details"
                        >
                          {/* mapping */}
                          {this.state.productDataForModal.data.map((data) => (
                            <Col span={12}>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="start"
                              >
                                <Col span={7} align="center">
                                  <img
                                    src={
                                      data.variant.image
                                        ? `${config.REACT_APP_FILES_URL}${data.variant.image}`
                                        : "https://via.placeholder.com/100x100?text=No+Image"
                                    }
                                  />
                                </Col>
                                <Col span={16} offset={1}>
                                  <Row>
                                    <Col>
                                      <label className="brand-name">
                                        {data.product.brand &&
                                          data.product.brand.name
                                          ? data.product.brand.name
                                          : ""}
                                      </label>
                                      <div className="brand-variant-name">
                                        {data.variant.name
                                          ? data.variant.name.toLowerCase()
                                          : ""}
                                      </div>
                                      <Row
                                        type="flex"
                                        justify="space-between"
                                        align="top"
                                      >
                                        <Col span={10}>
                                          <label className="psc">
                                            {data.variant.packing_scheme
                                              ? data.variant.packing_scheme
                                              : ""}
                                          </label>
                                        </Col>
                                        <Col span={7}>
                                          <label className="psc">
                                            {data.special_code !== null
                                              ? data.special_code
                                              : data.variant.material_code}
                                          </label>
                                        </Col>
                                        <Col span={7}>
                                          <label className="psc">
                                            {data.product &&
                                              data.product.country_made
                                              ? country(
                                                data.product.country_made
                                              )
                                              : ""}
                                          </label>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col>
                                      <Row
                                        type="flex"
                                        justify="space-between"
                                        align="top"
                                        style={{ padding: "10px 0" }}
                                      >
                                        <Col span={10} className="aqc">
                                          <label>amount:</label> <br />
                                          <span>
                                            $&nbsp;
                                            {numFormat(
                                            "dot/comma",
                                            data.selling_price * data.quantity
                                          )}
                                          </span>
                                        </Col>
                                        <Col span={7} className="aqc">
                                          <label>cbm:</label>
                                          <br />
                                          <span>
                                            {numberFormat(
                                              data.cbm * data.quantity
                                            )}
                                          </span>
                                        </Col>
                                        <Col span={7} className="aqc">
                                          <label>qty:</label>
                                          <br />
                                          <span>
                                            {parseInt(
                                              data.quantity
                                            ).toLocaleString()}
                                          </span>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </Row>
                      </Layout.Content>
                    </Card>
                  </Layout.Content>
                  <Layout.Content className="order-submit-footer">
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="f-total"
                    >
                      <Col span={8}>
                        <label>total amount:</label>
                        <span>
                          $&nbsp;
                          {numFormat(
                          "dot/comma",
                          this.state.productDataForModal.totalSellingPrice
                        )}
                        </span>
                      </Col>
                      <Col span={8} align="center">
                        <label>total cbm:</label>
                        <span>
                          {numberFormat(
                            this.state.productDataForModal.totalCbm
                          )}
                        </span>
                      </Col>
                      <Col span={8} align="right">
                        <label>total quantity:</label>
                        <span>
                          {this.state.productDataForModal.totalQty.toLocaleString()}
                        </span>
                      </Col>
                    </Row>

                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="f-warning-submit"
                    >
                      <Col span={12}>
                        <img src={warningIcon} />
                        <h3>Are you confirming your order?</h3>
                      </Col>
                      <Col span={12} align="center">
                        <Button
                          className="btn-small btn-default"
                          disabled={this.state.isOrderFormLoading}
                          onClick={() => {
                            this.setState({
                              isOrderProductModalShow: false,
                              isOrderDeliveryModalShow: true,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          id="Thank_you_for_your_order"
                          loading={this.state.isOrderFormLoading}
                          className="btn-small btn-primary"
                          onClick={() => this.onOrderFormSubmit()}
                        >
                          {this.state.isOrderFormLoading
                            ? null
                            : "Confirm Order"}
                        </Button>
                      </Col>
                    </Row>
                  </Layout.Content>
                </Layout>
              </MediaQuery>
              <MediaQuery maxWidth={1224}>
                <Row
                  type="flex"
                  justify="center"
                  align="top"
                  className="xs-order-summary-modal"
                >
                  <Col span={24}>
                    {/* header */}
                    <Row type="flex" justify="center" align="top">
                      <Col span={21}>
                        <h1>Order Summary</h1>
                        <p>Below is the summary of your order.</p>
                      </Col>
                    </Row>
                    {/* content */}
                    <Row type="flex">
                      <Col span={24}>
                        {/* delivery details */}
                        <Row
                          type="flex"
                          justify="center"
                          align="top"
                          className="delivery"
                        >
                          <Col span={21}>
                            <Row
                              type="flex"
                              justify="center"
                              align="middle"
                              className="sub-title-action"
                            >
                              <Col span={12}>
                                <h2>order details</h2>
                              </Col>
                              <Col span={12} align="right">
                                <h2
                                  onClick={() => {
                                    this.setState({
                                      isOrderProductModalShow: false,
                                      isOrderDeliveryModalShow: true,
                                    });
                                  }}
                                >
                                  Edit
                                </h2>
                              </Col>
                            </Row>
                            <Row
                              type="flex"
                              justify="space-between"
                              align="top"
                            >
                              <Col span={12}>
                                <label>customer name</label>
                                <span>{distributor.name || "N/A"}</span>
                              </Col>
                              <Col span={11} offset={1}>
                                <label>customer code</label>
                                <span>
                                  {distributor.customer_code || "N/A"}
                                </span>
                              </Col>
                              <Col span={24}>
                                <label>office address</label>
                                <span>
                                  {selectedAddress(
                                    distributorInputData,
                                    distributor
                                  )}
                                </span>
                              </Col>

                              <Col span={12}>
                                <label>email address</label>
                                <span>
                                  {this.displayDistributorBasedOnId(
                                    distributorInputData.email_address,
                                    "email",
                                    "contact",
                                    "contact"
                                  ) || "N/A"}
                                </span>
                              </Col>
                              <Col span={11} offset={1}>
                                <label>phone number</label>
                                <span>
                                  {this.displayDistributorBasedOnId(
                                    distributorInputData.distributor_contact_id,
                                    "contact",
                                    "contact",
                                    "contact"
                                  ) || "N/A"}
                                </span>
                              </Col>
                              <Col span={12}>
                                <label>port of destination</label>
                                <span>
                                  {distributorInputData.port_of_destination ||
                                    "N/A"}
                                </span>
                              </Col>
                              <Col span={11} offset={1}>
                                <label>target ETD</label>
                                <span>
                                  {moment(this.state.startDate).format(
                                    "MM/YYYY"
                                  ) || "N/A"}
                                </span>
                              </Col>
                              <Col span={12}>
                                <label>po number</label>
                                <span>
                                  {distributorInputData.po_ref_no || "N/A"}
                                </span>
                              </Col>
                              <Col span={11} offset={1}>
                                <label>customer PO number</label>
                                <span>
                                  {distributorInputData.sales_ref_number ||
                                    "N/A"}
                                </span>
                              </Col>

                              <Col span={11} offset={1}>
                                <label>Container Options</label>
                                <span>
                                  {distributorInputData.volume || "N/A"}
                                </span>
                              </Col>

                              <Col span={24}>
                                <label>special instructions</label>
                                <span>
                                  {distributorInputData.special_instructions ||
                                    "N/A"}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* product details */}
                        <Row
                          type="flex"
                          justify="center"
                          align="top"
                          className="product"
                        >
                          <Col span={21}>
                            <Row type="flex" justify="center" align="middle">
                              <Col span={12}>
                                <h2>Product details</h2>
                              </Col>
                              <Col span={12} align="right">
                                <h2
                                  onClick={() => {
                                    this.setState({
                                      isOrderProductModalShow: false,
                                      isOrderDeliveryModalShow: false,
                                    });
                                  }}
                                >
                                  Edit
                                </h2>
                              </Col>
                            </Row>

                            <Row type="flex" justify="start" align="middle">
                              {this.state.productDataForModal.data.map(
                                (data) => {
                                  return (
                                    <>
                                      <Col span={6} align="center">
                                        <img
                                          className="product-details-xs-img"
                                          src={
                                            data.variant.image
                                              ? `${config.REACT_APP_FILES_URL}${data.variant.image}`
                                              : "https://via.placeholder.com/100x100?text=No+Image"
                                          }
                                        />
                                      </Col>
                                      <Col span={17} offset={1}>
                                        <Row>
                                          <Col>
                                            <label>
                                              {data.product.brand &&
                                                data.product.brand.name
                                                ? data.product.brand.name
                                                : ""}
                                            </label>
                                            <div className="variant-name">
                                              {data.variant.name
                                                ? data.variant.name
                                                : ""}
                                            </div>
                                            <Row
                                              type="flex"
                                              justify="space-between"
                                              align="top"
                                            >
                                              <Col span={11}>
                                                <label>
                                                  {data.variant.packing_scheme
                                                    ? data.variant
                                                      .packing_scheme
                                                    : ""}
                                                </label>
                                              </Col>
                                              <Col span={7}>
                                                <label>
                                                  {data.special_code}
                                                </label>
                                              </Col>
                                              <Col span={6}>
                                                <label>
                                                  {data.product &&
                                                    data.product.country_made
                                                    ? country(
                                                      data.product
                                                        .country_made
                                                    )
                                                    : ""}
                                                </label>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col>
                                            <Row
                                              type="flex"
                                              justify="space-between"
                                              align="top"
                                              className="amount-qty-cbm"
                                            >
                                              <Col span={11}>
                                                <label>amount:</label> <br />
                                                <span>
                                                  $&nbsp;
                                                  {numFormat(
                                                  "dot/comma",
                                                  data.selling_price *
                                                  data.quantity
                                                )}
                                                </span>
                                              </Col>
                                              <Col span={7}>
                                                <label>cbm:</label>
                                                <br />
                                                <span>
                                                  {numberFormat(
                                                    data.cbm * data.quantity
                                                  )}
                                                </span>
                                              </Col>
                                              <Col span={6}>
                                                <label>qty:</label>
                                                <br />
                                                <span>
                                                  {parseInt(
                                                    data.quantity
                                                  ).toLocaleString()}
                                                </span>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </>
                                  );
                                }
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* footer */}
                    <Row type="flex">
                      <Col span={24}>
                        <Row type="flex" justify="center" align="top">
                          <Col span={22}>
                            <Row type="flex" justify="center" align="top">
                              <Col span={10}>
                                <label>total amount</label>
                                <span>
                                  $&nbsp;
                                  {numFormat(
                                  "dot/comma",
                                  this.state.productDataForModal
                                    .totalSellingPrice
                                )}
                                </span>
                              </Col>
                              <Col span={7}>
                                <label>total cbm</label>
                                <span>
                                  {numFormat(
                                    "dot/comma",
                                    this.state.productDataForModal.totalCbm
                                  )}
                                </span>
                              </Col>
                              <Col span={7}>
                                <label>total qty.</label>
                                <span>
                                  {numFormat(
                                    "comma",
                                    this.state.productDataForModal.totalQty
                                  )}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row type="flex" justify="center" align="middle">
                          <Col span={21} align="center">
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                              className="cancel-confirm-btns"
                            >
                              <Col span={12}>
                                <Button
                                  className="btn-small btn-default"
                                  disabled={this.state.isOrderFormLoading}
                                  onClick={() => {
                                    this.setState({
                                      isOrderProductModalShow: false,
                                      isOrderDeliveryModalShow: true,
                                    });
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Col>
                              <Col span={12} align="center">
                                <Button
                                  id="Thank_you_for_your_order"
                                  loading={this.state.isOrderFormLoading}
                                  className="btn-small btn-primary"
                                  onClick={() => this.onOrderFormSubmit()}
                                >
                                  {this.state.isOrderFormLoading
                                    ? null
                                    : "Confirm Order"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </MediaQuery>
            </Modal>
          )}
        </Layout>
      </DocumentMeta>
    );
  }
}

const selector = formValueSelector("productOrderForm");

const mapStateToProps = (state, ownProps) => {
  let {
    products,
    productCategories,
    productPriceList,
    drafts,
    distributor: { data: distributor },
    permissions,
    productOrder,
  } = state;
  let prefix_number =
    productOrder && productOrder.data && productOrder.data.prefix_number
      ? productOrder.data.prefix_number
      : 0;
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  let number = parseInt(prefix_number) + 1;
  let newPad = zeroPad(number, 2);
  let newPoRefNo = makePONumber(distributor, newPad);

  return {
    products,
    distributor,
    myPermissions: permissions.data,
    myPermissionsIsFetching: permissions.fetching,
    productsIsFetching: products.fetching,
    priceListIsFetching: productPriceList.fetching,
    drafts,
    categories: productCategories,
    priceList: productPriceList,
    formValues: { orders: selector(state, "orders") },
    productOrderLoading: productOrder.fetching,
    po_ref_no: newPoRefNo,
    initialValues: {
      po_ref_no: newPoRefNo,
      distributor_id: "",
      distributor_address_id: "",
      distributor_contact_id: "",
      status: 1,
      special_instructions: "",
      orders: _.map(
        productPriceList.data,
        ({
          product_id,
          product_variant_id,
          selling_price,
          variant,
          quantity,
        }) => ({
          product_id,
          product_variant_id,
          selling_price,
          cbm: variant && variant.cbm ? variant.cbm : 0,
          gross_weight: variant && variant.gross_weight ? variant.gross_weight : 0,
          quantity: quantity ? quantity : 0,
        })
      ),
    },
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  fetchProductPriceList,
  fetchDistributor,
  createProductOrder,
  fetchPermissions,
  fetchDraftById,
  saveAsDraft,
  fetchDrafts,
  fetchPoRefNo,
  postPoRefNo,
  postFeedback,
  isCustomerPoNoUnique,
})(reduxForm({ form: "productOrderForm", enableReinitialize: true })(Order));
