import * as _ from 'lodash';
import { FETCH_PRODUCT_CATEGORIES, FETCH_PRODUCT_CATEGORIES_FULFILLED, FETCH_PRODUCT_CATEGORIES_REJECTED } from '../actions';

const categories = [{
  id: 1,
  title: 'All',
  children: []
}, {
  id: 2,
  title: 'Jack n\' Jill Snacks',
  children: [{
    id: 9,
    title: 'Chippy',
    children: []
  }, {
    id: 10,
    title: 'Piattos',
    children: []
  }, {
    id: 12,
    title: 'Nova',
    children: []
  }, {
    id: 13,
    title: 'Mr. Chips',
    children: []
  }, {
    id: 14,
    title: 'Potato Chips',
    children: []
  }, {
    id: 15,
    title: 'Roller Coaster',
    children: []
  }]
}, {
  id: 3,
  title: 'Jack n\' Jill Bakery',
  children: []
}, {
  id: 4,
  title: 'Jack n\' Jill Confectionary',
  children: []
}, {
  id: 5,
  title: 'Powdered Beverages',
  children: []
}, {
  id: 6,
  title: 'RTD Beverages',
  children: []
}, {
  id: 7,
  title: 'Noodles',
  children: []
}, {
  id: 8,
  title: 'Private Label',
  children: []
}];

export default (state = {
  sandbox: categories,
  data: null,
  fetching: false,
  fetched: false,
  error: null
}, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_CATEGORIES:
      return { ...state, fetching: true, fetched: false, error: null }
    case FETCH_PRODUCT_CATEGORIES_FULFILLED:
      return { ...state, fetching: false, fetched: true, error: null, data: Object.assign(state.data || {}, _.mapKeys(action.payload, 'id')) };
    case FETCH_PRODUCT_CATEGORIES_REJECTED:
      return { ...state, fetching: false, fetched: false, error: action.payload, data: null };

    default:
      return state;
  }
}
