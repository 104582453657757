import React from 'react'
import { connect } from 'react-redux';
import { Form, Input, Button, Modal, Row, Col, Upload } from 'antd';
import { submitBulkUpload } from '../../../actions'
import { withRouter } from "react-router-dom";
import moment from 'moment'
import validator from './csvUploadValidators.js'
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import success from "../../../assets/icons/check-icon.png";
import JSZip from 'jszip'
import ExcelJS from 'exceljs'
const wbOrder = new ExcelJS.Workbook()
const wbHeader = new ExcelJS.Workbook()
class csvUpload extends React.Component {
    constructor() {
        super()
        this.state = {
            fileList: [],
            validationError: [],
            submitLoading: false,
            uploading: false,
        };
    }

    parse(e) {
        return new Promise(resolve => {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            // let returnArray = []
            reader.onload = (e) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { range: 1 });
                /* Update state */
                resolve(data)
                // returnArray = data
            };

            if (rABS) {
                reader.readAsBinaryString(e.originFileObj);
            } else {
                reader.readAsArrayBuffer(e.originFileObj);
            };
            // return returnArray
        });

        // return new Promise(resolve => {
        //     Papa.parse(file, {
        //         header: true,
        //         skipEmptyLines: true,
        //         complete: function (results) {
        //             resolve(results.data);
        //         }
        //     });
        // });
    }

    summaryQuantityByMaterialCode = (csvData) => {
        csvData.map((items, i) => {
            let duplicates = [];
            let uniques = [];
            let valueCounts = {};

            function getDuplicatesAndUniques(array, value) {
                for (var i = 0; i < array.length; i++) {
                    if (!valueCounts[array[i][value]])
                        valueCounts[array[i][value]] = 1;
                    else
                        valueCounts[array[i][value]]++;
                }

                for (var i = 0; i < array.length; i++) {
                    if (valueCounts[array[i][value]] == 1) {
                        uniques.push(array[i]);
                    } else {
                        duplicates.push(array[i]);
                    }
                }
            }

            var dups = [];
            var arr = items.po_items.filter(function (el) {
                if (dups.indexOf(el.material_code) == -1) { dups.push(el.material_code); return true; } return false;
            });
            getDuplicatesAndUniques(items.po_items, "material_code");
            let storeData = { material_code: '', quantity: 0, customer_po_number: '' }
            duplicates.map((items) => {
                storeData.quantity = parseInt(items.quantity) + parseInt(storeData.quantity)
                storeData.material_code = items.material_code
                storeData.customer_po_number = items.customer_po_number
            })
            csvData[i].po_items = arr
            csvData[i].po_items.map((items) => {
                if (items.material_code === storeData.material_code && items.customer_po_number === storeData.customer_po_number) {
                    items.quantity = storeData.quantity
                }
            })
            // return arr
        })
        return csvData
    }

    handleChange = info => {
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return (file);
        });

        this.setState({ fileList, validationError: [] });
    };

    handleSubmit = () => {
        this.setState({ validationError: [], submitLoading: true })
        const { fileList } = this.state;

        // to check if the file is in CSV format
        const validateFileType = validator.validateFileType(fileList)
        if (validateFileType.length !== 0) {
            this.setState({ validationError: validateFileType, submitLoading: false })
            return
        }

        // to validate file name 
        const validateFileName = validator.validateFileName(fileList)
        if (validateFileName.length !== 0) {
            this.setState({ validationError: validateFileName, submitLoading: false })
            return
        }

        const poHeader = fileList.find((item) => item.name === 'PO_HEADER.xlsx')
        let poOrder = fileList.find((item) => item.name === 'PO_ORDER.xlsx')
        let csvData = []
        this.parse(poHeader).then((fromHeaderData) => {
            this.parse(poOrder).then((fromOrderData) => {
                fromOrderData = fromOrderData.filter((data) => data['QUANTITY'] !== 0)
                fromHeaderData.map((headerData) => {
                    const orderData = []
                    fromOrderData.map((orderItem, i) => {
                        const index = i + 3 // not included the header in csv.
                        orderData.push({
                            "row": index,
                            "customer_po_number": orderItem['CUSTOMER_PO_NUMBER'],
                            "quantity": orderItem['QUANTITY'],
                            "material_code": orderItem['MATERIAL_CODE']
                        })
                    })
                    const filteredByPoReference = orderData.filter((orderData) => orderData['customer_po_number'].toString().toUpperCase() === headerData['CUSTOMER_PO_NUMBER'].toString().toUpperCase())
                    csvData.push({
                        "po_header": {
                            "customer_po_number": headerData['CUSTOMER_PO_NUMBER'],
                            "special_instructions": headerData['SPECIAL_INSTRUCTIONS'],
                            "port_destination": headerData['PORT_DESTINATION'],
                            "etd_date": headerData['ETD_DATE'] ? moment(headerData['ETD_DATE']).format('YYYY-MM-DD HH:mm:ss') : undefined,
                            "etd_date_check": headerData['ETD_DATE']
                        },
                        "po_items": filteredByPoReference
                    })
                })

                const validateCSV = validator.validateCSV(csvData)
                if (validateCSV.length !== 0) {
                    this.setState({ validationError: validateCSV, submitLoading: false })
                    return
                }

                const newCsvData = this.summaryQuantityByMaterialCode(csvData)
                this.props.onSubmitBulkUpload(newCsvData).then((res) => {
                    if (res.error) {
                        const { err: { response: { data: { error } } } } = res
                        const responseErrorMessage = validator.responseErrorMessage(error)
                        if (responseErrorMessage.length !== 0) {
                            this.setState({ validationError: responseErrorMessage, submitLoading: false })
                            return
                        }
                    } else {
                        this.props.csvModalVisible()
                        Modal.success({
                            // title: "Draft!",
                            content: "Great! All files were successfully uploaded.",
                            okText: ' Okay',
                            className: 'bulk-upload-success-modal',
                            icon: <img src={success} alt="success" />,
                        });

                        this.props.history.push('/drafts')
                    }
                    this.setState({ submitLoading: false })
                })
            })
        })
    };

    async handleDownload() {

        const worksheetOrder = wbOrder.addWorksheet(`${'PO_ORDERS'}`)

        // Width of  column
        worksheetOrder.getColumn('A').width = 16
        worksheetOrder.getColumn('B').width = 16
        worksheetOrder.getColumn('C').width = 16
        worksheetOrder.getColumn('D').width = 16
        worksheetOrder.getColumn('E').width = 35
        worksheetOrder.getColumn('F').width = 20
        worksheetOrder.getColumn('G').width = 20
        worksheetOrder.getColumn('H').width = 20
        worksheetOrder.getColumn('I').width = 20
        worksheetOrder.getColumn('J').width = 20

        //Header
        worksheetOrder.mergeCells('A1:J1');
        worksheetOrder.getCell('F1').value = 'PO ORDER';
        worksheetOrder.getCell('F1').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('F1').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('F1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('A2').value = 'CUSTOMER_PO_NUMBER';
        worksheetOrder.getCell('A2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('A2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('A2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('B2').value = 'MATERIAL_CODE';
        worksheetOrder.getCell('B2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('B2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('B2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('C2').value = 'QUANTITY';
        worksheetOrder.getCell('C2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('C2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('C2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('D2').value = 'CUSTOMER SKU CODE';
        worksheetOrder.getCell('D2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('D2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('D2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('E2').value = 'PRODUCT DESCRIPTION';
        worksheetOrder.getCell('E2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('E2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('E2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('F2').value = 'PACKING SCHEME';
        worksheetOrder.getCell('F2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('F2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('F2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('G2').value = 'SKU TYPE';
        worksheetOrder.getCell('G2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('G2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('G2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('H2').value = 'PRICE / CASE (IN USD)';
        worksheetOrder.getCell('H2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('H2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('H2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('I2').value = 'CBM / CASE';
        worksheetOrder.getCell('I2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('I2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('I2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        worksheetOrder.getCell('J2').value = 'TOTAL CBM';
        worksheetOrder.getCell('J2').alignment = { horizontal: 'center' };
        worksheetOrder.getCell('J2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetOrder.getCell('J2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };

        const lastRow = 3
        this.props.distributorProduct.map((productItem, i) => {
            worksheetOrder.getCell((`A${lastRow + i}`).toString()).value = ``;
            worksheetOrder.getCell((`B${lastRow + i}`).toString()).value = `${(productItem && productItem.variant && productItem.variant.material_code) || ''}`;
            worksheetOrder.getCell((`C${lastRow + i}`).toString()).value = 0;
            worksheetOrder.getCell((`D${lastRow + i}`).toString()).value = `${(productItem && productItem.special_code) || ''}`;
            worksheetOrder.getCell((`E${lastRow + i}`).toString()).value = `${(productItem && productItem.variant && productItem.variant.name) || ''}`;
            worksheetOrder.getCell((`F${lastRow + i}`).toString()).value = `${(productItem && productItem.variant && productItem.variant.packing_scheme) || ''}`;
            worksheetOrder.getCell((`G${lastRow + i}`).toString()).value = `N/A`;
            worksheetOrder.getCell((`H${lastRow + i}`).toString()).value = `${(productItem && productItem.selling_price) || ''}`
            worksheetOrder.getCell((`I${lastRow + i}`).toString()).value = `${(productItem && productItem.variant && productItem.variant.cbm) || ''} `;
            worksheetOrder.getCell((`J${lastRow + i}`).toString()).value = { formula: `C${lastRow + i} * ${(productItem && productItem.variant && productItem.variant.cbm) || ''}` };
        })


        const worksheetHeader = wbHeader.addWorksheet(`${'PO_HEADER'}`)

        // Width of  column
        worksheetHeader.getColumn('A').width = 20
        worksheetHeader.getColumn('B').width = 20
        worksheetHeader.getColumn('C').width = 20
        worksheetHeader.getColumn('D').width = 20
        worksheetHeader.getColumn('E').width = 20
        worksheetHeader.getColumn('F').width = 20
        worksheetHeader.getColumn('G').width = 20
        worksheetHeader.getColumn('H').width = 20
        worksheetHeader.getColumn('I').width = 20
        worksheetHeader.getColumn('J').width = 20
        worksheetHeader.getColumn('K').width = 20

        //Header
        worksheetHeader.mergeCells('A1:J1');
        worksheetHeader.getCell('F1').value = 'PO HEADER';
        worksheetHeader.getCell('F1').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('F1').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('F1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        // worksheetHeader.getCell('A2').value = 'PO_REFERENCE';
        // worksheetHeader.getCell('A2').alignment = { horizontal: 'center' };
        // worksheetHeader.getCell('A2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        // worksheetHeader.getCell('A2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('A2').value = 'CUSTOMER_PO_NUMBER';
        worksheetHeader.getCell('A2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('A2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('A2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('B2').value = 'SPECIAL_INSTRUCTIONS';
        worksheetHeader.getCell('B2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('B2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('B2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('C2').value = 'PORT_DESTINATION';
        worksheetHeader.getCell('C2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('C2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('C2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('D2').value = 'ETD_DATE';
        worksheetHeader.getCell('D2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('D2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('D2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('E2').value = 'DISTRIBUTOR NAME';
        worksheetHeader.getCell('E2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('E2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('E2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('F2').value = 'P.O DATE';
        worksheetHeader.getCell('F2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('F2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('F2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('G2').value = 'INTERCOMS';
        worksheetHeader.getCell('G2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('G2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('G2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('H2').value = 'PAYMENT TERMS';
        worksheetHeader.getCell('H2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('H2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('H2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('I2').value = 'LOADING DATE';
        worksheetHeader.getCell('I2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('I2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('I2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        worksheetHeader.getCell('J2').value = 'ETA DESTINATION';
        worksheetHeader.getCell('J2').alignment = { horizontal: 'center' };
        worksheetHeader.getCell('J2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
        worksheetHeader.getCell('J2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff0000' } };

        const lastRowHeader = 3
        for (var i = 0; i < 100; i++) {
            worksheetHeader.getCell(`C${i + lastRowHeader}`).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: [`"${this.props.distributor.port_destination.map(data => data.replace(/,/g, '')).toString()}"`]
            };
        }

        const order = await wbOrder.xlsx.writeBuffer()
        const header = await wbHeader.xlsx.writeBuffer()
        // saveAs(new Blob([buf]), 'Order-upload.xlsx')
        var zip = new JSZip();
        var pos = zip.folder("PO_HEADER_AND_PO_ORDER");
        pos.file("PO_ORDER.xlsx", order, { base64: true });
        pos.file("PO_HEADER.xlsx", header, { base64: true });
        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, "PO_HEADER_AND_PO_ORDER.zip");
            });

        wbOrder.removeWorksheet(worksheetOrder.id)
        wbHeader.removeWorksheet(worksheetHeader.id)
    }

    render() {
        const { uploading, fileList, submitLoading } = this.state;
        const uploadProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1)

                    return {
                        headerData: [],
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            onChange: this.handleChange,
            fileList,
        };
        return (
            <div className='bulk-upload-container'>

                <Modal
                    className="bulk-upload-content"
                    // title={<span>Bulk Upload</span>}
                    visible={this.props.visible}
                    onOk={this.props.csvModalVisible}
                    onCancel={this.props.csvModalVisible}
                    footer={null}
                    closable={false}
                    width={695}
                >
                    <div className='bulk-upload-container'>

                        <div className='bulk-header'>
                            <h4>Bulk Uploader</h4>
                        </div>

                        <div className='bulk-description'>
                            <span> Choose the Drag and Drop the chosen .xlsx file to be uplaoded below.</span>
                            <span> After choosing the selected XLSX file, press the upload button to upload the files</span>
                            <span> Please take note that only two(2) files can be uploaded at once.</span>
                        </div>

                        <div className='bulk-sample-download'>
                            {/* <a href={RarDownloadSampleCSV} download  >Download Sample RAR File for Headers and Orders</a> */}
                            <a onClick={() => this.handleDownload()} >Download Compressed Sample File for Headers and Orders </a>
                        </div>

                        {this.state.validationError.length !== 0 &&
                            <div className='bulk-error'>
                                <h4>WARNING:</h4>
                                {this.state.validationError.map((error, i) => {
                                    return (
                                        <span key={i}>• {error.text}</span>
                                    )
                                })}
                            </div>
                        }

                        <Row>
                            <Col span={24} align="center">

                                <div className='uploader-btn'>
                                    <Upload {...uploadProps} directory={true} fileList={this.state.fileList}
                                        multiple={false}
                                    // disabled={this.state.fileList.length === 2}
                                    >
                                        <Button >
                                            <span>
                                                Upload
                                            </span>
                                        </Button>
                                    </Upload>
                                </div>

                                <div className='bulk-submit'>
                                    <Button
                                        className='clear-btn'
                                        type="primary"
                                        onClick={() => this.setState({ validationError: [], fileList: [] })}
                                        style={{ marginTop: 16 }}
                                    >
                                        Clear Files
                                    </Button>
                                    <Button
                                        className='submit-btn'
                                        type="primary"
                                        onClick={this.handleSubmit}
                                        loading={uploading}
                                        style={{ marginTop: 16 }}
                                        loading={submitLoading}
                                    >
                                        Submit
                                    </Button>
                                </div>

                            </Col>
                        </Row>
                    </div>

                </Modal>
            </div >
        );
    }
}

const csvUploadForm = Form.create({ name: 'csv_upload' })(csvUpload);

function mapStateToProps(state) {
    return {
        // this is temporary fix for empty selling_price and variant 
        // empty selling_price and variant should not be return by backend
        distributorProduct: state.productPriceList.pricelistData.filter(data => (data.variant !== null && data.selling_price !== 0)),
        // distributorProduct: state.productPriceList.pricelistData,
        distributor: state.distributor.data.distributor
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitBulkUpload: (data) => dispatch(submitBulkUpload(data))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(csvUploadForm));