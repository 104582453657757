import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Modal, Input, Button, Layout, Row, Col, Menu, Icon, Select, Spin, Skeleton, Result } from 'antd'
import { useMediaQuery } from 'react-responsive'
import {
  fetchPermissions,
  fetchDistributor,
  updateDistributor,
  submitImage,
} from '../../actions'
import {
  fetchMail,
  removeAdditionalEmail,
  updateAdditionalEmail,
  submitAdditionalEmail
} from '../../actions/additionalEmail'
import ImageUploader from '../shared/ImageUploader'
import DocumentMeta from "react-document-meta";
import { getCode, getNames } from 'country-list'
import error from "../../assets/icons/error-icon.png";
import success from "../../assets/icons/check-icon.png";

// Import CSS
import './UserProfile.scss'
const { Option } = Select;
// Will Refactor after presentation------>
// Will Remove hardcoded css
// Will Support Mobile Responsive
let addressId = 0;
let portOfDestinationId = 0
let additionalMailId = 0
const { Content } = Layout
class UserProfile extends Component {
  constructor() {
    super()
    this.imageUploader = React.createRef();
    this.state = {
      isShowUploadBtn: false,
      isShowUploadLoading: false,
      isSubmitLoading: false,
      updateEmail: ''
    }
  }

  removeAddress = k => {
    const { form } = this.props;
    const keyAddress = form.getFieldValue('keyAddress');
    if (keyAddress.length === 1) {
      return;
    }

    form.setFieldsValue({
      keyAddress: keyAddress.filter(key => key !== k),
    });
  };

  addAddress = () => {
    const { form } = this.props;
    const keyAddress = form.getFieldValue('keyAddress');
    const nextKeyAddress = keyAddress.concat(addressId++);
    form.setFieldsValue({
      keyAddress: nextKeyAddress,
    });
  };

  removePortOfDestination = k => {
    const { form } = this.props;
    const keyPortOfDestination = form.getFieldValue('keyPortOfDestination');
    if (keyPortOfDestination.length === 1) {
      return;
    }

    form.setFieldsValue({
      keyPortOfDestination: keyPortOfDestination.filter(key => key !== k),
    });
  };

  // handleAdditionalEmailChange = e => {
  //   const { form } = this.props;
  //   const keyAdditionalMail = form.getFieldValue('keyAdditionalMail');
  //   form.setFieldsValue({
  //     keyAdditionalMail: e.target.value
  //   });
  // }

  // updateAdditionalMail = (id, k) => {

  //   const { form } = this.props;
  //   const keyAdditionalMail = form.getFieldValue('keyAdditionalMail');
  //   if (keyAdditionalMail.length === 1) {
  //     return;
  //   }
  //   const keyAdditionalMailID = form.getFieldValue('keyAdditionalMailID');
  //   if (keyAdditionalMailID.length === 1) {
  //     return;
  //   }

  //   const data = { id: id, email: k }

  //   if (id && k && this.validateEmail(k)) {
  //     this.props.updateAdditionalEmail(data)
  //   }

  // };

  removeAdditionalMail = k => {
    const { form } = this.props;
    const keyAdditionalMail = form.getFieldValue('keyAdditionalMail');
    if (keyAdditionalMail.length === 1) {
      return;
    }

    const data = { email: k }
    if (k && this.validateEmail(k)) {
      this.props.removeAdditionalEmail(data)
    }

    form.setFieldsValue({
      keyAdditionalMail: keyAdditionalMail.filter(key => key !== k),
    });

  };

  addAdditionalMail = () => {
    const { form } = this.props;
    const keyAdditionalMail = form.getFieldValue('keyAdditionalMail');
    const nextKeyAdditionalMail = keyAdditionalMail.concat(additionalMailId++);
    form.setFieldsValue({
      keyAdditionalMail: nextKeyAdditionalMail,
    });
  };

  addPortOfDestination = () => {
    const { form } = this.props;
    const keyPortOfDestination = form.getFieldValue('keyPortOfDestination');
    const nextKeyPortOfDestination = keyPortOfDestination.concat(portOfDestinationId++);
    form.setFieldsValue({
      keyPortOfDestination: nextKeyPortOfDestination,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    const keyAdditionalMailID = form.getFieldValue('keyAdditionalMailID');

    this.setState({ isSubmitLoading: true })
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { mobile, email, customer_code, country, company_landline, address, port_destination, additional_mail } = values;
        address = (address) ? address.filter(function (e) { return e }) : []
        port_destination = (port_destination) ? values.port_destination.filter(function (e) { return e }).map(data => data.replace(/,/g, '')) : []
        additional_mail = (additional_mail) ? values.additional_mail.filter(function (e) { return e }) : []
        const submitData = {
          mobile, email, customer_code, company_landline, port_destination, address, country, additional_mail
          // name, mobile, email, customer_code, country, company_landline, port_destination, address
        }
        const submitMail = { additional_mail }

        submitMail.additional_mail.forEach((res, index) => {
          if (this.validateEmail(res)) {
            const data = { email: res }
            this.props.submitAdditionalEmail(data)
          }

          const data = { id: keyAdditionalMailID[index], email: res }
          this.props.updateAdditionalEmail(data)
        })



        this.props.updateDistributor(submitData)
          .then(response => {
            if (response.error) {
              Modal.error({
                title: 'Unable to update your info. Please try again.',
                content: '',
                icon: <img src={error} alt="error" />
              })
            } else {
              Modal.success({
                title: 'Information Saved...',
                content: '',
                icon: <img src={success} alt="success" />
              })
            }
          })
          .then(() => {
            this.props.fetchDistributor()
            this.setState({ isSubmitLoading: false })
          })
      }
    });
  };

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  componentDidMount() {
    this.props.fetchPermissions()

    // this.props.fetchMail()
    //   .then((response) => {
    //     if (!response.error) {

    //       let additionalMailArray = []
    //       let additionalMail = (this.props.mail) ? this.props.mail : []
    //       additionalMail.map(data => {
    //         if (data.status === 0) {
    //           additionalMailArray.push(data.email)
    //         }
    //       })

    //       const { form } = this.props;
    //       form.setFieldsValue({
    //         keyAdditionalMail: additionalMail
    //       });
    //     }
    //   })
    this.props.fetchDistributor()
      .then((response) => {
        if (!response.error) {
          let distributorAddressArray = []
          this.props.distributor && this.props.distributor.address.map(data => {
            distributorAddressArray.push(data)
          })

          let portOfDestinationArray = []
          let portOfDestination = (this.props.distributor.port_destination) ? this.props.distributor.port_destination : []
          portOfDestination.map(data => {
            if (data.status === 0) {
              portOfDestinationArray.push(data)
            }
          })

          let additionalMailArray = []
          let additionalMail = (this.props.mail) ? this.props.mail.map((value) => { return value.email }) : []
          additionalMail.map(data => {
            if (data.status === 0) {
              additionalMailArray.push(data.email)
            }
          })


          let additionalMailIDArray = []
          let additionalMailID = (this.props.mail) ? this.props.mail.map((value) => { return value.id }) : []
          additionalMailID.map(data => {
            if (data.status === 0) {
              additionalMailIDArray.push(data.id)
            }
          })

          const { form } = this.props;
          form.setFieldsValue({
            keyAddress: distributorAddressArray,
            keyPortOfDestination: portOfDestination,
            keyAdditionalMail: additionalMail,
            keyAdditionalMailID: additionalMailID
          });
        }
      })
  }

  displayContact(type) {
    const { distributor } = this.props
    const dataArray = []
    distributor !== '' && distributor.contact.map((data) => {
      if (data.type === type) dataArray.push(data)
    })
    return dataArray
  }

  displayAddress(type) {
    const { distributor } = this.props
    const dataArray = []
    distributor !== '' && distributor.address.map((data) => {
      if (data.type === type) dataArray.push(data)
    })
    return dataArray
  }

  isMobile() {
    return useMediaQuery({
      query: '(max-device-width: 810px)'
    })
  }


  handleSubmitImage = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isShowUploadLoading: true })
        let media = null;
        let imageUrl =
          this.imageUploader.current === null
            ? ""
            : this.imageUploader.current.getImageUrl();
        media =
          this.imageUploader.current === null
            ? ""
            : await this.imageUploader.current.handleUploadRequest();
        const image = media ? media.url : imageUrl;
        await this.props.submitImage({ image: image })
          .then((response) => {
            if (response.error) {
              Modal.error({
                title: 'Unable to update Image Profile.',
                content: '',
                icon: <img src={error} alt="error" />
              })
            } else {
              Modal.success({
                title: 'Image Profile has been updated successfully',
                content: '',
                icon: <img src={success} alt="success" />
              })
            }
            this.setState({ isShowUploadLoading: false })
          })
      }
    });
  };

  render() {
    const {
      myPermissions, myPermissionsIsFetching
    } = this.props
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator('keyAddress', { initialValue: [] });
    getFieldDecorator('keyPortOfDestination', { initialValue: [] });
    getFieldDecorator('keyAdditionalMail', { initialValue: [] });
    getFieldDecorator('keyAdditionalMailID', { initialValue: [] });
    const keyAddress = getFieldValue('keyAddress');
    const keyPortOfDestination = getFieldValue('keyPortOfDestination');
    const keyAdditionalMail = getFieldValue('keyAdditionalMail');
    const keyAdditionalMailID = getFieldValue('keyAdditionalMailID');
    const AddressFormItems = keyAddress.map((k, index) => (
      <Col lg={24} md={24} sm={24} key={`address-${index}`}>
        <Form.Item
          required={false}
          key={`address-${index}`}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Address {index + 1}</h4>
            {keyAddress.length > 1 ? (
              //Delete Address Should be hide for now. because distributor profile should not/or delete address. Note: delete visibility in CSS 
              <h5 onClick={() => this.removeAddress(k)} style={{ color: '#7F7F7F', display: 'block', cursor: 'pointer', visibility: 'hidden' }}>
                <Icon
                  style={{ margin: '12px' }}
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                /> Delete This Address
              </h5>
            ) : null}
          </div>
          {getFieldDecorator(`address[${k.id || k}]`, {
            validateTrigger: ['onChange', 'onBlur'],
            initialValue: `${k.name || ''}`,
            rules: [
              {
                whitespace: true,
                message: "Please input address.",
              },
            ],
          })(<Input placeholder={`Address ${index + 1}`} style={disabledInputStyle} disabled />)}
        </Form.Item></Col>
    ));

    const portOfDestinationFormItems = keyPortOfDestination.map((k, index) => (
      <Col lg={24} md={24} sm={24} key={`pod-${index}`} >
        <Form.Item
          required={false}
          key={`pod-${index}`}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Port Of Destination {index + 1}</h4>
            {keyPortOfDestination.length > 1 ? (
              <h5 onClick={() => this.removePortOfDestination(k)} style={{ color: '#BF914A', display: 'block', cursor: 'pointer' }}>
                <Icon
                  style={{ margin: '12px' }}
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                /> Delete This Port Of Destination
              </h5>
            ) : null}
          </div>
          {getFieldDecorator(`port_destination[${index}]`, {
            validateTrigger: ['onChange', 'onBlur'],
            initialValue: `${typeof k === 'string' || k instanceof String ? k : '' || ''}`,
            rules: [
              {
                whitespace: true,
                message: "Please input port of destination.",
              },
            ],
          })(<Input
            disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
            placeholder={`Port Of Destination ${index + 1}`} style={inputStyle}
          />)}
        </Form.Item></Col>
    ));

    const additionalMailFormItems = keyAdditionalMail.map((k, index) => (

      <Col lg={24} md={24} sm={24} key={`am-${index}`} >
        <Form.Item
          required={false}
          key={`pod-${index}`}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Additional Email Address {index + 1}</h4>
            {keyAdditionalMail.length > 1 ? (
              <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* <h5 onClick={() => this.updateAdditionalMail(keyAdditionalMailID[index], k)} style={{ color: '#52C41A', display: 'block', cursor: 'pointer' }}>
                  <Icon
                    style={{ margin: '12px' }}
                    className="dynamic-save-button"
                    type="check-circle-o"
                  /> Save Changes
                  </h5> */}
                <h5 onClick={() => this.removeAdditionalMail(k)} style={{ color: '#BF914A', display: 'block', cursor: 'pointer' }}>
                  <Icon
                    style={{ margin: '12px' }}
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                  /> Delete Email Address
                  </h5>
              </span>
            ) : null}
          </div>
          {getFieldDecorator(`additional_mail[${index}]`, {
            validateTrigger: ['onChange', 'onBlur'],
            initialValue: `${this.validateEmail(k) ? k : '' || ''}`,
            rules: [
              {
                whitespace: true,
                message: "Please input additional email address.",
              },
            ],
            // onChange: (e) => this.handleAdditionalEmailChange(e, `additional_mail[${index}]`)
          })(<Input
            disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
            placeholder={`Additional Email Address ${index + 1}`} style={inputStyle}
          />)}
        </Form.Item></Col>
    ));

    const meta = {
      title: "User Profile - URC Global Exports",
      description: "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "User Profile"
        }
      }
    };

    if (myPermissionsIsFetching) {
      return (
        <DocumentMeta {...meta}>
          <Layout>
            <Spin spinning={myPermissionsIsFetching}>
              <Skeleton active />
            </Spin>
          </Layout>
        </DocumentMeta>
      )
    }

    return (
      <DocumentMeta {...meta}>
        <Layout>
          {myPermissions.some(r => r.name === 'distributor.orders.view') ? <Layout>
            <Content className='user-profile-container'>
              <Layout>
                <Row type='flex' className='user-profile-card'>
                  <Col lg={6} md={24} sm={24}>
                    <Content className='side-container'>
                      <Content>
                        <div className='user-profile-sider-header'>
                          {/* <div className="circle-logo" /> */}
                          <div className='test' style={{ width: '100%', position: 'absolute', top: '45px', height: '180px', textAlign: 'center' }}>
                            <Form onSubmit={e => this.handleSubmitImage(e)}>
                              <div style={{ textAlign: 'center' }}>
                                <ImageUploader
                                  ref={this.imageUploader}
                                  fileFolder={`products`}
                                  // buttonDesc="Click here to choose image"
                                  imageUrl={this.props.distributor.image}
                                  handleOnChange={() => this.setState({ isShowUploadBtn: true })}
                                />
                              </div>
                              {/* {(this.imageUploader) && this.imageUploader.current && (this.props.distributor.image !== this.imageUploader.current.getImageUrl()) ? */}
                              <div style={{ margin: 0 }}>
                                {this.state.isShowUploadBtn ?
                                  <Button
                                    htmlType='submit'
                                    icon="save"
                                    className="btn-small btn-primary"
                                    loading={this.state.isShowUploadLoading}
                                  >
                                    {this.state.isShowUploadLoading ? null : "Save"}
                                  </Button> : null
                                }
                              </div>

                              {/* } */}
                            </Form>
                          </div>
                        </div>
                      </Content>
                      <div className='sider-content'>
                        <div className='sider-content-header'>
                          <h1>{this.props.distributor.name}</h1>
                          {/* <h5>Add New Photo > </h5> */}
                        </div>
                        <Content>
                          <Menu
                            mode="inline"
                            style={{ borderRight: 0, background: '#FCF9F4' }}
                            defaultSelectedKeys={['1']}
                          >
                            {/* {this.renderFilterOptions(filterOptions.data)} */}
                            <Menu.Item key="1"> <span className='sidebar-label'>COMPANY INFORMATION</span></Menu.Item>
                            <Menu.Item key="2" onClick={() => this.props.history.push('/confirm-password')}> <span className='sidebar-label'>CHANGE PASSWORD</span></Menu.Item>

                          </Menu>
                        </Content>
                      </div>
                    </Content>
                  </Col>
                  <Col lg={18} md={24} sm={24}>
                    <Content className='user-profile-content'>
                      <Content>
                        <div className='user-profile-header'>
                          <div className='user-profile-header-text'>
                            <h1>User Profile</h1>
                            <h2>
                              {/* Edit Information > */}
                            </h2>
                          </div>
                        </div>
                      </Content>
                      <Content className='content-scrollable'>
                        <div className='user-profile-text-header'>
                          <h1>Company Information</h1>
                        </div>
                        <Form className='user-profile-form' onSubmit={this.handleSubmit}>
                          <Row gutter={30}>
                            <Col md={12} sm={24}>
                              <h4>Company Name</h4>
                              <Input style={disabledInputStyle} placeholder={`Company Name`} value={this.props.distributor.name || 'N/A'} disabled />
                              {/* {getFieldDecorator(`name`, {
                              initialValue: this.props.distributor.name || 'N/A',
                              validateTrigger: ['onChange', 'onBlur'],
                              rules: [
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "Please input  ",
                                },
                              ],
                            })(<Input style={inputStyle} placeholder={`Company Name`} readOnly />)} */}
                            </Col>
                            <Col md={12} sm={24}>
                              <Form.Item className='form-item-container'>
                                <h4>Country</h4>
                                {getFieldDecorator(`country`, {
                                  initialValue: this.props.distributor.country || 'N/A',
                                  validateTrigger: ['onChange', 'onBlur'],
                                  rules: [
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input your Country  ",
                                    },
                                  ],
                                })(
                                  // <Input style={inputStyle} placeholder={`Country`} />
                                  <Select
                                    disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
                                    style={selectStyle}
                                    placeholder={`Country`}>
                                    {getNames().map((country, i) => {
                                      return (
                                        <Option key={i} value={getCode(country)} >{country}</Option>
                                      )
                                    })}
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>
                            <Col md={12} sm={24}>
                              <h4>Distributor Code</h4>
                              {getFieldDecorator(`customer_code`, {
                                initialValue: this.props.distributor.customer_code || 'N/A',
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please input  ",
                                  },
                                ],
                              })(<Input style={disabledInputStyle} placeholder={`Company Name`} disabled />)}
                            </Col>
                            <Col md={12} sm={24}>
                              <h4>Company Landline</h4>
                              {getFieldDecorator(`company_landline`, {
                                initialValue: this.displayContact('office')[0] ? this.displayContact('office')[0]['contact'] : 'N/A',
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please input  ",
                                  },
                                ],
                              })(
                                <Input
                                  disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
                                  style={inputStyle}
                                  placeholder={`Company Landline`} />
                              )}
                            </Col>
                            {/* <Form.item>
                              <Col md={12} sm={24}>
                                <h4>Mobile Number</h4>
                                {getFieldDecorator(`mobile`, {
                                  // initialValue: this.displayContact('mobile')[0] ? this.displayContact('mobile')[0]['contact'] : 'N/A',
                                  // validateTrigger: ['onChange', 'onBlur'],
                                  rules: [
                                    {
                                      required: true,
                                      // whitespace: true,
                                      message: "Please input Mobile Number  ",
                                    },
                                  ],
                                })(<Input style={inputStyle} placeholder={`Mobile Number`} />)}
                              </Col>
                            </Form.item> */}

                            <Col lg={12} md={12} sm={24}>
                              <Form.Item className='form-item-container'>
                                <h4>Mobile Number</h4>
                                {getFieldDecorator('mobile', {
                                  initialValue: this.displayContact('mobile')[0] ? this.displayContact('mobile')[0]['contact'] : 'N/A',
                                  rules: [{ required: true, message: 'Please input your Mobile Number!' }]
                                })(
                                  <Input
                                    disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
                                    style={inputStyle}
                                    placeholder='Mobile Number'
                                  />
                                )}
                              </Form.Item>
                            </Col>
                            <Col md={12} sm={24}>
                              <h4>Email Address</h4>
                              {getFieldDecorator(`email`, {
                                initialValue: this.displayContact('email')[0] ? this.displayContact('email')[0]['contact'] : 'N/A',
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Please input  ",
                                  },
                                ],
                              })(
                                <Input
                                  disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
                                  style={inputStyle}
                                  placeholder={`Email Address`} />
                              )}
                            </Col>
                            {additionalMailFormItems}
                            <Col md={24} sm={24}>
                              <Form.Item>
                                <Button className="btn-small btn-secondary" onClick={this.addAdditionalMail}
                                  disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
                                  style={{ width: '100%' }}>
                                  <Icon type="plus" /> Add Additional Mail
                              </Button>
                              </Form.Item>
                            </Col>

                            {portOfDestinationFormItems}
                            <Col md={24} sm={24}>
                              <Form.Item>
                                <Button className="btn-small btn-secondary" onClick={this.addPortOfDestination}
                                  disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
                                  style={{ width: '100%' }}>
                                  <Icon type="plus" /> Add Port Of Destination
                              </Button>
                              </Form.Item>
                            </Col>
                            {AddressFormItems}
                            {/* <Col md={24} sm={24}>
                            <Form.Item>
                              <Button type="dashed" onClick={this.addAddress} style={{
                                width: '100%',
                                margin: '20px 0px',
                                border: ' 1px solid #BF914A',
                                color: '#BF914A'
                              }}>
                                <Icon type="plus" /> Add Address
                              </Button>
                            </Form.Item>
                          </Col> */}
                          </Row>
                          <Form.Item className='button-container'>
                            <Button htmlType="button" className="btn-small btn-default" disabled={this.state.isSubmitLoading} onClick={() => this.props.history.push('/')} >
                              Cancel
                              </Button>
                            <Button htmlType="submit" loading={this.state.isSubmitLoading}
                              disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
                              className="btn-small btn-primary">
                              {this.state.isSubmitLoading ? null : "Save Changes"}
                            </Button>
                          </Form.Item>
                        </Form>
                      </Content>
                    </Content>
                  </Col>
                </Row>
              </Layout>
            </Content>
          </Layout>
            : <Result
              status="warning"
              title="You have no permission to view this page."
            />
          }
        </Layout >
      </DocumentMeta >
    )
  }
}


const selectStyle = {
  border: 'none',
  outline: '0',
  background: 'transparent',
  borderBottom: '2px solid #DFC8A5',
  borderTop: '10px solid black',
  borderTop: '0',
  boxShadow: '0 2px 2px -2px #DFC8A5',
  padding: 0,
  width: '100%',
  color: '#404040',
  fontSize: '16px',
  paddingBottom: 12,
  fontFamily: 'Montserrat'
}
const inputStyle = {
  border: '0',
  outline: '0',
  background: 'transparent',
  borderBottom: '2px solid #DFC8A5',
  boxShadow: '0 2px 2px -2px #DFC8A5',
  padding: 0,
  width: '100%',
  color: '#404040',
  fontSize: '16px',
  paddingBottom: 12,
  fontFamily: 'Montserrat'
}

const disabledInputStyle = {
  border: '0',
  outline: '0',
  background: 'transparent',
  borderBottom: 'none',
  boxShadow: 'none',
  padding: 0,
  width: '100%',
  color: '#404040',
  fontSize: '16px',
  paddingBottom: 12,
  fontFamily: 'Montserrat'
}

const submitButtonStyle = {
  margin: '10px',
  color: 'white',
  background: '#BF914A',
  padding: '20px',
  border: '1px solid #BF914A',
  borderRadius: '55px',
  lineHeight: '0px',
  minWidth: '130px',
}

const cancelButtonStyle = {
  margin: '10px',
  color: 'gray',
  background: 'white',
  padding: '20px',
  border: '1px solid gray',
  borderRadius: '55px',
  lineHeight: '0px',
  minWidth: '130px',
}

const UserProfileForm = Form.create({ name: 'userProfile_form' })(UserProfile)

function mapStateToProps(state) {
  let {
    permissions,
    distributor,
    mail
  } = state
  mail = (distributor.data) ? distributor.data.mail : ''
  distributor = (distributor.data) ? distributor.data.distributor : ''

  return {
    distributor,
    mail,
    myPermissions: permissions.data,
    myPermissionsIsFetching: permissions.fetching
  }
}

export default connect(mapStateToProps, {
  fetchPermissions,
  fetchDistributor,
  updateDistributor,
  submitImage,
  fetchMail,
  updateAdditionalEmail,
  removeAdditionalEmail,
  submitAdditionalEmail
})(UserProfileForm)
