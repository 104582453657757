import axios from "axios";
import { headers } from "../utilities";
import config from "../config";

export const GET_DATA_FROM_SAP = "GET_DATA_FROM_SAP";
export const GET_DATA_FROM_SAP_FULFILLED = "GET_DATA_FROM_SAP_FULFILLED";
export const GET_DATA_FROM_SAP_REJECTED = "GET_DATA_FROM_SAP_REJECTED";

export const fetchDataFromSap = () => dispatch => {
  dispatch({
    type: GET_DATA_FROM_SAP,
    payload: {}
  });
  return axios
    .get(`${config.REACT_APP_API_BASE_URL}/order-status`, {
      headers: headers()
    })
    .then(response => {
      dispatch({
        type: GET_DATA_FROM_SAP_FULFILLED,
        payload: response.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_DATA_FROM_SAP_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
};
