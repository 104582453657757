import {
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_FULFILLED,
  FETCH_NOTIFICATION_REJECTED,
  FETCH_NOTIFICATION_COUNT,
  FETCH_NOTIFICATION_COUNT_FULFILLED,
  FETCH_NOTIFICATION_COUNT_REJECTED
} from "../actions/notifications.js";

import { getUserDataFromLocalStorage } from "../utilities";

export default (
  state = {
    data: [],
    fetching: false,
    fetched: false,
    approveLoading: false,
    rejectLoading: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case FETCH_NOTIFICATION:
      return { ...state, fetching: true, fetched: false, error: null };
    case FETCH_NOTIFICATION_FULFILLED:
      const data = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data,
      };
    case FETCH_NOTIFICATION_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
        data: [],
      };

    case FETCH_NOTIFICATION_COUNT:
      return { ...state, fetching: true, fetched: false, error: null };
    case FETCH_NOTIFICATION_COUNT_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: action.payload,
      };
    case FETCH_NOTIFICATION_COUNT_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
        data: [],
      };

    // case APPROVE_NOTIFICATION:
    //   return { ...state, approveLoading: true };
    // case APPROVE_NOTIFICATION_FULFILLED:
    //   return { ...state, approveLoading: false };
    // case APPROVE_NOTIFICATION_REJECTED:
    //   return { ...state, approveLoading: false };

    // case REJECT_NOTIFICATION:
    //   return { ...state, rejectLoading: true };
    // case REJECT_NOTIFICATION_FULFILLED:
    //   return { ...state, rejectLoading: false };
    // case REJECT_NOTIFICATION_REJECTED:
    //   return { ...state, rejectLoading: false };

    default:
      return state;
  }
};
