import React from 'react'
// import ScrollAnimation from 'react-animate-on-scroll'
// import CSS
import './Home.scss'
import Banner from '../Home/banner.js'
import OurBrands from '../Home/ourBrands.js'
// import WhatsNew from '../Home/whatsNew.js'
import SellProduct from '../Home/sellProduct.js'
import ContactUs from '../Home/contactUs.js'
import DocumentMeta from "react-document-meta";

export default function Home() {

  const meta = {
    title: "URC Global Exports",
    description: "URC Global Exports is tasked w/ the worldwide distribution of URC products. It serves nations & territories in N. America, Europe & more. Contact us today!",
    canonical: window.location.href,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "URC Global Exports"
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div className='home-container'>
        <Banner />
        <OurBrands />
        <SellProduct />
        <ContactUs />
        {/* <WhatsNew /> */}
      </div>
    </DocumentMeta>
  )
}
