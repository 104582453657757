import React from 'react'
import { Layout, Modal, Icon, Row, Col, Button, Radio, Input } from 'antd'
import './FAQ.scss'
import { connect } from 'react-redux';
import { postFaqFeedback } from '../../actions'
const { Content } = Layout
const { TextArea } = Input

class ModalFaqSurvey extends React.Component {
    constructor() {
        super()
        this.state = {
            radioValue: 'I dont know how to contact you',
            textAreaValue: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.faqFeedbackSuccess !== nextProps.faqFeedbackSuccess) {
            this.props.onCancel()
        }
    }

    handleSubmitFeedback() {
        const submitData = {
            faq_content_id: this.props.selectedItem.id,
            is_yes: false,
            response: this.state.radioValue,
            message: this.state.textAreaValue
        }
        this.props.onSubmitFaqFeedback(submitData)
    }

    handleChangeRadio(value) {
        this.setState({ radioValue: value })
    }

    handleChangeTextArea(value) {
        this.setState({ textAreaValue: value })
    }

    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <Modal
                // title="Title"
                footer={null}
                visible={this.props.visible}
                onCancel={() => this.props.onCancel()}
                width={648}
            // onOk={this.handleOk}
            // confirmLoading={confirmLoading}
            // onCancel={this.handleCancel}
            >
                <Layout className='faq-survey-container'>
                    <Content>
                        <Content className='survey-header'>
                            <h1>We'd like to know more of your insights to help you get better information!</h1>
                        </Content>
                        <Content className='survey-content'>
                            {/* <Row className='action-helpful'>
                                <Col md={10}>
                                    <h1>
                                        Was the information helpful?
                                    </h1>
                                </Col>
                                <Col className='action-helpful-btn' md={14}>
                                    <Button className='like-btn'><Icon type="like" style={{ fontSize: 18, color: '#7A4521' }} /><span>Yes</span></Button>
                                    <Button className='dislike-btn'><Icon type="dislike" style={{ fontSize: 18, color: 'white' }} />No</Button>
                                </Col>
                            </Row> */}

                            <Row className='action-is-not-helpful'>
                                <Col md={10}>
                                    <h1>
                                        Why do you think it was not helpful?
                                    </h1>
                                </Col>
                                <Col className='action-helpful-radio' md={14}>
                                    <Radio.Group
                                        onChange={(e) => this.handleChangeRadio(e.target.value)}
                                        value={this.state.radioValue}>
                                        <Radio style={radioStyle} value={'I dont know how to contact you'}>
                                            I dont know how to contact you
                                        </Radio>
                                        <Radio style={radioStyle} value={`I could'nt find the information`}>
                                            I could'nt find the information
                                        </Radio>
                                        <Radio style={radioStyle} value={'Lack of information'}>
                                            Lack of information
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>

                            <Row className='action-improve'>
                                <Col md={10}>
                                    <h1>
                                        Help us out on what can be improved!
                                    </h1>
                                </Col>
                                <Col className='action-the-urc' md={14}>
                                    <div className='content'>
                                        <TextArea value={this.state.textAreaValue} onChange={(e) => this.handleChangeTextArea(e.target.value)} rows={2} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='action-feedback'>
                                <Col md={24} align='right' className='submit-container' >
                                    <h1 onClick={() => this.props.onCancel()} style={{ cursor: 'pointer' }}>Cancel</h1>
                                    <Button onClick={() => this.handleSubmitFeedback()} loading={this.props.faqFeedbackLoading}>Send Feedback</Button>
                                </Col>
                            </Row>
                        </Content>
                    </Content>
                </Layout>
            </Modal>

        )
    }
}

function mapStateToProps(state) {
    return {
        faqFeedbackSuccess: state.faq.faqFeedbackSuccess,
        faqFeedbackLoading: state.faq.faqFeedbackLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitFaqFeedback: (data) => dispatch(postFaqFeedback(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFaqSurvey);
