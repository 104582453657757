import React from 'react';
import _ from 'lodash';
import { Form, Input, InputNumber } from 'antd';

function renderFieldByType(type, input, props) {
  switch (type) {
    case 'number':
      return <InputNumber {...input} {...props} />
    case 'search':
      return <Input.Search {...input} {...props} />
    default:
      return <Input {...input} {...props} />;
  }
}

export const required = message => value => (!_.isEmpty(value) || value === 0 ? undefined : message ? message : 'Required')
export const maxLength = (max, message) => value =>
  value && value.length > max ? message ? message : `Must be ${max} characters or less` : undefined
export const minLength = (min, message) => value =>
  value && value.length < min ? message ? message : `Must be ${min} characters or more` : undefined
export const number = message => value =>
  value && isNaN(Number(value)) ? message ? message : 'Must be a number' : undefined
export const minValue = (min, message) => value =>
  parseInt(value) && parseInt(value) < min ? message ? message : `Must be at least ${min}` : undefined
export const email = message => value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? message ? message : 'Invalid email address'
    : undefined
export const alphaNumeric = message => value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? message ? message : 'Only alphanumeric characters'
    : undefined
export const matchField = (field, message) => (value, values) => {
  return value && value !== values[field] ? message ? message : `Fields does not match` : undefined
}
export const phoneNumber = message => value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? message ? message : 'Invalid phone number, must be 10 digits'
    : undefined

export const isChar = message => value =>
  value && /[`^~<>,"'.]/.test(value)
    ? message ? message : 'Must not contain special characters'
    : undefined


export default props => {
  const { type, label, hasFeedback, validate, input, meta: { touched, error }, ...fieldProps } = props;
  const hasError = touched && error;
  const formItemProps = {};

  formItemProps.label = label || '';
  formItemProps.validateStatus = hasError ? 'error' : 'success';
  formItemProps.help = hasError ? error : '';
  formItemProps.hasFeedback = hasFeedback || false;

  return (
    <Form.Item {...formItemProps}>
      {renderFieldByType(type, input, fieldProps)}
    </Form.Item>
  )

}