import * as _ from 'lodash';
import { FILTER_OPTIONS, FILTER_OPTIONS_FULFILLED, FILTER_OPTIONS_REJECTED } from '../actions';

export default (state = {
	data: null,
	fetching: false,
	fetched: false,
	error: null
}, action) => {
	switch (action.type) {
		case FILTER_OPTIONS:
			return { ...state, fetching: true, fetched: false, error: null }
		case FILTER_OPTIONS_FULFILLED:
			const payload = action.payload;
			const all = {
				id: 0,
				name: "all",
				description: null,
				status: null,
				created_at: null,
				updated_at: null,
				image: null
			};
			_.each(payload, options => options.splice(0, 0, all));
			delete payload.variant; // remove variant from filter options.
			return { ...state, fetching: false, fetched: true, error: null, data: payload };
		case FILTER_OPTIONS_REJECTED:
			return { ...state, fetching: false, fetched: false, error: action.payload, data: null };

		default:
			return state;
	}
}
