import axios from 'axios';

import config from '../config';
import { headers } from '../utilities'

export const POST_UPLOAD_IMAGE = 'POST_UPLOAD_IMAGE';
export const POST_UPLOAD_IMAGE_FULFILLED = 'POST_UPLOAD_IMAGE_FULFILLED';
export const POST_UPLOAD_IMAGE_REJECTED = 'POST_UPLOAD_IMAGE_REJECTED';

export const submitImage = (data) => dispatch => {
  dispatch({
    type: POST_UPLOAD_IMAGE,
    payload: {}
  });
  return axios.post(`${config.REACT_APP_API_BASE_URL}/profile-image`, data, { headers: headers() })
    .then(response => {
      dispatch({
        type: POST_UPLOAD_IMAGE_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: POST_UPLOAD_IMAGE_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
}




