import { saveAs } from 'file-saver';
import moment from 'moment'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import ExcelJS from 'exceljs'
import Papa from 'papaparse'
import { numberFormat } from "../../utilities";

const wb = new ExcelJS.Workbook()

function getTotal(data, column) {
    return data.reduce((a, c) => a + parseFloat(c && c[column]), 0)
}

export const downloadMultipleFile = {

    async EXCEL(orderDataArray) {

        let worksheetArray = []

        orderDataArray.map(orderData => {

            const worksheet = wb.addWorksheet(`${orderData.so_number}`)

            // Width of column
            worksheet.getColumn('A').width = 16
            worksheet.getColumn('B').width = 16
            worksheet.getColumn('C').width = 30
            worksheet.getColumn('D').width = 20
            worksheet.getColumn('E').width = 25
            worksheet.getColumn('F').width = 30
            worksheet.getColumn('G').width = 25
            worksheet.getColumn('H').width = 30
            worksheet.getColumn('I').width = 16
            worksheet.getColumn('J').width = 20

            // Header
            worksheet.mergeCells('A1:H1');
            worksheet.getCell('A1').value = 'CONTAINER SUMMARY BREAKDOWN';
            worksheet.getCell('A1').alignment = { horizontal: 'center' };
            worksheet.getCell('A1').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
            worksheet.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };


            worksheet.getCell('A2').value = 'CREATED DATE';
            worksheet.getCell('A2').alignment = { horizontal: 'center' };
            worksheet.getCell('A2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
            worksheet.getCell('A2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };
            // Value
            worksheet.getCell('A3').value = orderData.created_at || '';


            worksheet.getCell('B2').value = 'SO NUMBER';
            worksheet.getCell('B2').alignment = { horizontal: 'center' };
            worksheet.getCell('B2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
            worksheet.getCell('B2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };
            // Value
            worksheet.getCell('B3').value = orderData.so_number || '';


            worksheet.getCell('C2').value = 'CUSTOMER PO NUMBER';
            worksheet.getCell('C2').alignment = { horizontal: 'center' };
            worksheet.getCell('C2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
            worksheet.getCell('C2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };
            // Value
            worksheet.getCell('C3').value = orderData.customer_po_number || '';

            worksheet.getCell('D2').value = 'STATUS';
            worksheet.getCell('D2').alignment = { horizontal: 'center' };
            worksheet.getCell('D2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
            worksheet.getCell('D2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };
            // Value
            worksheet.getCell('D3').value = orderData.status.toUpperCase() || '';


            worksheet.getCell('E2').value = 'ACTUAL STOCKS';
            worksheet.getCell('E2').alignment = { horizontal: 'center' };
            worksheet.getCell('E2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
            worksheet.getCell('E2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };
            // Value
            worksheet.getCell('E3').value = orderData.actual_stocks.toLocaleString(undefined, { minimumFractionDigits: 2 }) || '';
            worksheet.getCell('E3').alignment = { horizontal: 'right' };


            worksheet.getCell('F2').value = 'AMOUNT (IN USD)';
            worksheet.getCell('F2').alignment = { horizontal: 'center' };
            worksheet.getCell('F2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
            worksheet.getCell('F2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };
            // Value
            worksheet.getCell('F3').value = orderData.amount.toLocaleString(undefined, { minimumFractionDigits: 2 }) || '';
            worksheet.getCell('F3').alignment = { horizontal: 'right' };


            worksheet.getCell('G2').value = 'CBM';
            worksheet.getCell('G2').alignment = { horizontal: 'center' };
            worksheet.getCell('G2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
            worksheet.getCell('G2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };
            // Value
            worksheet.getCell('G3').value = orderData.cbm.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '';
            worksheet.getCell('G3').alignment = { horizontal: 'right' };


            worksheet.getCell('H2').value = 'GROSS WEIGHT';
            worksheet.getCell('H2').alignment = { horizontal: 'center' };
            worksheet.getCell('H2').font = { bold: true, horizontal: 'center', color: { 'argb': 'ffffff' } };
            worksheet.getCell('H2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072d4' } };
            // Value
            worksheet.getCell('H3').value = orderData.gross_weight.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '';
            worksheet.getCell('H3').alignment = { horizontal: 'right' };


            // Product List Details
            worksheet.getCell('B5').value = 'SKU CODE';
            worksheet.getCell('B5').font = { bold: true };

            worksheet.getCell('C5').value = 'SKU DESCRIPTION';
            worksheet.getCell('C5').font = { bold: true };

            worksheet.getCell('D5').value = 'PACKING SCHEME';
            worksheet.getCell('D5').font = { bold: true };

            worksheet.getCell('E5').value = 'ORDERED QTY (IN CS)';
            worksheet.getCell('E5').font = { bold: true };

            worksheet.getCell('F5').value = 'ORDERED AMOUNT (IN USD)';
            worksheet.getCell('F5').font = { bold: true };

            worksheet.getCell('G5').value = 'INVOICED QTY (IN CS)';
            worksheet.getCell('G5').font = { bold: true };

            worksheet.getCell('H5').value = 'INVOICED AMOUNT (IN USD)';
            worksheet.getCell('H5').font = { bold: true };

            worksheet.getCell('I5').value = 'CBM';
            worksheet.getCell('I5').font = { bold: true };

            worksheet.getCell('J5').value = 'GROSS WEIGHT';
            worksheet.getCell('J5').font = { bold: true };

            const lastRow = 6

            orderData && orderData.products[0] !== null && orderData.products.map((productItem, i) => {
                productItem.partialOrderedAmount = (orderData.ordered_quantities[i] * (productItem && productItem.pricelist && productItem.pricelist[0].selling_price))
                productItem.partialCBM = (productItem.cbm * orderData.invoiced_quantities[i])
                productItem.partialGrossWeight = (productItem.gross_weight)

                worksheet.getCell((`A${lastRow + i}`).toString()).value = `${i + 1}`;
                worksheet.getCell((`B${lastRow + i}`).toString()).value = `${productItem.material_code}`;
                worksheet.getCell((`C${lastRow + i}`).toString()).value = `${productItem.name}`;
                worksheet.getCell((`D${lastRow + i}`).toString()).value = `${productItem.packing_scheme}`;
                worksheet.getCell((`E${lastRow + i}`).toString()).value = `${(orderData.ordered_quantities[i]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                worksheet.getCell((`F${lastRow + i}`).toString()).value = `$${(orderData.ordered_quantities[i] * (productItem.pricelist && productItem.pricelist[0].selling_price)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                worksheet.getCell((`G${lastRow + i}`).toString()).value = `${(orderData.invoiced_quantities[i]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                worksheet.getCell((`H${lastRow + i}`).toString()).value = `$${(orderData.invoiced_quantities[i] * (productItem.pricelist && productItem.pricelist[0].selling_price)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                worksheet.getCell((`I${lastRow + i}`).toString()).value = `${(productItem.cbm * orderData.invoiced_quantities[i]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                worksheet.getCell((`J${lastRow + i}`).toString()).value = `${(productItem.gross_weight).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            })

            orderData.totalOrderedAmount = getTotal(orderData.products, "partialOrderedAmount")
            orderData.totalCBM = getTotal(orderData.products, "partialCBM")
            orderData.totalGrossWeight = getTotal(orderData.products, "partialGrossWeight")

            let totalRow = lastRow + orderData.products.length
            worksheet.getCell(`A${totalRow}`).value = 'TOTAL'
            worksheet.getCell(`A${totalRow}`).font = { bold: true }

            worksheet.getCell((`E${totalRow}`).toString()).value = orderData.ordered_quantities.reduce((a, c) => a + c).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            worksheet.getCell(`E${totalRow}`).alignment = { vertical: 'bottom', horizontal: 'right' }
            worksheet.getCell(`E${totalRow}`).font = { bold: true }

            worksheet.getCell((`F${totalRow}`).toString()).value = `$${orderData.totalOrderedAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            worksheet.getCell(`F${totalRow}`).alignment = { vertical: 'bottom', horizontal: 'right' }
            worksheet.getCell(`F${totalRow}`).font = { bold: true }

            worksheet.getCell((`G${totalRow}`).toString()).value = orderData.invoiced_quantities.reduce((a, c) => a + c).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            worksheet.getCell(`G${totalRow}`).alignment = { vertical: 'bottom', horizontal: 'right' }
            worksheet.getCell(`G${totalRow}`).font = { bold: true }

            worksheet.getCell((`H${totalRow}`).toString()).value = `$${orderData.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            worksheet.getCell(`H${totalRow}`).alignment = { vertical: 'bottom', horizontal: 'right' }
            worksheet.getCell(`H${totalRow}`).font = { bold: true }

            worksheet.getCell((`I${totalRow}`).toString()).value = orderData.totalCBM.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            worksheet.getCell(`I${totalRow}`).alignment = { vertical: 'bottom', horizontal: 'right' }
            worksheet.getCell(`I${totalRow}`).font = { bold: true }

            worksheet.getCell((`J${totalRow}`).toString()).value = orderData.totalGrossWeight.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            worksheet.getCell(`J${totalRow}`).alignment = { vertical: 'bottom', horizontal: 'right' }
            worksheet.getCell(`J${totalRow}`).font = { bold: true }

            worksheetArray.push(worksheet)

        })

        const excelData = await wb.xlsx.writeBuffer()
        saveAs(new Blob([excelData]), 'Container-Summary.xlsx')

        worksheetArray.map((dataObj) => {
            wb.removeWorksheet(dataObj.id)
        })

    },

    CSV(orderDataArray) {
        let csvArray = []
        orderDataArray.map(orderData => {
            const orderItem = orderData.products
            orderItem.map((productItem, i) => {
                // const status = getStatus(productItem.status) || 'FOR PRODUCTION'
                // const skuCode = productItem.pricelist.special_code ? productItem.pricelist.special_code : productItem.variant.material_code
                // const totalOrderQty = orderItem.reduce((a, b) => +a + +b.orderQty.replace(/,/g, ""), 0);
                // const totalOrderAmount = orderItem.reduce((a, b) => +a + +(parseInt(b.orderAmount.replace(/,/g, ""))), 0);
                // const totalCbm = orderItem.reduce((a, b) => +a + +(b.cbm.replace(/,/g, "")), 0);

                csvArray.push({
                    "CREATED DATE": orderData.created_at,
                    // "SO NUMBER": orderData.so_number,
                    // "PO NUMBER": orderData.po_number,
                    "STATUS": orderData.status.toUpperCase(),
                    // "ACTUAL STOCKS": orderData.actual_stocks,
                    // "AMOUNT (IN USD)": orderData.amount,
                    // "CBM": orderData.cbm,
                    // "GROSS WEIGHT": orderData.gross_weight,

                    "SKU CODE": productItem && productItem.material_code,
                    "SKU DESCRIPTION": productItem && productItem.name,
                    "PACKING SCHEME": productItem && productItem.packing_scheme,
                    "ORDERED QTY (IN CS)": orderData.ordered_quantities[i],
                    "ORDERED AMOUNT (IN USD)": `$${numberFormat((orderData.ordered_quantities[i] * (productItem && productItem.pricelist && productItem.pricelist[0].selling_price)))}`,
                    "INVOICED QTY (IN CS)": orderData.invoiced_quantities[i],
                    "INVOICED AMOUNT (IN USD)": `$${numberFormat((orderData.invoiced_quantities[i] * (productItem && productItem.pricelist && productItem.pricelist[0].selling_price)))}`,
                    "CBM": numberFormat((productItem && productItem.cbm) * orderData.invoiced_quantities[i]),
                    "GROSS WEIGHT": numberFormat(productItem && productItem.gross_weight)
                })
            })
        })

        const newCsv = Papa.unparse(csvArray)

        const blob = new Blob([newCsv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "Order Status Report.csv");

    },

    async PDF(orderDataArray) {

        const newArray = []
        orderDataArray.map((order, x) => {
            order.products && order.products.map((product, y) => {
                newArray.push({
                    created_at: order.created_at,
                    status: order.status || '',
                    name: product && product.name || '',
                    material_code: product && product.material_code || '',
                    packing_scheme: product && product.packing_scheme || '',
                    order_qty: order.ordered_quantities[y] || 0,
                    order_amount: (order.ordered_quantities[y] * (product && product.pricelist && product.pricelist[0].selling_price)) || 0,
                    invoiced_qty: order.invoiced_quantities[y] || 0,
                    invoiced_amount: (order.invoiced_quantities[y] * (product && product.pricelist && product.pricelist[0].selling_price)) || 0,
                    cbm: (product && product.cbm * order.invoiced_quantities[y]) || 0,
                    gross_weight: product && product.gross_weight || 0
                })
            })
        })

        // Embed the Times Roman font
        const pdfDoc = await PDFDocument.create()
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Split page by 80
        let pageArray = splitArrayIntoChunksOfLen(newArray, 40)

        let pageCount = 0
        pageArray.map((orderData, i) => {
            const page = pdfDoc.addPage([910, 500])
            const { height } = page.getSize()
            page.moveTo(240, 120);

            page.drawText('#', { x: 20, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('DATE', { x: 40, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('STATUS', { x: 90, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('SKU CODE', { x: 135, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('SKU DESCRIPTION', { x: 195, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('PACKING SCHEME', { x: 350, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('ORDERED QTY', { x: 450, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('ORDERED AMT', { x: 540, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('INVOICED QTY', { x: 620, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('INVOICED AMT', { x: 700, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('CBM', { x: 790, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('G. WGT', { x: 830, y: height - 4 * 9, size: 9, font: timesRomanFont })

            orderData.map((value, i) => {
                pageCount++
                page.drawText((pageCount).toString(), { x: 20, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.created_at.toString(), { x: 40, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.status.toUpperCase(), { x: 90, y: height - (6 + i) * 9, size: 7, font: timesRomanFont })
                page.drawText(value.material_code, { x: 135, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.name, { x: 195, y: height - (6 + i) * 9, size: 7, font: timesRomanFont })
                page.drawText(value.packing_scheme.toString(), { x: 350, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.order_qty.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 450, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(`$${value.order_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, { x: 540, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.invoiced_qty.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 620, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(`$${value.invoiced_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, { x: 700, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.cbm.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 790, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.gross_weight.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 830, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
            })
            page.drawText('TOTAL', { x: 40, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            page.drawText(orderData.reduce((a, c) => a + (c.order_qty), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 450, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            page.drawText('$' + orderData.reduce((a, c) => a + (c.order_amount), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 540, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            page.drawText(orderData.reduce((a, c) => a + (c.invoiced_qty), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 620, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            page.drawText('$' + orderData.reduce((a, c) => a + (c.invoiced_amount), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 700, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            page.drawText(orderData.reduce((a, c) => a + (c.cbm), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 790, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            page.drawText(orderData.reduce((a, c) => a + (c.gross_weight), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 830, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
        })





        const pdfBytes = await pdfDoc.save()
        const pdfBlob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        saveAs(pdfBlob, "Container-Summary.pdf");
    }

}

function splitArrayIntoChunksOfLen(arr, len) {
    let chunks = [], i = 0, n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}